import React, {useRef, useState} from 'react';
import CreateAccountForm from "./components/CreateAccountForm";
import styles from './CreateAccount.module.scss';
import masterApi from "../../../api/masterApi";
import Logo from "../../../components/Logo";
import {Link} from "react-router-dom";
import avt from "../../../assets/img/avt.png";
import {useStore} from "../../../store";

CreateAccount.propTypes = {};

function CreateAccount(props) {
    const [state] = useStore();
    const [message, setMessage] = useState('');
    const [notification, setNotification] = useState({});
    const [showDropDown, setShowDropDown] = useState(false);
    const dropDownRef = useRef(null);

    const handleShowDropDown = () => {
        setShowDropDown((showDropDown) => !showDropDown);
    };

    const logoutMaster = async () => {
        try {
            const qrCode = localStorage?.getItem('qrCode');
            await masterApi.logoutMaster({
                "qr_code": qrCode ? qrCode : "0"
            });
            localStorage.clear();
            window.location.reload();
        } catch (e) {
        }
    }

    const handleSubmit = async (values) => {
        try {
            await masterApi.createAccountMaster(values);
            setNotification({
                type: 'success',
                message: 'New account created successfully.'
            });
            setMessage('');
        } catch (e) {
            setMessage(e?.response?.data);
            setNotification({
                type: 'error',
                message: e?.response?.data
            })
        }
    }

    return (
        <div className={'container'}>
            <div className={styles.headerDashboard}>
                <div className={styles.headerDashboardInr}>
                    <div className={styles.hdrLeft}>
                        <div className="logo">
                            <Logo/>
                        </div>
                    </div>
                    <div className={styles.hdrRight}>
                        <div className={styles.hdrItem}>
                            <Link to={"/"} className={styles.backHome}>
                                Homepage
                            </Link>
                        </div>
                        <div className={styles.hdrItem}>
                            <Link to={"/dashboard-global"} className={styles.btnGlobal}>
                                Global
                            </Link>
                        </div>
                        <div className={styles.hdrItem}>
                            <div
                                className={styles.user}
                                ref={dropDownRef}
                                onClick={handleShowDropDown}
                            >
                                <div className={styles.avt}>
                                    <img src={avt} alt="avt-user"/>
                                </div>
                                <div className={styles.extraUser}>
                                    <div className={styles.txtUser}>
                                        {localStorage.getItem("username")}
                                    </div>
                                    <div className={`${styles.txtUser} ${styles.colorGray}`}>
                                        Game master
                                    </div>
                                </div>
                            </div>

                            {showDropDown && (
                                <div className={styles.userPopup}>
                                    <ul>
                                        {'Superadmin' === state.level && (
                                            <>
                                                <li><Link to={"/master-account"}>Account List</Link></li>
                                                <li><Link to={"/create-account"}>Create Account</Link></li>
                                            </>
                                        )}
                                        <li onClick={logoutMaster}>Sign Out</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <div className={styles.createAccountContent}>
                    <div className={styles.createAccount}>
                        <h1 className={'center'}>Create Account</h1>
                        <CreateAccountForm onSubmit={handleSubmit}
                                           messsage={message}
                                           notification={notification}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateAccount;