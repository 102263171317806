import React, {useEffect, useState} from 'react';
import gameApi from '../../../api/gameApi';
import masterApi from '../../../api/masterApi';
import FormBiomassEarning from './components/FormBiomassEarning';
import LoadingSpinner from '../../../components/LoadingSpinner';

UpdateBiomassEarning.propTypes = {};

function UpdateBiomassEarning(props) {
  const [biomassEarning, setBiomassEarning] = useState([]);
  const [resultOfPM, setResultOfPM] = useState();

  const [message, setMessage] = useState('');
  const [round, setRound] = useState('');

  useEffect(() => {
    (async() => {
      try {
        const response = await gameApi.getBiomassEarningOfHarvesterMaster(
          localStorage.getItem('qrCode'));
        setBiomassEarning(response?.biomass_earning);
        setRound(response?.round);
        setResultOfPM(response?.pm);
      } catch (e) {
      }
    })();
  }, []);

  const biomassEarningDefault = biomassEarning.map(item => {
    const harvester = Object.entries(item.harvesters).map(([, data]) => data);
    return {
      player_name: item.player_name,
      harvesters: harvester,
      total: item.total_harvest,
    };
  });

  const harvesterMax = Math.max(
    ...biomassEarningDefault.map(item => item.harvesters.length));

  const handleSubmit = async(values) => {
    try {
      const qrCode = localStorage.getItem('qrCode');
      await masterApi.transferBiomass({
        'qr_code': qrCode,
        'transfer_biomass': values,
      });
      setMessage('The biomass was successfully updated.');

    } catch (e) {
      setMessage(e.response.data);
    }
  };

  const loadingStyle = {
    minHeight: '300px',
    display: 'flex',
    alignItems: 'center'

  };

  return (
    <div className={'min-vh'}>
      <div className={'container'}>
        <h1 className={'center'}>Harvest - Round {round}</h1>

        {0 === biomassEarningDefault.length &&
         <div style={loadingStyle}><LoadingSpinner/></div>}

        {0 < biomassEarningDefault.length &&
         <FormBiomassEarning
           biomassEarning={biomassEarningDefault}
           onSubmit={handleSubmit}
           harvesterMax={harvesterMax}
           resultOfPM={resultOfPM}
           message={message}/>}
      </div>
    </div>
  );
}

export default UpdateBiomassEarning;
