import InputField from "../../../../../components/FormFields/InputField";
import React from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import Notification from "../../../../../components/Notification";

export default function UpdateAccountForm({onSubmit, defaultForm, handleClosePopup, notification}) {
  const schemaUpdateAccount = yup.object({
    username: yup.string()
      .required('Please enter your username.')
      .min(5, 'Please enter at least 5 characters.')
      .matches(/^\S*$/, 'Username does not allow spaces.'),
    password: yup.string()
      .required('Please enter your password.')
      .min(8, 'Please enter at least 8 characters.'),
  });
  const {control, handleSubmit, formState: {errors}, reset} = useForm(
    {
      defaultValues: {
        username: defaultForm.username,
        password: '',
      },
      resolver: yupResolver(schemaUpdateAccount),
    });

  const onHandleSubmit = async (values) => {
    if (onSubmit) {
      await onSubmit(values);
    }
    reset();
  };

  const handleButtonCancel = () => {
    handleClosePopup();
  }

  return(
    <>
      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <h2 className={'center'}>Update Account</h2>

        <div className={'form-item'}>
          <InputField type={'text'}
                      placeholder={'Host Username'}
                      name={'username'}
                      label={'Username'}
                      autocomplete={'name'}
                      control={control}
                      errors={errors}
          />
        </div>

        <div className={'form-item'}>
          <InputField type={'password'}
                      placeholder={'Password'}
                      name={'password'}
                      label={'Password'}
                      autocomplete={'password'}
                      control={control}
                      errors={errors}
          />
        </div>


        {Object.keys(notification).length > 0 && <Notification notification={notification}/>}

        <div className={'form-actions form-flex'}>
          <button type={'button'}
                  className={'btn cancel'}
                  onClick={handleButtonCancel}>Cancel</button>
          <button type={'submit'}>Update</button>
        </div>
      </form>
    </>
  )
}