import React from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import Select from 'react-select';
import {ErrorMessage} from '@hookform/error-message';

SelectField.propTypes = {
  control: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
};

function SelectField(props) {
  const {control, placeholder, name, options, selected, disabled, errors} = props;

  const customStyles = {
    control: () => ({
      border: 'none',
      borderBottom: '1px solid rgba(190, 190, 190, 0.5)',
      display: 'flex',
    }),
    valueContainer: (provided, state) => {
      const paddingLeft = 0;
      return {...provided, paddingLeft};
    },
    indicatorSeparator: () => ({
      background: 'none'
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#788180'
    }),
    singleValue : (provided) => ({
      ...provided,
      color: '#222624'
    }),
    option: (provided, {isSelected}) => ({
      ...provided,
      color: '#222624',
      fontSize: '14px',
      backgroundColor: isSelected ? '#DDFDE6' : '',
      '&:hover': {
        backgroundColor: '#DDFDE6'
      },
      '&:focus': {
        backgroundColor: '#DDFDE6'
      }
    })
  };

  return (
    <Controller
      render={({field: {onChange, name, ref, value}}) => {
        return (
          <>
            <Select
              inputRef={ref}
              options={options}
              onChange={(value) => onChange(value?.value)}
              placeholder={placeholder}
              name={name}
              defaultValue={value}
              styles={customStyles}
              value={options[selected] ? options[selected] : value?.value}
              isDisabled={disabled}/>

            <ErrorMessage
              errors={errors}
              name={name}
              render={({message}) =>
                <p className={'error'}>{message}</p>}/>
          </>
        );
      }}
      name={name}
      control={control}
    />
  );
}

export default SelectField;
