import React, { useEffect, useState } from 'react';
import LogoGray from '../../../assets/img/logo-gray.png';
import LineIcon from '../../../assets/img/line-icon.png';
import styles from './Landscape.module.scss';
import { Link } from 'react-router-dom';
import LandscapeContentHeader
  from '../../../components/LandscapeLayout/LandscapeContentHeader';
import gameApi from '../../../api/gameApi';
import WaitingForLandscape from '../../../components/WaitingForLandscape';
import { useStoreGame } from '../../../store';
Landscape.propTypes = {};

function Landscape(props) {
  const [stateGame] = useStoreGame();
  const { roundCurrent } = stateGame;

  const qrCode = localStorage.getItem('qrCode');
  const [round, setRound] = useState(roundCurrent ? roundCurrent : ' ');

  const [landscapeView, setLandscapeView] = useState({});
  const [showView, setShowView] = useState(false);

  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await gameApi.getLandscape(qrCode);
        setLandscapeView(response?.landscape);
        setRound(response?.round);
        setWaiting(!waiting);
      } catch (e) { }
    })();
  }, []);

  const handleShowView = () => {
    setShowView(!showView);
  };

  return (
    <>
      <main className={'container'}>
        <LandscapeContentHeader title={`Round ${round} - Landscape of round`}
          active={1} />
        <div className='game-content-master'>
          {waiting && <WaitingForLandscape />}

          {!waiting && <div>
            <div className={'game-board'}>
              <div className={'game-title-row'}>
                <div>A</div>
                <div>B</div>
                <div>C</div>
                <div>D</div>
                <div>E</div>
              </div>
              <div className={'game-title-col'}>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
              </div>
              <div className={'game-view'}>
                {!showView &&
                  <div className={'game-list'}>
                    {Object.entries(landscapeView).map((value, index) => (
                      <div key={index}
                        className={'game-item'}
                        style={{ backgroundColor: value[1].color }}>
                      </div>
                    ))}
                  </div>}

                {showView &&
                  <div className={'game-list'}>
                    {Object.entries(landscapeView).map((value, index) => (
                      <div key={index}
                        className={'game-item'}
                        style={{ backgroundColor: value[1].color }}>
                        {0 < value[1].animal_qty &&
                          <p className={'animal-icon'}>{[
                            ...Array(value[1].animal_qty)].map(
                              (currentValue, i) => (
                                <span key={i}>&nbsp;</span>))}</p>}
                      </div>
                    ))}
                  </div>}
              </div>
            </div>
            <div className={'center'}>
              {round === 1 ?
                <Link to={'/timer-harvester'}
                  className={'btn-arrow'}><span>Next</span></Link> :
                <Link to={'/timer-park-manager'}
                  className={'btn-arrow'}><span>Next</span></Link>}
            </div>
          </div>}
        </div>
      </main>

      <footer className={styles.footer}>
        <div className={'container'}>
          <div className={styles.wrapper}>
            <div className={'d-flex'}>
              <img src={LogoGray}
                alt={'Planet C Logo Gray'}
                className={styles.logoFt} />
              Park manager

              <div onClick={handleShowView} className={'switch-toggle'}>
                <input type={'checkbox'} name={'switch'} value={'1'} />
                <label htmlFor={'switch'}>Switch</label>
              </div>
            </div>

            <p>
              <Link to={'/landscape-view'}
                target={'_blank'}
                className={styles.linkView}>
                <span>Game master view</span>
                <img src={LineIcon} alt={'Line Icon'} />
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Landscape;
