import React, {useEffect, useState} from 'react';
import {useStoreGame, useStoreUser} from '../../../store';
import gameApi from '../../../api/gameApi';
import WaitingForLandscape from '../../../components/WaitingForLandscape';
import HeaderParkManager from "../../../components/HeaderParkManager";

ParkManagerViewDecision.propTypes = {};

function ParkManagerViewDecision(props) {
  const [stateUser] = useStoreUser();
  const {qrCode} = stateUser;

  const [stateGame] = useStoreGame();
  const {landscapePlayer} = stateGame;

  const [landscapeView, setLandscapeView] = useState({});
  const [round, setRound] = useState();

  const [adult, setAdult] = useState();
  const [babies, setBabies] = useState();
;
  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    (async() => {
      try {
        const response = await gameApi.getLandscapePlayer(qrCode);
        setLandscapeView(response?.landscape);
        setWaiting(!waiting);
      } catch (e) {
        
      }
    })();
  }, [qrCode]);

  useEffect(() => {
    (async() => {
      try {
        const response = await gameApi.getBiomassEarningOfHarvester(qrCode);
        const biomassEarning = response.biomass_earning;
        setRound(response?.round);
        setAdult(biomassEarning.total_adult ? biomassEarning.total_adult : 0);
        setBabies(
          biomassEarning.total_babies ? biomassEarning.total_babies : 0);
      } catch (e) {
      }
    })();
  }, [qrCode]);

  useEffect(() => {
    setLandscapeView(landscapePlayer);
  }, [landscapePlayer, qrCode]);

  return (
    <div className={'min-vh'}>
      <div className={'container-sm'}>
        <HeaderParkManager title={'End of the Harvest Season'}/>

        <div className={'game-content'}>
          {waiting && <WaitingForLandscape/>}

          {!waiting &&
           <div className={'game-board small'}>
             <div className={'game-title-row'}>
               <div>A</div>
               <div>B</div>
               <div>C</div>
               <div>D</div>
               <div>E</div>
             </div>
             <div className={'game-title-col'}>
               <div>1</div>
               <div>2</div>
               <div>3</div>
               <div>4</div>
             </div>
             <div className={'game-list'}>
               {Object.entries(landscapeView).map((value, index) => (
                 <div key={index}
                      className={`game-item 
                     ${value[1]?.is_protected === 'true' ? 'active' : ''}`}
                      style={{backgroundColor: value[1].color}}>
                   <div className='inner'>
                     {0 < value[1].animal_qty &&
                      <p className={'animal-icon'}>{[
                        ...Array(value[1].animal_qty)].map(
                        (currentValue, i) => (
                          <span key={i}>&nbsp;</span>))}</p>}

                     {0 < value[1]?.animal_new_born &&
                      <p className={'animal-icon new-born'}>{[
                        ...Array(value[1]?.animal_new_born)].map(
                        (currentValue, i) => (
                          <span key={i}>&nbsp;</span>))}</p>}

                     {0 < value[1].harvester_qty &&
                      <ul className={'harvesters'}>{[
                        ...Array(value[1].harvester_qty)].map(
                        (currentValue, i) => (
                          <li key={i}><span>&nbsp;</span></li>))}</ul>}
                   </div>
                 </div>
               ))}
             </div>
           </div>}
        </div>

        <div className={'harvest-result'}>
          <h2>Round {round}</h2>

          <p className={'orange'}>Adult Animals: {adult}</p>
          <p className={'orange'}>Newborn Animals : {babies}</p>
        </div>
      </div>
    </div>
  );
}

export default ParkManagerViewDecision;
