import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import GameRule1 from './components/GameRule1';
import GameRule2 from './components/GameRule2';
import GameRule3 from './components/GameRule3';
import GameRule4 from './components/GameRule4';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import userApi from '../../../api/userApi';

GameRulePlayer.propTypes = {};

function GameRulePlayer(props) {
  const [numberHarvester, setNumberHarvester] = useState();

  let settings = {
    dots: true,
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    (async() => {
      try {
        const response = await userApi.getHarvestorOfPlayer();
        setNumberHarvester(response?.number_harvesters);
      } catch (e) {
      }
    })();
  }, []);

  return (
    <div className={'min-vh'}>
      <div className={'container-sm'}>
        <Slider {...settings}>
          <GameRule1 numberHarvester={numberHarvester}/>
          <GameRule2/>
          <GameRule3/>
          <GameRule4/>
        </Slider>
      </div>
    </div>
  );
}

export default GameRulePlayer;
