import React, { useState, useEffect, useRef } from "react";
import styles from "./DashboardGlobal.module.scss";
import Logo from "../../../components/Logo";
import { Link } from "react-router-dom";
import avt from "../../../assets/img/avt.png";
import dashboardApi from "../../../api/dashboardApi";
import WaitingForDashboard from "../../../components/WaitingForDashboard";
import { Chart as ChartJS, Tooltip, Legend } from "chart.js";
import { Scatter } from "react-chartjs-2";
import gameApi from "../../../api/gameApi";
import masterApi from "../../../api/masterApi";
import {useStore} from "../../../store";

function DashboardGlobal() {
  const quadrants = useRef({
    id: "quadrants",
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { left, top, right, bottom },
        scales: { x, y },
      } = chart;
      const midX = x.getPixelForValue(0);
      const midY = y.getPixelForValue(0);
      ctx.save();
      ctx.fillStyle = options.topLeft;
      ctx.fillRect(left, top, midX - left, midY - top);
      ctx.fillStyle = options.topRight;
      ctx.fillRect(midX, top, right - midX, midY - top);
      ctx.fillStyle = options.bottomRight;
      ctx.fillRect(midX, midY, right - midX, bottom - midY);
      ctx.fillStyle = options.bottomLeft;
      ctx.fillRect(left, midY, midX - left, bottom - midY);
      ctx.restore();
    },
  });

  const dataFilter = [
    { id: 1, name: "Total harvest", checked: true },
    { id: 2, name: "Final biomass", checked: true },
    { id: 3, name: "Newborns", checked: false },
  ];

  const [state] = useStore();
  const [showDropDown, setShowDropDown] = useState(false);
  const dropDownRef = useRef(null);
  const dropDownFilterRef = useRef(null);
  const boxRef = useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const [checkBoxes, setCheckedBoxes] = useState(dataFilter);
  const [dashboardTotal, setDashboardTotal] = useState(null);

  const [xAxis, setXAxis] = useState("harvest");
  const [yAxis, setYAxis] = useState("biomass");

  const [textTopLeft, setTextTopLeft] = useState(
    <>
      Development Wins / <br /> Conservation Looses
    </>
  );

  const [textTopRight, setTextTopRight] = useState("Both Win");
  const [textBotLeft, setTextBotLeft] = useState("Both loose");
  const [textBotRight, setTextBotRight] = useState(
    <>
      Conservation Wins / <br /> Development Loose
    </>
  );

  const logoutMaster = async () => {
    try {
      const qrCode = localStorage?.getItem('qrCode');
      await masterApi.logoutMaster({
        "qr_code": qrCode ? qrCode : "0"
      });
      localStorage.clear();
      window.location.reload();
    } catch (e) { }
  }

  const handleOnChange = (event) => {
    let checkBoxIndex = checkBoxes.findIndex(
      (box) => box.name === event.target.name
    );

    let copyOfCheckBoxes = [...checkBoxes];
    let isChecked = copyOfCheckBoxes[checkBoxIndex].checked;
    let amountOfCheckedBoxes = checkBoxes.filter((box) => box.checked);

    if (!isChecked && amountOfCheckedBoxes.length >= 2) {
      return checkBoxes;
    }

    if (isChecked) {
      copyOfCheckBoxes[checkBoxIndex] = {
        ...copyOfCheckBoxes[checkBoxIndex],
        checked: false,
      };
    } else {
      copyOfCheckBoxes[checkBoxIndex] = {
        ...copyOfCheckBoxes[checkBoxIndex],
        checked: true,
      };
    }
    setCheckedBoxes(copyOfCheckBoxes);
  };

  const handleShowDropDown = () => {
    setShowDropDown((showDropDown) => !showDropDown);
  };
  const handleShowFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  useEffect(() => {
    function handleClickOutDropDown(e) {
      if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
        setShowDropDown(false);
      }
    }
    document.addEventListener("click", handleClickOutDropDown);
    return () => {
      document.removeEventListener("click", handleClickOutDropDown);
    };
  }, []);

  useEffect(() => {
    function handleClickOutDropDownFilter(e) {
      if (
        dropDownFilterRef.current &&
        !dropDownFilterRef.current.contains(e.target) &&
        boxRef.current &&
        !boxRef.current.contains(e.target)
      ) {
        setShowFilter(false);
      }
    }
    document.addEventListener("click", handleClickOutDropDownFilter);
    return () => {
      document.removeEventListener("click", handleClickOutDropDownFilter);
    };
  }, []);

  useEffect(() => {
    ChartJS.register(Tooltip, Legend, quadrants);
    return () => {
      ChartJS.unregister(quadrants);
    };
  }, [quadrants]);

  const handleApiGetDashboard = (idFilter) => {
    (async () => {
      try {
        const response = await dashboardApi.getDashboardGlobal(0, idFilter);
        setDashboardTotal(response);
      } catch (e) {
      }
    })();
  };

  useEffect(() => {
    handleApiGetDashboard("1,2");
  }, []);

  useEffect(() => {
    const checkFilter = checkBoxes.filter((item) => item.checked === true);
    const arrayId = checkFilter.map((item) => item.id);
    if (arrayId.includes(1) && arrayId.includes(2)) {
      handleApiGetDashboard("1,2");
      setXAxis("harvest");
      setYAxis("final biomass");
      setTextTopLeft(
        <>
          Development Wins / <br /> Conservation Looses
        </>
      );
      setTextTopRight("Both Win");
      setTextBotLeft("Both loose");
      setTextBotRight(
        <>
          Conservation Wins / <br /> Development Loose
        </>
      );
    } else if (arrayId.includes(1) && arrayId.includes(3)) {
      handleApiGetDashboard("1,3");
      setXAxis("harvest");
      setYAxis("newborns");
      setTextTopLeft(
        <>
          Development Wins / <br /> Conservation Looses
        </>
      );
      setTextTopRight("Both Win");
      setTextBotLeft("Both loose");
      setTextBotRight(
        <>
          Conservation Wins / <br /> Development Loose
        </>
      );
    } else if (arrayId.includes(2) && arrayId.includes(3)) {
      handleApiGetDashboard("2,3");
      setXAxis("final biomass");
      setYAxis("newborns");
      setTextTopLeft("Empty Lanscape");
      setTextTopRight("Ecosystem thriving");
      setTextBotLeft("Ecological colapse");
      setTextBotRight("Unsustainable change");
    } else {
      handleApiGetDashboard("0");
    }
  }, [checkBoxes]);

  return (
    <>
      <div className={styles.headerDashboard}>
        <div className={styles.headerDashboardInr}>
          <div className={styles.hdrLeft}>
            <div className="logo">
              <Logo />
            </div>
          </div>
          <div className={styles.hdrRight}>
            <div className={styles.hdrItem}>
              <Link to={"/dashboard"} className={styles.backHome}>
                Back
              </Link>
            </div>
            <div className={styles.hdrItem}>
              <div
                className={styles.user}
                ref={dropDownRef}
                onClick={handleShowDropDown}
              >
                <div className={styles.avt}>
                  <img src={avt} alt="avt-user" />
                </div>
                <div className={styles.extraUser}>
                  <div className={styles.txtUser}>
                    {localStorage.getItem("username")}
                  </div>
                  <div className={`${styles.txtUser} ${styles.colorGray}`}>
                    Game master
                  </div>
                </div>
              </div>

              {showDropDown && (
                <div className={styles.userPopup}>
                  <ul>
                    {'Superadmin' === state.level && (
                      <>
                        <li><Link to={"/master-account"}>Account List</Link></li>
                        <li><Link to={"/create-account"}>Create Account</Link></li>
                      </>
                    )}
                    <li onClick={logoutMaster}>Sign Out</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.sectionInr}>
          <div className={styles.title}>
            <h2>Global Overview</h2>
            <div className={styles.selectBox}>
              <div
                onClick={handleShowFilter}
                ref={dropDownFilterRef}
                className={styles.selectHeader}
              >
                <span>Axis value</span>
              </div>
              {showFilter && (
                <div ref={boxRef} className={styles.selectCnt}>
                  <div className={styles.selectItems}>
                    {checkBoxes.map((box, index) => (
                      <div key={index} className={styles.itemSl}>
                        <div className="checkbox">
                          <input
                            id={`chkFilter-${box.id}`}
                            type="checkbox"
                            name={box.name}
                            onChange={handleOnChange}
                            checked={box.checked}
                          />
                          <label htmlFor={`chkFilter-${box.id}`}>
                            {box.name}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={styles.selectHint}>Up to 2 values</div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.chart}>
            {dashboardTotal && (
              <>
                {dashboardTotal.max_total_harvest ||
                  dashboardTotal.max_final_biomass ? (
                  <Scatter
                    data={{
                      datasets: [
                        {
                          data: dashboardTotal.dashboard.map((item) => {
                            let axisChart = { x: null, y: null };
                            if (dashboardTotal.id_type === 1) {
                              axisChart = {
                                x: item.total_harvest,
                                y: item.final_biomass,
                              };
                              return axisChart;
                            } else if (dashboardTotal.id_type === 2) {
                              axisChart = {
                                x: item.total_harvest,
                                y: item.total_newborns,
                              };
                              return axisChart;
                            } else {
                              axisChart = {
                                x: item.final_biomass,
                                y: item.total_newborns,
                              };
                              return axisChart;
                            }
                          }),
                          backgroundColor: "#29513B",
                          pointRadius: 4.5,
                          pointHoverRadius: 10,
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        quadrants: {
                          topLeft: "#E6FAF1",
                          topRight: "#FFECE9",
                          bottomRight: "#E5F0FF",
                          bottomLeft: "#FCE7F6",
                        },
                      },
                      scales: {
                        x: {
                          min:
                            dashboardTotal.id_type === 1 ||
                              dashboardTotal.id_type === 2
                              ? -dashboardTotal.max_total_harvest
                              : dashboardTotal.id_type === 3
                                ? -dashboardTotal.max_final_biomass
                                : null,
                          max:
                            dashboardTotal.id_type === 1 ||
                              dashboardTotal.id_type === 2
                              ? dashboardTotal.max_total_harvest
                              : dashboardTotal.id_type === 3
                                ? dashboardTotal.max_final_biomass
                                : null,
                          position: "center",
                          grid: {
                            display: false,
                          },
                          ticks: {
                            stepSize: 20,
                            font: {
                              family: "'Poppins', sans-serif",
                              weight: "400",
                            },
                          },
                        },
                        y: {
                          type: "linear",
                          grace: "5%",
                          min:
                            dashboardTotal.id_type === 1
                              ? -dashboardTotal.max_final_biomass
                              : dashboardTotal.id_type === 2 ||
                                dashboardTotal.id_type === 3
                                ? -dashboardTotal.max_total_newborns
                                : null,
                          max:
                            dashboardTotal.id_type === 1
                              ? dashboardTotal.max_final_biomass
                              : dashboardTotal.id_type === 2 ||
                                dashboardTotal.id_type === 3
                                ? dashboardTotal.max_total_newborns
                                : null,
                          position: "center",
                          grid: {
                            display: false,
                          },
                          ticks: {
                            stepSize: 20,
                            font: {
                              family: "'Poppins', sans-serif",
                              weight: "400",
                            },
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <div className={styles.boxCenter}>
                    <WaitingForDashboard />
                  </div>
                )}
              </>
            )}

            <>
              {dashboardTotal && (
                <>
                  {dashboardTotal.max_total_harvest ||
                    dashboardTotal.max_final_biomass ? (
                    <div className={styles.textQuadrants}>
                      <div className={styles.itemQuadrants}>{textTopLeft}</div>
                      <div className={styles.itemQuadrants}>{textTopRight}</div>
                      <div className={styles.itemQuadrants}>{textBotLeft}</div>
                      <div className={styles.itemQuadrants}>{textBotRight}</div>
                    </div>
                  ) : null}
                </>
              )}
            </>

            <>
              {dashboardTotal && (
                <>
                  {dashboardTotal.max_total_harvest ||
                    dashboardTotal.max_final_biomass ? (
                    <div className={styles.ItemsAxis}>
                      <div className={styles.itemAxis}>{xAxis}</div>
                      <div className={styles.itemAxis}>{yAxis}</div>
                    </div>
                  ) : null}
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardGlobal;
