import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LandscapeContentHeader
  from '../../../components/LandscapeLayout/LandscapeContentHeader';
import styles from '../Landscape/Landscape.module.scss';
import LogoGray from '../../../assets/img/logo-gray.png';
import LineIcon from '../../../assets/img/line-icon.png';
import gameApi from '../../../api/gameApi';
import { useStoreGame } from '../../../store';
import masterApi from '../../../api/masterApi';

PositionOfHarvestor.propTypes = {};

function PositionOfHarvestor(props) {
  const [stateGame] = useStoreGame();
  const { roundCurrent } = stateGame;

  const [round, setRound] = useState(roundCurrent ? roundCurrent : ' ');
  const qrCode = localStorage.getItem('qrCode');

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await gameApi.getLandscape(qrCode);
        setRound(response?.round);
      } catch (e) {
      }
    })();
  }, []);

  const redirectPage = async () => {
    try {
      await masterApi.redirectPage({
        'qr_code': qrCode,
        'next_page': 'biomass-earning-of-harvester'
      });
      navigate('/biomass-earning');

    } catch (e) {
    }
  };

  return (
    <>
      <main className={'container'}>
        <LandscapeContentHeader title={`Round ${round} - Harvest Map`}
          active={5} />

        <div className={'content-fix center'}>
          <h2 className={'mb-40'}>Waiting for final decision</h2>

          <button className={'btn btn-arrow'}
            onClick={redirectPage}><span>Next</span></button>
        </div>
      </main>

      <footer className={styles.footer}>
        <div className={'container'}>
          <div className={styles.wrapper}>
            <div className={'d-flex'}>
              <img src={LogoGray}
                alt={'Planet C Logo Gray'}
                className={styles.logoFt} />
            </div>

            <p>
              <Link to={'/change-position-harvesters'}
                target={'_blank'}
                className={styles.linkView}>
                <span>Game master view</span>
                <img src={LineIcon} alt={'Line Icon'} />
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default PositionOfHarvestor;
