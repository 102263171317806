import React, { useEffect, useRef, useState } from 'react';
import styles from './MasterWaiting.module.scss';
import logoSmall from '../../../assets/img/favicon.svg';
import favicon from '../../../assets/img/favicon.svg';
import { useStore, useStoreGame } from '../../../store';
import masterApi from '../../../api/masterApi';
import IconClose from '../../../assets/img/close-icon.png';
import IconExpand from '../../../assets/img/expand-icon.png';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';

MasterWaiting.propTypes = {};

function MasterWaiting(props) {
  const [state] = useStore();
  const { qrCode } = state;

  const [stateGame] = useStoreGame();
  const { playerSocketItem } = stateGame;

  const [playerList, setPlayerList] = useState([]);
  const [parkManager, setParkManager] = useState();
  const [active, setActive] = useState();

  let navigate = useNavigate();
  const [enableStartGame, setEnableStartGame] = useState(false);

  const [qrCodeGame, setQrCodeGame] = useState('');
  const urlJoinGame = `${window.location.origin}/join-game/${qrCode}`;

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await masterApi.playersOnline(qrCode);
        setPlayerList(response?.players);
      } catch (e) {
      }
    })();
  }, []);

  useEffect(() => {
    const newPlayerList = [...playerList];
    if (0 === newPlayerList.filter(
      item => item.player_id === playerSocketItem.player_id).length) {
      newPlayerList.push(playerSocketItem);
    }
    setPlayerList(newPlayerList);
  }, [playerSocketItem]);

  useEffect(() => {
    if ((2 <= playerList.length) && (parkManager !== undefined)) {
      setEnableStartGame(true);
    }
  }, [parkManager]);

  useEffect(() => {
    setQrCodeGame(urlJoinGame);
  }, []);

  const handleParkManagerClick = (id, idx) => {
    setParkManager(id);
    setActive(idx);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  const handleStartGame = async () => {
    try {
     await masterApi.startGame({
        'qr_code': qrCode,
        'park_manager_id': parkManager,
      });
      navigate('/landscape', { replace: true });
    } catch (e) {
    }
  };

  const refQrCode = useRef();
  const refPopUpContent = useRef();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (refPopUpContent.current !== undefined &&
        null !== refPopUpContent.current) {
        if (!refPopUpContent.current.contains(event.target)
          && !refQrCode.current.contains(event.target)) {
          setOpenModal(false);
        }
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className={'has-bg'}>
      <div className={styles.container}>
        <div className={styles.masterWaiting}>
          <div className={styles.header}>
            <img src={logoSmall} alt={'Planet C Logo'} />

            <h2 className={'center'}>Choose a Park Manager</h2>

            <div className={styles.qrCodeSmall}
              onClick={handleOpenModal} ref={refQrCode}>
              <div className={`${styles.qrCodeInner} qrCodeSmall`}>
                <QRCode size={80} value={`${qrCodeGame}`} />
                <p className={styles.logoSmall}><img src={logoSmall}
                  alt={'Logo small'} /></p>
                <img className={styles.iconExpand}
                  src={IconExpand}
                  alt={'Expand Icon'} />
              </div>

              <p>Expand QR code</p>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.userList}>
              {0 < playerList.length && playerList.map((player, idx) => (
                <div key={idx}
                  className={`${styles.item} ${active === idx ?
                    styles.active : ''} `}
                  onClick={() => handleParkManagerClick(player.player_id,
                    idx)}>
                  <div className={styles.name}>
                    {player.name}
                  </div>
                </div>
              ))}

              {0 === playerList.length &&
                <p className={styles.noPlayer}>Waiting for the players</p>}
            </div>
          </div>

          <button onClick={handleStartGame}
            className={'btn primary max-340'}
            disabled={!enableStartGame}>Start Game
          </button>
        </div>

        {openModal &&
          <div className={styles.qrCodePopup}>
            <div className={styles.qrCodeLarge}>
              <div className={`${styles.popUpContent} popUpContent`}
                ref={refPopUpContent}>
                <p className={styles.close} onClick={handleCloseModal}>
                  <img src={IconClose}
                    alt={'Icon Close'} /></p>

                <div className={styles.qrCodeLogo}>
                  <QRCode size={300} value={`${qrCodeGame}`} />
                  <p className={styles.favicon}><img src={favicon}
                    alt={'Planet C'} /></p>
                </div>
              </div>
            </div>
          </div>}
      </div>
    </div>
  );
}

export default MasterWaiting;
