import React, {useEffect, useRef, useState} from 'react';
import {useStoreUser} from '../../../store';
import gameApi from '../../../api/gameApi';
import HeaderPlayer from '../../../components/HeaderPlayer';
import userApi from '../../../api/userApi';
import PlayerAddHarvest from './components/PlayerAddHarvest';
import {useNavigate} from 'react-router-dom';
import Message from '../../../components/Message';
import WaitingForLandscape from '../../../components/WaitingForLandscape';

PlayerHarvestProcess.propTypes = {};

function PlayerHarvestProcess(props) {
  const [stateUser] = useStoreUser();
  const {userId, qrCode} = stateUser;

  const [isTimer, setIsTimer] = useState(false);
  const [countDown, setCountDown] = useState(null);

  const refMinutes = useRef();
  const refSeconds = useRef();

  const refModal = useRef([]);
  const navigate = useNavigate();

  const [landscapeView, setLandscapeView] = useState({});
  const [harvesters, setHarvesters] = useState([]);

  const [openHarvestor, setOpenHarvestor] = useState(false);
  const [message, setMessage] = useState('');

  const [waiting, setWaiting] = useState(true);
  const [numberHarvester, setNumberHarvester] = useState(0);

  const [areaCurrent, setAreaCurrent] = useState();
  const [positionOfHarvest, setPositionOfHarvest] = useState([]);

  const [timerEnd, setTimerEnd] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await gameApi.getLandscapePlayer(qrCode);
        setLandscapeView(response?.landscape);
        setWaiting(!waiting);
      } catch (e) {
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await userApi.getHarvestorOfPlayer();
        setNumberHarvester(response?.number_harvesters);
      } catch (e) {
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await userApi.getCountDownTime(qrCode);
        const countdownTime = response.countdown_for_player;
        let timeFormat = countdownTime.split(':');
        let convertTime = (+timeFormat[0]) * 60 +
          (+timeFormat[1]);
        setCountDown(convertTime);
        setTimerEnd(response.set_time_for_player_end_at);
        setIsTimer(true);
      } catch (e) {
      }
    })();
  }, []);

  useEffect(() => {
    let displayMinutes = refMinutes.current;
    let displaySeconds = refSeconds.current;

    const dateNow = Math.floor(Date.now() / 1000);
    const timeLeft = timerEnd - dateNow;

    0 < countDown && setTimeout(() => {
      let minutes;
      let seconds;
      minutes = parseInt(countDown / 60, 10);
      seconds = parseInt(countDown % 60, 10);

      minutes = 10 > minutes ? '0' + minutes : minutes;
      seconds = 10 > seconds ? '0' + seconds : seconds;

      if (displayMinutes && displaySeconds) {
        displayMinutes.innerHTML = minutes;
        displaySeconds.innerHTML = seconds;
      }

      if (countDown > timeLeft) {
        setCountDown(timeLeft - 1);
      } else {
        setCountDown(countDown - 1);
      }
    }, 1000);
    if (0 === countDown) {
      if(displayMinutes && displaySeconds) {
        displayMinutes.innerHTML = '00';
        displaySeconds.innerHTML = '00';
      }
      submitHarvestProcess();
      localStorage.removeItem('harvest');
    }
  }, [countDown]);

  useEffect(() => {
    (async () => {
      try {
        const response = await userApi.getHarvestorOfPlayer();
        setHarvesters(response?.harvesters);
      } catch (e) {
      }
    })();
  }, []);

  const handleArea = (value) => {
    setAreaCurrent(value);
    setOpenHarvestor(true);
    harvesters.map(item => {
      const findHarvest = positionOfHarvest.find(
        x => x.harvester_ids.includes(item.harvester_id));
      if (findHarvest !== undefined && 0 !==
        findHarvest?.harvester_ids.length) {
        item.position = findHarvest.area;
      } else {
        item.position = '';
      }
    });
  };

  const handleHarvestersClick = (id) => {
    const areaByName = positionOfHarvest.find(el => el.area === areaCurrent);
    if (areaByName === undefined) {
      setPositionOfHarvest([
        ...positionOfHarvest,
        {
          'area': areaCurrent,
          'harvester_ids': [id],
        },
      ]);
    } else {
      const findAreaById = positionOfHarvest.find(
        el => el.harvester_ids.includes(id));
      if (!areaByName.harvester_ids.includes(id)) {
        if (findAreaById !== undefined) {
          findAreaById.harvester_ids = findAreaById.harvester_ids.filter(
            el => el !== id);
        }
        areaByName.harvester_ids.push(id);
      } else {
        areaByName.harvester_ids = areaByName.harvester_ids.filter(
          el => el !== id);
      }
    }

    const areaById = positionOfHarvest.find(
      el => el.harvester_ids.includes(id));

    if (areaById !== undefined && areaById.area !== areaByName?.area) {
      areaById.harvester_ids = areaById.harvester_ids.filter(el => el !== id);
    }

    setOpenHarvestor(!openHarvestor);
  };

  const submitHarvestProcess = async () => {
    try {
      await userApi.postChooseLandToHarvest(
        {
          'qr_code': qrCode,
          'position_harvest': positionOfHarvest,
        },
      );
      navigate(`/player-waiting-decision/${userId}`);
      localStorage.removeItem('harvest');

    } catch (e) {
      setMessage(e.response.data);
    }
  };

  const refGameList = useRef();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (refGameList.current && !refGameList.current.contains(event.target)) {
        setOpenHarvestor(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('harvest', JSON.stringify(positionOfHarvest));
  }, [positionOfHarvest]);

  return (
    <div className={'min-vh'}>
      <div className={'container-sm'}>
        <HeaderPlayer
          title={'Time is ticking. Where <br/>should your harvesters go?'}
          numberHarvester={numberHarvester}/>

        <div className={'game-content'}>
          {waiting && <WaitingForLandscape/>}
          {!waiting &&
          <div className={'game-board small'}>
            <div className={'game-title-row'}>
              <div>A</div>
              <div>B</div>
              <div>C</div>
              <div>D</div>
              <div>E</div>
            </div>
            <div className={'game-title-col'}>
              <div>1</div>
              <div>2</div>
              <div>3</div>
              <div>4</div>
            </div>
            <div className={'game-list'} ref={refGameList}>
              {Object.entries(landscapeView).map((value, index) => (
                <div key={index}
                     className={`game-item 
                     ${value[1]?.is_protected === 'true' ? 'active' : ''}`}
                     style={{backgroundColor: value[1].color}}
                     onClick={() => handleArea(value[0], index)}
                     ref={(element) => {
                       refModal.current[index] = element;
                     }}>
                  <ul className={'harvesters'}>
                    {positionOfHarvest.map((item) => (
                      item.area === value[0] ?
                        item.harvester_ids.map(
                          el => (<li key={el}><span>{el}</span></li>)) :
                        ''
                    ))}
                  </ul>
                </div>
              ))}
            </div>

            {openHarvestor &&
            <PlayerAddHarvest
              harvesters={harvesters}
              areaCurrent={areaCurrent}
              handleHarvestersClick={handleHarvestersClick}
              position={positionOfHarvest}/>}
          </div>}

          {isTimer &&
          <div className={'time-left'}>
            <h3 className={'center'}>Time Left</h3>
            <div id={'countDown'}
                 className={'count-down'}>
              <span ref={refMinutes}>--</span>
              <span className={'split'}>:</span>
              <span ref={refSeconds}>--</span>
            </div>
          </div>}

          {message && <Message notification={message}/>}

          {isTimer && <div className={'center'}>
            <button type={'button'}
                    className={'btn primary'}
                    onClick={submitHarvestProcess}>Finish
            </button>

            <p className={'center text-hint'}>Have you sent your harvesters?</p>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default PlayerHarvestProcess;
