import React from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import Select from 'react-select';
import {ErrorMessage} from '@hookform/error-message';

SelectField2.propTypes = {
  control: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  clickSelect: PropTypes.func,
};

function SelectField2(props) {
  const {control, placeholder, name, options, disabled, errors, protectedArea} = props;

  const customStyles = {
    control: () => ({
      border: 'none',
      display: 'flex',
    }),
    valueContainer: (provided, state) => {
      const paddingLeft = 0;
      const paddingRight = 0;
      return {...provided, paddingLeft, paddingRight};
    },
    indicatorSeparator: () => ({
      background: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#788180',
      display: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#222624',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#222624',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '205px',
    }),
    option: (provided, {isSelected}) => ({
      ...provided,
      color: '#222624',
      fontSize: '14px',
      backgroundColor: isSelected ? '#ddfde6' : '',
      '&:hover': {
        backgroundColor: '#ddfde6',
      },
      '&:focus': {
        backgroundColor: '#ddfde6',
      },
    }),
  };

  return (
    <Controller
      render={({field: {onChange, name, ref, value}}) => {
        return (
          <>
            <Select
              inputRef={ref}
              options={options}
              onChange={(value) => onChange(value?.value)}
              placeholder={placeholder}
              name={name}
              autosize={true}
              defaultValue={value}
              styles={customStyles}
              value={options.find((ele) => ele.value === value)}
              isDisabled={disabled}
              className={protectedArea.includes(value) ? 'select-protected' : ''}/>

            <ErrorMessage
              errors={errors}
              name={name}
              render={({message}) =>
                <p className={'error'}>{message}</p>}/>
          </>
        );
      }}
      name={name}
      control={control}
    />
  );
}

export default SelectField2;
