import React from 'react';
import gameRule3 from '../../../assets/img/game-rule3.png';
import gameRule32 from '../../../assets/img/game-rule32.png';

Information3.propTypes = {};

function Information3(props) {
  return (
    <div className={'center'}>
      <img src={gameRule3} alt={'Game Rule 3'} width={'350'} height={'289'}/>

      <img src={gameRule32} alt={'Game Rule 32'} width={'328'} height={'80'}/>

      <h2>Reproduction will succeed or<br/>
        fail based on what happens<br/>
        in the landscape.</h2>

      <p>People live in this landscape and harvesters <br/>
        collect biomass. The reproductive success of a <br/>
        nest is linked to the disturbance by harvesters <br/>
        on its square and its neighbourhood.</p>
    </div>
  );
}

export default Information3;
