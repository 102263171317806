import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';
import copyIcon from '../../../../../assets/img/copy-icon.svg';
import favicon from '../../../../../assets/img/favicon.svg';
import styles from './QRCode.module.scss';

function QRCodeGame({ qrCodeGame }) {
  const inputRef = useRef();
  const copyRef = useRef();

  const getQRCodeLink = () => {
    inputRef.current.focus();
    const inputValue = inputRef.current.value;
    inputRef.current.select();
    inputRef.current.setSelectionRange(0, 99999);

    if (navigator.clipboard) {
      navigator.clipboard.writeText(inputValue);
    } else {
      document.execCommand('copy');
    }

    copyRef.current.style.opacity = 1;
    copyRef.current.style.visibility = 'visible';

    setTimeout(() => {
      if (copyRef.current) {
        copyRef.current.style.opacity = 0;
        copyRef.current.style.visibility = 'hidden';
      }
    }, 800);
  };

  return (
    <div className={styles.qrCodeGame}>
      <div>
        <h2 className={'center'}>Scan QR code to start</h2>

        <div className={styles.qrCodeImg}>
          <QRCode size={205} value={`${qrCodeGame}`} />
          <div className={styles.logo}><img src={favicon} alt={'Planet C'} />
          </div>
        </div>

        <p className={'center fw-500'}>Link to join the game</p>

        <div className={styles.linkBox}>
          <input type="text"
            value={`${qrCodeGame}`}
            id="qrCodeLink"
            ref={inputRef}
            readOnly />
          <button onClick={getQRCodeLink}
            className={`${styles.copyQRCode} btn`}>
            <img src={copyIcon} alt={'Copy Icon'} />
          </button>

          <div id={'copied'}
            className={styles.copyText}
            ref={copyRef}>Copied
          </div>
        </div>

        <div className={styles.startGame}>
          <Link to={'/master-waiting'} className={'btn primary'}>Start the
            game</Link>
        </div>
      </div>
    </div>
  );
}

export default QRCodeGame;
