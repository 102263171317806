import axiosClient from './axiosClient';

const gameApi = {
  pmChooseProtectedAreas(data) {
    const url = '/choose-protected-areas';
    return axiosClient.post(url, data);
  },

  getLandscape(qrCode) {
    const url = `/get-landscape/${qrCode}`;
    return axiosClient.get(url);
  },

  getLandscapePlayer(qrCode) {
    const url = `/get-landscape/${qrCode}`;
    return axiosClient.get(url);
  },

  getGameInfo(qrCode) {
    const url = `/game-info/${qrCode}`;
    return axiosClient.get(url);
  },

  getGameInfoMaster(qrCode) {
    const url = `/game-info/${qrCode}`;
    return axiosClient.get(url);
  },

  getBiomassEarningOfHarvester(qrCode) {
    const url = `/biomass-earning-of-harvester/${qrCode}`;
    return axiosClient.get(url);
  },

  getBiomassEarningOfHarvesterMaster(qrCode) {
    const url = `/biomass-earning-of-harvester/${qrCode}`;
    return axiosClient.get(url);
  },

  postRedirectPageMapOfHarvester(data) {
    const url = '/redirect-page';
    return axiosClient.post(url, data);
  },

  postNewRound(data) {
    const url = '/new-round';
    return axiosClient.post(url, data);
  },

  playAgain(data) {
    const url = '/play-again';
    return axiosClient.post(url, data);
  },

  stopGame(data) {
    const url = '/stop-game';
    return axiosClient.post(url, data);
  }

};

export default gameApi;
