import React, {useEffect, useRef, useState} from 'react';
import styles from '../Landscape/Landscape.module.scss';
import LogoGray from '../../../assets/img/logo-gray.png';
import LineIcon from '../../../assets/img/line-icon.png';
import LandscapeContentHeader
  from '../../../components/LandscapeLayout/LandscapeContentHeader';
import TimerParkManagerForm from './components/TimerParkManagerForm';
import masterApi from '../../../api/masterApi';
import {Link, useNavigate} from 'react-router-dom';
import gameApi from '../../../api/gameApi';
import {useStoreGame} from '../../../store';
import {HandleCountDownTimerPM} from '../../../utils/TimerCountDown';

TimerParkManager.propTypes = {};

function TimerParkManager(props) {
  const [stateGame] = useStoreGame();
  const {roundCurrent} = stateGame;

  const [landscapeView, setLandscapeView] = useState({});
  const [round, setRound] = useState(roundCurrent ? roundCurrent : ' ');

  const [showView, setShowView] = useState(false);
  const [timeLeftShow, setTimeLeftShow] = useState(false);

  const [timerCurrent, setTimerCurrent] = useState(null);
  const refMinutes = useRef(null);
  const refSeconds = useRef(null);

  const [timerEnd, setTimerEnd] = useState(null);

  const navigate = useNavigate();
  const qrCode = localStorage.getItem('qrCode');

  useEffect(() => {
    (async () => {
      const response = await gameApi.getLandscape(qrCode);
      setLandscapeView(response?.landscape);
      setRound(response?.round);
    })();
  }, []);

  const handleShowView = () => {
    setShowView(!showView);
  };

  const handleRedirectPage = async () => {
    try {
      await masterApi.redirectPage({
        'qr_code': qrCode,
        'next_page': '/protected-area'
      });
      navigate('/protected-area', {replace: true});
    } catch (e) {
    }
  };

  const handleSubmit = async (values) => {
    let time = values.minutes + ':' + values.seconds;
    try {
      const response = await masterApi.setTimeForParkManager(
        {
          'qr_code': qrCode,
          'time': time,
        });
      const currentTime = response?.game.countdown_time.countdown_for_player;
      let timeFormat = currentTime.split(':');
      let convertTime = (+timeFormat[0]) * 60 +
        (+timeFormat[1]);

      setTimerCurrent(convertTime);
      setTimerEnd(response?.game.countdown_time.set_time_for_pm_end_at);

      setTimeLeftShow(true);
    } catch (e) {
    }
  };

  useEffect(() => {
    (async () => {
      let displayMinutes = refMinutes.current;
      let displaySeconds = refSeconds.current;

      const dateNow = Math.floor(Date.now() / 1000);
      const timeLeft = timerEnd - dateNow;

      0 < timerCurrent && setTimeout(() => {
        let minutes;
        let seconds;
        minutes = parseInt(timerCurrent / 60, 10);
        seconds = parseInt(timerCurrent % 60, 10);

        minutes = 10 > minutes ? '0' + minutes : minutes;
        seconds = 10 > seconds ? '0' + seconds : seconds;

        if (displayMinutes && displaySeconds) {
          displayMinutes.innerHTML = minutes;
          displaySeconds.innerHTML = seconds;
        }

        if (timerCurrent > timeLeft) {
          setTimerCurrent(timeLeft - 1);
        } else {
          setTimerCurrent(timerCurrent - 1);
        }
      }, 1000);

      if (0 === timerCurrent) {
        if (displayMinutes && displaySeconds) {
          await handleRedirectPage();
          displayMinutes.innerHTML = '00';
          displaySeconds.innerHTML = '00';
        }
        setTimeLeftShow(false);
      }
    })()
  }, [timerCurrent]);

  const onFocus = async () => {
    const timeCountDown = await HandleCountDownTimerPM(qrCode);
    setTimerCurrent(timeCountDown?.time_current);
    setTimerEnd(timeCountDown?.time_end);
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [timeLeftShow]);

  return (
    <>
      <main className={'container'}>
        <LandscapeContentHeader
          title={`Round ${round} - Set Time for Park manager`}
          active={2}/>

        {!timeLeftShow &&
        <TimerParkManagerForm onSubmit={handleSubmit}/>
        }

        {timeLeftShow &&
        <>
          <div className='game-content-master'>
            <div className={'game-board'}>
              <div className={'game-title-row'}>
                <div>A</div>
                <div>B</div>
                <div>C</div>
                <div>D</div>
                <div>E</div>
              </div>
              <div className={'game-title-col'}>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
              </div>
              <div className={'game-view'}>
                {!showView &&
                <div className={'game-list'}>
                  {Object.entries(landscapeView).map((value, index) => (
                    <div key={index}
                         className={'game-item'}
                         style={{backgroundColor: value[1].color}}>
                    </div>
                  ))}
                </div>}

                {showView &&
                <div className={'game-list'}>
                  {Object.entries(landscapeView).map((value, index) => (
                    <div key={index}
                         className={'game-item'}
                         style={{backgroundColor: value[1].color}}>
                      {0 < value[1].animal_qty &&
                      <p className={'animal-icon'}>{[
                        ...Array(value[1].animal_qty)].map(
                        (currentValue, i) => (
                          <span key={i}>&nbsp;</span>))}</p>}
                    </div>
                  ))}
                </div>}
              </div>
            </div>
          </div>

          <div className={'time-left center'}>
            <h3>Time left</h3>
            <div id={'countDown'} className={'count-down'}>
              <span ref={refMinutes}>--</span>
              <span className={'split'}>:</span>
              <span ref={refSeconds}>--</span>
            </div>
          </div>
        </>
        }
      </main>

      <footer className={styles.footer}>
        <div className={'container'}>
          <div className={styles.wrapper}>
            <div className={'d-flex'}>
              <img src={LogoGray}
                   alt={'Planet C Logo Gray'}
                   className={styles.logoFt}/>
              {timeLeftShow && 'Park manager'}

              {timeLeftShow &&
              <div onClick={handleShowView} className={'switch-toggle'}>
                <input type={'checkbox'} name={'switch'} value={'1'}/>
                <label htmlFor={'switch'}>Switch</label>
              </div>}
            </div>

            {timeLeftShow &&
            <p>
              <Link to={'/landscape-view'}
                    target={'_blank'}
                    className={styles.linkView}>
                <span>Game master view</span>
                <img src={LineIcon} alt={'Line Icon'}/>
              </Link>
            </p>
            }
          </div>
        </div>
      </footer>
    </>
  );
}

export default TimerParkManager;
