import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../../../../../components/FormFields/InputField';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Notification from "../../../../../components/Notification";

CreateAccountForm.propTypes = {
  onSubmit: PropTypes.func,
  notification: PropTypes.object,
  message: PropTypes.string,
};

function CreateAccountForm({onSubmit, notification, message}) {
  const schema = yup.object({
    username: yup.string()
      .required('Please enter your username.')
      .min(5, 'Please enter at least 5 characters.')
      .matches(/^\S*$/, 'Username does not allow spaces.',
        (value) => console.log(value) ),
    password: yup.string()
      .required('Please enter your password.')
      .min(8, 'Please enter at least 8 characters.'),
    password_confirmation: yup.string()
      .required('Please enter confirm password.')
      .oneOf([yup.ref('password')], 'Passwords does not match.'),
  });
  const {control, handleSubmit, formState: {errors}, reset} = useForm(
    {
      defaultValues: {
        username: '',
        password: '',
        password_confirmation: ''
      },
      resolver: yupResolver(schema),
    });

  const onHandleSubmit = async (values) => {
    if (onSubmit) {
      await onSubmit(values);
    }
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}
          className={message ? 'error' : ''}>
      <div className={'form-item'}>
        <InputField type={'text'}
                    placeholder={'Host Username'}
                    name={'username'}
                    label={'Username'}
                    autocomplete={'name'}
                    control={control}
                    errors={errors}
        />
      </div>

      <div className={'form-item'}>
        <InputField type={'password'}
                    placeholder={'Password'}
                    name={'password'}
                    label={'Password'}
                    autocomplete={'password'}
                    control={control}
                    errors={errors}
        />
      </div>

      <div className={'form-item'}>
        <InputField
          type={'password'}
          name={'password_confirmation'}
          placeholder={'Confirm Password'}
          label={'Confirm Password'}
          autocomplete={'password_confirmation'}
          control={control}
          errors={errors}/>

        {Object.keys(notification).length > 0 && <Notification notification={notification}/>}
      </div>

      <div className={'form-actions'}>
        <button type={'submit'}>Create Account</button>
      </div>
    </form>
  );
}

export default CreateAccountForm;
