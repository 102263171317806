import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Add a request interceptor
axiosClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem('accessToken');

  if (token) {
    config.headers = { 
      'Authorization': `Bearer ${token}`,
    }
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axiosClient.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response.data;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (401 === error.response.status) {
    localStorage.clear();
  }
  return Promise.reject(error);
});

export  default axiosClient;
