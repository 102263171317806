import React from 'react';
import styles from './LoadingSpinner.module.scss'

LoadingSpinner.propTypes = {};

function LoadingSpinner(props) {
  return (
    <div className={styles.spinner}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default LoadingSpinner;
