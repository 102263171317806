import React from 'react';
import IconMessage from '../../assets/img/info-icon.svg';
import styles from './Message.module.scss'

Message.propTypes = {};
function Message({notification}) {
  return (
    <div className={styles.messageNotification}>
      <img src={IconMessage} alt={'Info Icon'}/>
      <span>{notification}</span>
    </div>
  );
}

export default Message;
