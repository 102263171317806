import React from 'react';
import gameRule2 from '../../../assets/img/game-rule2.png';

Information2.propTypes = {};

function Information2(props) {
  return (
    <div className={'center'}>
      <img src={gameRule2}
           alt={'Game rule 2'}
           width={'350'}
           height={'289'}/>

      <h2>Your objective is to ensure the<br/>
        reproduction of a protected<br/>
        migratory species.</h2>

      <p>The adults of the species arrive every round<br/>
        and nest in all squares with suitable habitat.<br/>
        A square is a suitable habitat if it contains<br/>
        at least 2 biomass and<br/>
        if there is not another nest already.</p>
    </div>
  );
}

export default Information2;
