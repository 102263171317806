import React, {useState, useEffect, useRef} from "react";
import styles from "./Dashboard.module.scss";
import Logo from "../../../components/Logo";
import {Link} from "react-router-dom";
import avt from "../../../assets/img/avt.png";
import iconPlayer from "../../../assets/img/icon-db-player.svg";
import iconHarvest from "../../../assets/img/icon-db-harvest.svg";
import iconSessions from "../../../assets/img/icon-db-sessions.svg";
import iconBiomass from "../../../assets/img/icon-db-biomass.svg";
import iconTimer from "../../../assets/img/icon-db-timer.svg";
import iconNewBorn from "../../../assets/img/icon-db-newborn.svg";
import dashboardApi from "../../../api/dashboardApi";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from "chart.js";
import {Line} from "react-chartjs-2";
import masterApi from "../../../api/masterApi";
import {useStore} from "../../../store";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend
);

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            onClick: null,
            position: "bottom",
            labels: {
                usePointStyle: true,
                pointStyle: "circle",
                boxWidth: 6,
                boxHeight: 4,
                padding: 32,
                font: {
                    size: 14,
                    family: "'Poppins', sans-serif",
                    weight: "500",
                },
            },
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                stepSize: 10,
                font: {
                    family: "'Poppins', sans-serif",
                    weight: "400",
                },
            },
        },
        y: {
            beginAtZero: true,
            grid: {
                display: true,
            },
            ticks: {
                stepSize: 100,
                font: {
                    family: "'Poppins', sans-serif",
                    weight: "400",
                },
            },
        },
    },
};

function Dashboard(props) {
    const [state] = useStore();
    const [showDropDown, setShowDropDown] = useState(false);
    const dropDownRef = useRef(null);
    const [dashboardTotal, setDashboardTotal] = useState(null);

    const handleShowDropDown = () => {
        setShowDropDown((showDropDown) => !showDropDown);
    };

    const logoutMaster = async () => {
        try {
            const qrCode = localStorage?.getItem('qrCode');
            await masterApi.logoutMaster({
                "qr_code": qrCode ? qrCode : "0"
            });
            localStorage.clear();
            window.location.reload();
        } catch (e) {
        }
    }

    useEffect(() => {
        function handleClickOutDropDown(e) {
            if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
                setShowDropDown(false);
            }
        }

        document.addEventListener("click", handleClickOutDropDown);
        return () => {
            document.removeEventListener("click", handleClickOutDropDown);
        };
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const response = await dashboardApi.getDashboard();
                setDashboardTotal(response);
            } catch (e) {
            }
        })();
    }, []);

    return (
        <>
            <div className={styles.headerDashboard}>
                <div className={styles.headerDashboardInr}>
                    <div className={styles.hdrLeft}>
                        <div className="logo">
                            <Logo/>
                        </div>
                    </div>
                    <div className={styles.hdrRight}>
                        <div className={styles.hdrItem}>
                            <Link to={"/"} className={styles.backHome}>
                                Homepage
                            </Link>
                        </div>
                        <div className={styles.hdrItem}>
                            <Link to={"/dashboard-global"} className={styles.btnGlobal}>
                                Global
                            </Link>
                        </div>
                        <div className={styles.hdrItem}>
                            <div
                                className={styles.user}
                                ref={dropDownRef}
                                onClick={handleShowDropDown}
                            >
                                <div className={styles.avt}>
                                    <img src={avt} alt="avt-user"/>
                                </div>
                                <div className={styles.extraUser}>
                                    <div className={styles.txtUser}>
                                        {localStorage.getItem("username")}
                                    </div>
                                    <div className={`${styles.txtUser} ${styles.colorGray}`}>
                                        Game master
                                    </div>
                                </div>
                            </div>

                            {showDropDown && (
                                <div className={styles.userPopup}>
                                    <ul>
                                        {'Superadmin' === state.level && (
                                            <>
                                                <li><Link to={"/master-account"}>Account List</Link></li>
                                                <li><Link to={"/create-account"}>Create Account</Link></li>
                                            </>
                                        )}
                                        <li onClick={logoutMaster}>Sign Out</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.section}>
                <div className={styles.sectionInr}>
                    <div className={styles.title}>Dashboard</div>
                    <div className={`${styles.flexBox} ${styles.col7}`}>
                        <div className={styles.flexItem}>
                            <div className={styles.subTitle}>Statistic</div>
                            <div className={`${styles.cntDashboard} ${styles.flexColumn}`}>
                                <div className={styles.statisticItems}>
                                    <div className={styles.item}>
                                        <div className={styles.itemIcon}>
                                            <img src={iconPlayer} alt="Player"/>
                                        </div>
                                        <div className={styles.itemTxt}>
                                            {dashboardTotal && (
                                                <p className={styles.number}>
                                                    {dashboardTotal.dashboard[0].total_players}
                                                </p>
                                            )}
                                            <p className={styles.brief}>players</p>
                                        </div>
                                    </div>
                                    <div className={styles.item}>
                                        <div className={styles.itemIcon}>
                                            <img src={iconHarvest} alt="harvest"/>
                                        </div>
                                        <div className={styles.itemTxt}>
                                            {dashboardTotal && (
                                                <p className={styles.number}>
                                                    {dashboardTotal.dashboard[0].avg_harvest}
                                                </p>
                                            )}
                                            <p className={styles.brief}>average harvest</p>
                                        </div>
                                    </div>
                                    <div className={styles.item}>
                                        <div className={styles.itemIcon}>
                                            <img src={iconSessions} alt="sessions"/>
                                        </div>
                                        <div className={styles.itemTxt}>
                                            {dashboardTotal && (
                                                <p className={styles.number}>
                                                    {dashboardTotal.dashboard[0].total_sessions}
                                                </p>
                                            )}
                                            <p className={styles.brief}>sessions</p>
                                        </div>
                                    </div>
                                    <div className={styles.item}>
                                        <div className={styles.itemIcon}>
                                            <img src={iconBiomass} alt="biomass"/>
                                        </div>
                                        <div className={styles.itemTxt}>
                                            {dashboardTotal && (
                                                <p className={styles.number}>
                                                    {dashboardTotal.dashboard[0].avg_biomass}
                                                </p>
                                            )}
                                            <p className={styles.brief}>average final biomass</p>
                                        </div>
                                    </div>
                                    <div className={styles.item}>
                                        <div className={styles.itemIcon}>
                                            <img src={iconTimer} alt="hours"/>
                                        </div>
                                        <div className={styles.itemTxt}>
                                            {dashboardTotal && (
                                                <p className={styles.number}>
                                                    {dashboardTotal.dashboard[0].hours}
                                                </p>
                                            )}
                                            <p className={styles.brief}>hours</p>
                                        </div>
                                    </div>
                                    <div className={styles.item}>
                                        <div className={styles.itemIcon}>
                                            <img src={iconNewBorn} alt="newborns"/>
                                        </div>
                                        <div className={styles.itemTxt}>
                                            {dashboardTotal && (
                                                <p className={styles.number}>
                                                    {dashboardTotal.dashboard[0].avg_newborns}
                                                </p>
                                            )}
                                            <p className={styles.brief}>average newborns</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.flexItem}>
                            <div className={styles.subTitle}>Harvest</div>
                            <div className={`${styles.cntDashboard} ${styles.chart}`}>
                                <div className={styles.ctnChart}>
                                    {dashboardTotal && (
                                        <Line
                                            options={options}
                                            data={{
                                                labels: dashboardTotal.dashboard[0].harvesters.labels,
                                                datasets: [
                                                    {
                                                        id: 1,
                                                        label: dashboardTotal.dashboard[0].name,
                                                        data: dashboardTotal.dashboard[0].harvesters.number,
                                                        borderColor: "#3D69B0",
                                                        backgroundColor: "#3D69B0",
                                                    },
                                                ],
                                            }}
                                        />
                                    )}
                                    {!dashboardTotal && (
                                        <div className={styles.noData}>No Data</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.flexBox} ${styles.col5}`}>
                        <div className={styles.flexItem}>
                            <div className={styles.subTitle}>Biomass</div>
                            <div className={styles.cntDashboard}>
                                <div className={styles.ctnChart}>
                                    {dashboardTotal && (
                                        <Line
                                            options={options}
                                            data={{
                                                labels: dashboardTotal.dashboard[0].biomass.labels,
                                                datasets: [
                                                    {
                                                        id: 2,
                                                        label: dashboardTotal.dashboard[0].name,
                                                        data: dashboardTotal.dashboard[0].biomass.number,
                                                        borderColor: "#3D69B0",
                                                        backgroundColor: "#3D69B0",
                                                    },
                                                ],
                                            }}
                                        />
                                    )}
                                    {!dashboardTotal && (
                                        <div className={styles.noData}>No Data</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.flexItem}>
                            <div className={styles.subTitle}>Newborns</div>
                            <div className={styles.cntDashboard}>
                                <div className={styles.ctnChart}>
                                    {dashboardTotal && (
                                        <Line
                                            options={options}
                                            data={{
                                                labels: dashboardTotal.dashboard[0].newborns.labels,
                                                datasets: [
                                                    {
                                                        id: 3,
                                                        label: dashboardTotal.dashboard[0].name,
                                                        data: dashboardTotal.dashboard[0].newborns.number,
                                                        borderColor: "#3D69B0",
                                                        backgroundColor: "#3D69B0",
                                                    },
                                                ],
                                            }}
                                        />
                                    )}
                                    {!dashboardTotal && (
                                        <div className={styles.noData}>No Data</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
