import React, {useEffect, useState} from 'react';
import HeaderPlayer from '../../../components/HeaderPlayer';
import {useStoreGame} from '../../../store';
import gameApi from '../../../api/gameApi';
import LoadingSpinner from '../../../components/LoadingSpinner';
import WaitingForLandscape from '../../../components/WaitingForLandscape';
import userApi from '../../../api/userApi';

PlayerWaitingFinalDecision.propTypes = {};

function PlayerWaitingFinalDecision(props) {
  const [stateGame] = useStoreGame();

  const [landscapeView, setLandscapeView] = useState({});
  const [waiting, setWaiting] = useState(true);

  const [numberHarvester, setNumberHarvester] = useState(0);
  const qrCode = localStorage.getItem('qrCode');

  useEffect(() => {
    localStorage.removeItem('harvest');
  }, [])

  useEffect(() => {
    (async() => {
      try {
        const response = await gameApi.getLandscapePlayer(qrCode);
        setLandscapeView(response?.landscape);
        setWaiting(false);
      } catch (e) {
      }
    })();
  }, [stateGame.landscapePlayer]);

  useEffect(() => {
    (async() => {
      try {
        const response = await userApi.getHarvestorOfPlayer();
        setNumberHarvester(response?.number_harvesters);
      } catch (e) {
      }
    })();
  }, []);

  return (
    <div className={'min-vh'}>
      <div className={'container-sm'}>
        <HeaderPlayer title={'Waiting for the final decision!'}
                      numberHarvester={numberHarvester}/>

        <div className={'game-content'}>
          {waiting && <WaitingForLandscape/>}

          {!waiting &&
           <div className={'game-board small'}>
             <div className={'game-title-row'}>
               <div>A</div>
               <div>B</div>
               <div>C</div>
               <div>D</div>
               <div>E</div>
             </div>
             <div className={'game-title-col'}>
               <div>1</div>
               <div>2</div>
               <div>3</div>
               <div>4</div>
             </div>
             <div className={'game-list'}>
               {Object.entries(landscapeView)
                      .map(([positionKey, positionData], index) => (
                        <div key={index}
                             className={`game-item 
                     ${positionData?.is_protected === 'true' ? 'active' : ''}`}
                             style={{backgroundColor: positionData.color}}>
                          {0 < positionData.harvester_qty &&
                           <ul className={'harvesters'}>
                             {[...Array(positionData.harvester_qty)].map(
                               (currentValue, i) => (
                                 <li key={i}><span>&nbsp;</span></li>))}
                           </ul>
                          }
                        </div>
                      ))}
             </div>
           </div>}

          <LoadingSpinner/>
        </div>
      </div>
    </div>
  );
}

export default PlayerWaitingFinalDecision;
