import React, {useEffect} from 'react';
import Logo from '../../../components/Logo';
import {Link} from 'react-router-dom';
import background from '../../../assets/img/login-bg.jpg';
import styles from './CreateGame.module.scss';
import {actions, useStore} from '../../../store';

CreateGame.propTypes = {};

function CreateGame(props) {
  const [state, dispatch] = useStore();
  const {expired} = state;
  const dateNow = Math.floor(Date.now() / 1000);

  useEffect(() => {
    if (0 > parseInt(expired) - dateNow) {
      dispatch(actions.masterLogout(null));
    }
  }, []);

  return (
    <div className={'min-vh has-bg'}
         style={{backgroundImage: `url(${background})`}}>
      <div className={styles.createGame}>

        <div className={'logo-small'}><Logo/></div>

        <h2 className={'center'}>What are you up to</h2>

        <div className={styles.btnGroup}>
          <Link to={'/dashboard'} className={'btn secondary'}>Dashboard</Link>
          <Link to={'/organization'} className={'btn primary'}>Create a
            game</Link>
        </div>
      </div>
    </div>
  );
}

export default CreateGame;
