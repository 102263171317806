import React, {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import HeaderPlayer from '../../../components/HeaderPlayer';
import gameApi from '../../../api/gameApi';
import userApi from '../../../api/userApi';
import WaitingForLandscape from '../../../components/WaitingForLandscape';
import {HandleCountDownTimerPM} from "../../../utils/TimerCountDown";

PlayerViewProtectedArea.propTypes = {};

function PlayerViewProtectedArea(props) {
  const [isTimer, setIsTimer] = useState(null);
  const [countDown, setCountDown] = useState(null);
  const [timerEnd, setTimerEnd] = useState(null);

  const refMinutes = useRef();
  const refSeconds = useRef();

  const navigate = useNavigate();
  const [landscapeView, setLandscapeView] = useState([]);

  const [waiting, setWaiting] = useState(true);
  const [numberHarvester, setNumberHarvester] = useState(0);

  const qrCode = localStorage.getItem('qrCode');

  useEffect(() => {
    (async() => {
      try {
        const response = await gameApi.getLandscapePlayer(qrCode);
        setLandscapeView(response?.landscape);
        setWaiting(!waiting);
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    (async() => {
      try {
        const response = await userApi.getCountDownTime(qrCode);

        const countdownTime = response?.countdown_for_pm;
        let timeFormat = countdownTime.split(':');
        let convertTime = (+timeFormat[0]) * 60 +
          (+timeFormat[1]);

        setCountDown(convertTime);
        setTimerEnd(response.set_time_for_pm_end_at);
        setIsTimer(true);
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    let displayMinutes = refMinutes.current;
    let displaySeconds = refSeconds.current;

    const dateNow = Math.floor(Date.now() / 1000);
    const timeLeft = timerEnd - dateNow;

    0 < countDown && setTimeout(() => {
      let minutes;
      let seconds;
      minutes = parseInt(countDown / 60, 10);
      seconds = parseInt(countDown % 60, 10);

      minutes = 10 > minutes ? '0' + minutes : minutes;
      seconds = 10 > seconds ? '0' + seconds : seconds;

      if(displayMinutes && displaySeconds) {
        displayMinutes.innerHTML = minutes;
        displaySeconds.innerHTML = seconds;
      }

      if (countDown > timeLeft) {
        setCountDown(timeLeft - 1);
      } else {
        setCountDown(countDown - 1);
      }
    }, 1000);
    if (0 === countDown) {
      if (displayMinutes && displaySeconds) {
        displayMinutes.innerHTML = '00';
        displaySeconds.innerHTML = '00';
      }

      navigate('/player-preservation-zone', {replace: true});
    }
  }, [countDown]);

  useEffect(() => {
    (async() => {
      try {
        const response = await userApi.getHarvestorOfPlayer();
        setNumberHarvester(response?.number_harvesters);
      } catch (e) {
      }
    })();
  }, []);

  const onFocusPlayer = async () => {
    const timeCountDown = await HandleCountDownTimerPM(qrCode);
    setCountDown(timeCountDown?.time_current);
    setTimerEnd(timeCountDown?.time_end);
  };

  useEffect(() => {
    window.addEventListener("focus", onFocusPlayer);
    return () => {
      window.removeEventListener("focus", onFocusPlayer);
    };
  }, []);

  return (
    <div className={'min-vh'}>
      <div className={'container-sm'}>
        <HeaderPlayer title={'A coffee and <br/>Waiting the park manager'}
                      numberHarvester={numberHarvester}/>

        <div className={'game-content'}>
          {waiting && <WaitingForLandscape/>}

          {!waiting &&
           <div className={'game-board small'}>
             <div className={'game-title-row'}>
               <div>A</div>
               <div>B</div>
               <div>C</div>
               <div>D</div>
               <div>E</div>
             </div>
             <div className={'game-title-col'}>
               <div>1</div>
               <div>2</div>
               <div>3</div>
               <div>4</div>
             </div>
             <div className={'game-list'}>
               {Object.entries(landscapeView).map((value, index) => (
                 <div key={index}
                      className={`game-item 
                     ${value[1]?.is_protected === 'true' ? 'active' : ''}`}
                      style={{backgroundColor: value[1].color}}>
                 </div>
               ))}
             </div>
           </div>}

          {isTimer &&
           <div className={'time-left'}>
             <h3 className={'center'}>Time Left</h3>
             <div id={'countDown'}
                  className={'count-down'}>
               <span ref={refMinutes}>--</span>
               <span className={'split'}>:</span>
               <span ref={refSeconds}>--</span>
             </div>
           </div>}
        </div>
      </div>
    </div>
  );
}

export default PlayerViewProtectedArea;
