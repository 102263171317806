import React, {useEffect, useState} from 'react';
import Text from '../../../components/Text';
import background from '../../../assets/img/login-bg.jpg';
import QRCodeGame from './components/QRCodeGame';
import {useStore} from '../../../store';

StartGame.propTypes = {};

function StartGame(props) {
  const [state] = useStore();
  const {qrCode} = state;

  const [qrCodeGame, setQrCodeGame] = useState('');
  const urlJoinGame = `${window.location.origin}/join-game/${qrCode}`;

  useEffect(() => {
    if (qrCode) {
      setQrCodeGame(urlJoinGame);
    }
  }, [qrCode, urlJoinGame]);

  return (
    <>
      <div className={'has-bg'} style={{backgroundImage: `url(${background})`}}>
        <div className={'container'}>
          <div className={'box'}>
            <Text/>

            <QRCodeGame qrCodeGame={qrCodeGame}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default StartGame;
