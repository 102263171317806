import React from 'react';
import gameRule1 from '../../../../../assets/img/game-rule1.png';
import biomass from '../../../../../assets/img/game-rule12.png';

GameRule1.propTypes = {};

function GameRule1({numberHarvester}) {
  return (
    <div className={'center'}>
      <img src={gameRule1}
           alt={'Game Rule 1'}
           width={'350'}
           height={'289'}/>

      <img src={biomass}
           alt={'Biomass'}
           width={'237'}
           height={'40'}/>

      <h2>Welcome! You are the leader<br/>
        of a clan of {numberHarvester} harvester(s).</h2>

      <p>You clan will have {numberHarvester} harvester(s).<br/>They depend on your
        decisions to live a good <br/> life. The landscape where you will be living
        is<br/> made of 20 squares identified by a letter and a<br/> number. Each square
        contains biomass, the<br/> colour tells you how much.</p>
    </div>
  );
}

export default GameRule1;
