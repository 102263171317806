import {
  GENERATE_QRCODE,
  MASTER_LOGIN,
  MASTER_LOGOUT,
  USER_LOGIN,
  USER_REFRESH_TOKEN,
  GAME_MASTER,
  GAME_PLAYER,
  GAME_MASTER_WAITING,
  MASTER_REFRESH_TOKEN
} from './constants';

export const initialState = {
  accessToken: localStorage.getItem('accessToken') || '',
  qrCode: localStorage.getItem('qrCode') || '',
  expired: localStorage.getItem('expired') || '',
  role: localStorage.getItem('role') || '',
  level: localStorage.getItem('level' || ''),
  username: localStorage.getItem('username' || '')
};

export const initialUserState = {
  userId: localStorage.getItem('userId') || '',
  userName: localStorage.getItem('userName') || '',
  accessToken: localStorage.getItem('accessToken') || '',
  qrCode: localStorage.getItem('qrCode') || '',
  expired: localStorage.getItem('expired') || '',
  role: localStorage.getItem('role') || '',
};

export const initialGame = {
  landscapeMaster: [],
  landscapePlayer: [],
  roundGame: '',
  playerSocketItem: '',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case MASTER_LOGIN:
      localStorage.setItem('accessToken', action.payload.admin.access_token);
      localStorage.setItem('expired', action.payload.admin.expires_at);
      localStorage.setItem('role', action.payload.admin.role);
      localStorage.setItem('level', action.payload.admin.gm_role);
      localStorage.setItem('username', action.payload.admin.username);
      return {
        ...state,
        accessToken: action.payload.admin.access_token,
        expired: action.payload.admin.expires_at,
        role: action.payload.admin.role,
        level: action.payload.admin.gm_role,
        username: action.payload.admin.username,
      };
    case GENERATE_QRCODE:
      localStorage.setItem('qrCode', action.payload.qr_code);
      return {
        ...state,
        qrCode: action.payload.qr_code,
      };
    case MASTER_LOGOUT:
      localStorage.clear();
      window.location.reload();
      return {
        ...state,
        accessToken: null,
        qrCode: null,
        role: null,
        level: null,
        username: null
      };
    case MASTER_REFRESH_TOKEN:
      localStorage.setItem('expired', action.payload.expires_at);
      return  {
        ...state,
        expired: action.payload.expires_at
      }
    default:
      return state;
  }
};

export const reducerUser = (state, action) => {
  switch (action.type) {
    case USER_LOGIN:
      localStorage.setItem('userId', action.payload.player.id);
      localStorage.setItem('userName', action.payload.player.username);
      localStorage.setItem('accessToken', action.payload.player.access_token);
      localStorage.setItem('qrCode', action.payload.player.qr_code);
      localStorage.setItem('expired', action.payload.player.expires_at);
      localStorage.setItem('role', action.payload.player.role)
      return {
        ...state,
        userId: action.payload.player.id,
        userName: action.payload.player.username,
        accessToken: action.payload.player.access_token,
        qrCode: action.payload.player.qr_code,
        expired: action.payload.player.expires_at,
        role: action.payload.player.role
      };
    case USER_REFRESH_TOKEN:
      localStorage.setItem('expired', action.payload.expires_at);
      return {
        ...state,
        expired: action.payload.expires_at
      }
    default:
      return state;
  }
};

export const reducerGame = (state, action) => {
  switch (action.type) {
    case GAME_MASTER:
      return {
        ...state,
        landscapeMaster: action.payload.landscape,
        roundCurrent: action.payload.round
      };

    case GAME_MASTER_WAITING:
      return {
        ...state,
        playerSocketItem: action.payload
      };

    case GAME_PLAYER:
      return {
        ...state,
        landscapePlayer: action.payload.landscape,
      };

    default:
      return state;
  }
}

