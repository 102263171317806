import React from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';

InputCompleteField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,

  placeholder: PropTypes.string,
  suggestions: PropTypes.array,
};

function InputCompleteField(props) {
  const {
    control, name, type, placeholder, errors,
    suggestions, handleSuggestion, display, handleOnClick, autocompleteRef,
  } = props;

  return (
    <Controller control={control}
                name={name}
                render={({field: {onChange, onBlur, value}}) => (
                  <div ref={autocompleteRef}>
                    <input type={type}
                           placeholder={placeholder}
                           onChange={onChange}
                           value={value ? value : ''}
                           onClick={handleOnClick}/>

                    {(display && 0 < suggestions.length) && (
                      <ul className={'autocomplete-list'}>
                        {suggestions.map((item, idx) => (
                          <li key={idx}
                              onClick={() => handleSuggestion(item)}
                          >{item.organization_name}</li>
                        ))}
                      </ul>
                    )}

                    <ErrorMessage
                      errors={errors}
                      name={name}
                      render={({message}) =>
                        <p className={'error'}>{message}</p>}
                    />
                  </div>
                )}/>
  );
}

export default InputCompleteField;
