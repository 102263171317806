import {Navigate, Outlet} from 'react-router-dom';

export const PrivateRoutes = ({admin, redirectPath = '/'}) => {
  if (!admin) {
    return <Navigate to={redirectPath} replace/>;
  }

  return <Outlet/>;
};

export const PrivateUserRoutes = ({user, redirectPath = '/welcome'}) => {
  if (!user) {
    return <Navigate to={redirectPath} replace/>;
  }

  return <Outlet/>;
};


