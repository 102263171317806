import React, { useEffect, useState } from 'react';
import { useStore } from '../../../store';
import masterApi from '../../../api/masterApi';
import gameApi from '../../../api/gameApi';
import styles from './ChangePositionOfHarvestor.module.scss';
import PositionHarvesterForm from './components/PositionHarvesterForm';

ChangePositionOfHarvestor.propTypes = {};

function ChangePositionOfHarvestor(props) {
  const [state] = useStore();
  const { qrCode } = state;

  const [landscapeView, setLandscapeView] = useState({});
  const [positionPlayers, setPositionPlayers] = useState([]);

  const [positionHarvest, setPositionHarvest] = useState([]);
  const [currentHarvest, setCurrentHarvest] = useState();

  const [areaSelected, setAreaSelected] = useState(() => new Object());
  const [click, setClick] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await gameApi.getLandscape(qrCode);
      setLandscapeView(response?.landscape);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await masterApi.getPositionPlayers(qrCode);
        setPositionPlayers(response?.position_harvest);

      } catch (e) {
      }
    })();
  }, []);

  const harvesterMax = Math.max(...positionPlayers.map(item => item.harvesters.length));

  const handleEdit = (harvesters, index) => {
    setClick(!click);
    setCurrentHarvest(harvesters);
    harvesters.map(item => {
      if (areaSelected[`${item.position}`]) {
        areaSelected[`${item.position}`] = areaSelected[`${item.position}`] + 1;
      } else {
        areaSelected[`${item.position}`] = 1;
      }
    });

    setAreaSelected(areaSelected);
  };

  const handleCancel = (harvesters) => {
    setClick(!click);
    setCurrentHarvest(harvesters);
    harvesters.map(item => {
      if (areaSelected[`${item.position}`]) {
        areaSelected[`${item.position}`] = areaSelected[`${item.position}`] - 1;
      } else {
        areaSelected[`${item.position}`] = areaSelected[`${item.position}`];
      }
    });

    setAreaSelected(areaSelected);
  };

  const handlePositionHarvesterForm = (value) => {
    handleCancel(currentHarvest);
    setPositionHarvest([
      ...positionHarvest,
      value,
    ]);
    setClick(!click);
  };

  const updatePositionHarvester = async () => {
    try {
      const response = await masterApi.updatePositionOfPlayerHarvester({
        'qr_code': qrCode,
        'position_harvest': positionHarvest,
      });
      setLandscapeView(response.game.landscape);

      const responsePosition = await masterApi.getPositionPlayers(qrCode);
      setPositionPlayers(responsePosition?.position_harvest);

      setPositionHarvest([]);
    } catch (e) {
    }
  };

  useEffect(() => {
    setAreaSelected(areaSelected);
  }, [click]);

  return (
    <main className={'min-vh'}>
      <div className={'container center'}>
        <h1>Position of harvestor</h1>

        <div className={styles.positionContent}>
          <div className={styles.playerList}>
            <div className={styles.inner}>
              {positionPlayers.length > 0 && positionPlayers.map((item, index) => (
                <div key={index}>
                  <PositionHarvesterForm
                    onSubmit={handlePositionHarvesterForm}
                    player={item}
                    edit={handleEdit}
                    cancel={handleCancel}
                    index={index}
                    harvesterMax={harvesterMax} />
                </div>
              ))}
            </div>
          </div>

          <div className={'game-board'}>
            <div className={'game-title-row'}>
              <div>A</div>
              <div>B</div>
              <div>C</div>
              <div>D</div>
              <div>E</div>
            </div>
            <div className={'game-title-col'}>
              <div>1</div>
              <div>2</div>
              <div>3</div>
              <div>4</div>
            </div>
            <div className={'game-list'}>
              {Object.entries(landscapeView)
                .map(([positionKey, positionData], index) => (
                  <div key={index}
                    className={`game-item 
                            ${positionData?.is_protected === 'true' ?
                        'active' : ''}`}
                    style={{ backgroundColor: positionData.color }}>
                    {0 < positionData.animal_qty &&
                      <p className={'animal-icon'}>{
                        [...Array(positionData.animal_qty)].map(
                          (number) => (
                            <span key={number}>&nbsp;</span>))}</p>}
                    {0 < positionData.harvester_qty &&
                      <ul className={'harvesters'}>
                        {areaSelected[positionKey] > 0 &&
                          typeof areaSelected[positionKey] === 'number' ?
                          [...Array(areaSelected[positionKey])].map(
                            (number) => (
                              <li key={number} className={'selected'}>
                                <span>&nbsp;</span></li>
                            )) : ''}
                        {positionData.harvester_qty > 0 ?
                          [
                            ...Array(positionData.harvester_qty -
                              (areaSelected[positionKey] ?
                                areaSelected[positionKey] :
                                0))].map(
                                  (number) => (
                                    <li key={number}><span>&nbsp;</span></li>
                                  )) : ''}
                      </ul>}
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className={styles.positionFooter}>
          <button className={'btn primary auto'}
            onClick={updatePositionHarvester}
            disabled={positionHarvest.length <= 0}>
            Save
          </button>

          <p>Close tab and come back to the mainscreen</p>
        </div>
      </div>
    </main>
  );
}

export default ChangePositionOfHarvestor;
