import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import HeaderParkManager from '../../../components/HeaderParkManager';
import gameApi from '../../../api/gameApi';
import userApi from '../../../api/userApi';
import {useNavigate} from 'react-router-dom';
import {useStoreUser} from '../../../store';
import WaitingForLandscape from '../../../components/WaitingForLandscape';
import {HandleCountDownTimerGM} from "../../../utils/TimerCountDown";

ParkManagerProtectedArea.propTypes = {};

function ParkManagerProtectedArea(props) {
  const [isTimer, setIsTimer] = useState(false);
  const [countDown, setCountDown] = useState(null);

  const refMinutes = useRef();
  const refSeconds = useRef();

  const refProtectedAreaCol = useRef([]);
  const [landscapeView, setLandscapeView] = useState({});

  const navigate = useNavigate();
  const [stateUser] = useStoreUser();

  const {qrCode} = stateUser;
  const [waiting, setWaiting] = useState(true);

  const [timerEnd, setTimerEnd] = useState(null);

  const initialProtectedArea = {
    'qr_code': qrCode,
    'area1': '',
    'area2': '',
    'area3': '',
  };

  const [protectedArea, setProtectedArea] = useState(initialProtectedArea);
  const [clickArea, setClickArea] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const response = await userApi.getCountDownTime(qrCode);
        const countdownTime = response.countdown_for_pm;
        let timeFormat = countdownTime.split(':');
        let convertTime = (+timeFormat[0]) * 60 +
          (+timeFormat[1]);
        setCountDown(convertTime);
        setTimerEnd(response.set_time_for_pm_end_at);
        setIsTimer(true);
      } catch (e) {
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await gameApi.getLandscapePlayer(qrCode);
        setLandscapeView(response?.landscape);
        setWaiting(!waiting);
      } catch (e) {

      }
    })();
  }, []);

  useLayoutEffect(() => {
    setProtectedArea({
      ...protectedArea,
      area1: refArea.current[0] || '',
      area2: refArea.current[1] || '',
      area3: refArea.current[2] || '',
    });
  }, [clickArea]);

  useEffect(() => {
    let displayMinutes = refMinutes.current;
    let displaySeconds = refSeconds.current;

    const dateNow = Math.floor(Date.now() / 1000);
    const timeLeft = timerEnd - dateNow;

    0 < countDown && setTimeout(() => {
      let minutes;
      let seconds;
      minutes = parseInt(countDown / 60, 10);
      seconds = parseInt(countDown % 60, 10);

      minutes = 10 > minutes ? '0' + minutes : minutes;
      seconds = 10 > seconds ? '0' + seconds : seconds;

      if(displayMinutes && displaySeconds) {
        displayMinutes.innerHTML = minutes;
        displaySeconds.innerHTML = seconds;
      }

      if (countDown > timeLeft) {
        setCountDown(timeLeft - 1);
      } else {
        setCountDown(countDown - 1);
      }
    }, 1000);
    if (0 === countDown) {
      if (displaySeconds && displaySeconds) {
        displayMinutes.innerHTML = '00';
        displaySeconds.innerHTML = '00';
      }
      setIsTimer(false);
      submitProtectedArea();
    }
  }, [countDown]);

  useEffect(() => {
    localStorage.setItem('area', JSON.stringify(protectedArea));
  }, [protectedArea]);

  const refArea = useRef([]);
  const handleProtectedArea = (value, index) => {
    setClickArea(clickArea + 1);
    let currentArea = value[0];
    if (!refArea.current.includes(currentArea)) {
      if (refArea.current.length < 3) {
        refArea.current.push(currentArea);
        refProtectedAreaCol.current[index].classList.toggle('active');
      }
    } else {
      refArea.current.filter(item => {
        if (item === currentArea) {
          let indexOfItem = refArea.current.indexOf(item);
          if (indexOfItem !== -1) {
            refArea.current.splice(indexOfItem, 1);
            refProtectedAreaCol.current[index].classList.remove('active');
          }
        }
      });
    }
  };

  const submitProtectedArea = async () => {
    try {
      await gameApi.pmChooseProtectedAreas(protectedArea);
      navigate('/park-manager-view-protected-area', {replace: true});
    } catch (e) {
    }
  };

  const onFocusPM = async () => {
    const timeCountDown = await HandleCountDownTimerGM(qrCode);
    setCountDown(timeCountDown?.time_current);
    setTimerEnd(timeCountDown?.time_end);
  };

  useEffect(() => {
    window.addEventListener("focus", onFocusPM);
    return () => {
      window.removeEventListener("focus", onFocusPM);
    };
  }, []);

  return (
    <div className={'min-vh'}>
      <div className={'container-sm'}>
        <HeaderParkManager title={'Hurry up, choose <br/>protected areas (up to 3)'}/>

        <div className={'game-content'}>
          {waiting && <WaitingForLandscape/>}

          {!waiting &&
          <div className={'game-board small'}>
            <div className={'game-title-row'}>
              <div>A</div>
              <div>B</div>
              <div>C</div>
              <div>D</div>
              <div>E</div>
            </div>
            <div className={'game-title-col'}>
              <div>1</div>
              <div>2</div>
              <div>3</div>
              <div>4</div>
            </div>
            <div className={`game-list`}>
              {Object.entries(landscapeView).map((value, index) => (
                <div key={index}
                     className={'game-item'}
                     style={{backgroundColor: value[1].color}}
                     onClick={() => handleProtectedArea(value, index)}
                     ref={(element) => {
                       refProtectedAreaCol.current[index] = element;
                     }}>
                  {0 < value[1].animal_qty &&
                  <p className={'animal-icon'}>{[
                    ...Array(value[1].animal_qty)].map(
                    (currentValue, i) => (
                      <span key={i}>&nbsp;</span>))}</p>}
                </div>
              ))}
            </div>
          </div>}

          {isTimer &&
          <div className={'time-left'}>
            <h3 className={'center'}>Time Left</h3>
            <div id={'countDown'}
                 className={'count-down'}>
              <span ref={refMinutes}>--</span>
              <span className={'split'}>:</span>
              <span ref={refSeconds}>--</span>
            </div>
            <button type={'button'}
                    className={'btn primary'}
                    onClick={submitProtectedArea}>Finish
            </button>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default ParkManagerProtectedArea;
