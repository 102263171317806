import React from 'react';
import Slider from 'react-slick';
import GameRule1 from './components/GameRule1';
import GameRule2 from './components/GameRule2';
import GameRule3 from './components/GameRule3';
import GameRule4 from './components/GameRule4';
import GameRule5 from './components/GameRule5';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';

GameRule.propTypes = {};

function GameRule(props) {
  let settings = {
    dots: true,
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={'min-vh'}>
      <div className={'container-sm'}>
        <Slider {...settings}>
          <GameRule1/>
          <GameRule2/>
          <GameRule3/>
          <GameRule4/>
          <GameRule5/>
        </Slider>
      </div>
    </div>
  );
}

export default GameRule;
