import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../../../../../components/FormFields/InputField';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Message from '../../../../../components/Message';

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  message: PropTypes.string,
};

function LoginForm({onSubmit, message}) {
  const schema = yup.object({
    username: yup.string().required('Please enter your username'),
    password: yup.string()
                 .required('Please enter your password'),
  });
  const {control, handleSubmit, formState: {errors}, reset} = useForm(
    {
      defaultValues: {
        username: '',
        password: '',
      },
      resolver: yupResolver(schema),
    });

  const onHandleSubmit = async(values) => {
    if (onSubmit) {
      await onSubmit(values);
    }
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}
          className={message ? 'error' : ''}>
      <div className={'form-item'}>
        <InputField type={'text'}
                    placeholder={'Host Username'}
                    name={'username'}
                    label={'Host Username'}
                    autocomplete={'name'}
                    control={control}
                    errors={errors}
        />
      </div>

      <div className={'form-item'}>
        <InputField type={'password'}
                    placeholder={'Password'}
                    name={'password'}
                    label={'Password'}
                    autocomplete={'password'}
                    control={control}
                    errors={errors}
        />

        {message && <Message notification={message} />}
      </div>

      <div className={'form-actions'}>
        <button type={'submit'}>Login</button>
      </div>
    </form>
  );
}

export default LoginForm;
