import React, {useEffect, useState} from 'react';
import HeaderPlayer from '../../../components/HeaderPlayer';
import gameApi from '../../../api/gameApi';
import WaitingForLandscape from '../../../components/WaitingForLandscape';
import userApi from '../../../api/userApi';
import {useStoreGame} from "../../../store";

PlayerPreservationZone.propTypes = {};

function PlayerPreservationZone(props) {
  const [stateGame] = useStoreGame();
  const [landscapeView, setLandscapeView] = useState({});

  const [waiting, setWaiting] = useState(true);
  const [numberHarvester, setNumberHarvester] = useState(0);

  useEffect(() => {
    if (stateGame?.landscapePlayer) {
      setLandscapeView(stateGame?.landscapePlayer);
    }
  }, [stateGame]);

  useEffect(() => {
    (async() => {
      try {
        const qrCode = localStorage.getItem('qrCode');
        const response = await gameApi.getLandscapePlayer(qrCode);
        setLandscapeView(response?.landscape);
        setWaiting(!waiting);
      } catch (e) {}
    })();
  }, []);

  useEffect(() => {
    (async() => {
      try {
        const response = await userApi.getHarvestorOfPlayer();
        setNumberHarvester(response?.number_harvesters);
      } catch (e) {}
    })();
  }, []);

  return (
    <div className={'min-vh'}>
      <div className={'container-sm'}>
        <HeaderPlayer title={'That’s great! <br/>These are the protected areas'}
                      numberHarvester={numberHarvester}/>

        <div className={'game-content'}>
          {waiting && <WaitingForLandscape/>}

          {!waiting &&
           <div className={'game-board small'}>
             <div className={'game-title-row'}>
               <div>A</div>
               <div>B</div>
               <div>C</div>
               <div>D</div>
               <div>E</div>
             </div>
             <div className={'game-title-col'}>
               <div>1</div>
               <div>2</div>
               <div>3</div>
               <div>4</div>
             </div>
             <div className={'game-list'}>
               {Object.entries(landscapeView).map((value, index) => (
                 <div key={index}
                      className={`game-item 
                     ${value[1]?.is_protected === 'true' ? 'active' : ''}`}
                      style={{backgroundColor: value[1].color}}>
                 </div>
               ))}
             </div>
           </div>}
        </div>
      </div>
    </div>
  );
}

export default PlayerPreservationZone;
