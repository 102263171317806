import {
  GAME_MASTER, GAME_PLAYER,
  GENERATE_QRCODE,
  MASTER_LOGIN,
  MASTER_LOGOUT,
  USER_LOGIN,
  USER_LOGOUT,
  USER_REFRESH_TOKEN,
  GAME_MASTER_WAITING,
  MASTER_REFRESH_TOKEN,
} from './constants';

export const masterLogin = payload => ({
  type: MASTER_LOGIN,
  payload,
});

export const masterLogout = payload => ({
  type: MASTER_LOGOUT,
  payload,
});

export const masterRefreshToken = payload => ({
  type: MASTER_REFRESH_TOKEN,
  payload,
})

export const generateQrCode = payload => ({
  type: GENERATE_QRCODE,
  payload,
});

export const userLogin = payload => ({
  type: USER_LOGIN,
  payload,
});

export const userRefreshToken = payload => ({
  type: USER_REFRESH_TOKEN,
  payload,
});

export const userLogout = payload => ({
  type: USER_LOGOUT,
  payload,
});

export const gameMater = payload => ({
  type: GAME_MASTER,
  payload,
});

export const gameMaterWaiting = payload => ({
  type: GAME_MASTER_WAITING,
  payload,
});

export const gamePlayer = payload => ({
  type: GAME_PLAYER,
  payload,
});



