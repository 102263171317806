import axiosClient from "./axiosClient";

const dashboardApi = {
  getDashboard() {
    const url = `/dashboard-overview`;
    return axiosClient.get(url);
  },

  getDashboardOfGame(qrCode, idFilter = 3, idPlayer = -1) {
    const url = `/dashboard-session-overview/${qrCode}/${idFilter}/${idPlayer}`;
    return axiosClient.get(url);
  },

  getDashboardGlobal(qrCode, idFilter) {
    const url = `/dashboard-graph/${qrCode}/${idFilter}`;
    return axiosClient.get(url);
  },
};

export default dashboardApi;
