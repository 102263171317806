import React from 'react';
import styles from './LandscapeContentHeader.module.scss';
import {Link} from 'react-router-dom';

LandscapeContentHeader.propTypes = {};

const data = [
  {id: 1, title: 'Landscape <br/>of round'},
  {id: 2, title: 'Set Time <br/>for Park manager'},
  {id: 3, title: 'Protected area'},
  {id: 4, title: 'Set Time <br/> for Harvesters'},
  {id: 5, title: 'Position <br/>of Harvesters'},
  {id: 6, title: 'Biomass earning <br/>of Harvesters'},
  {id: 7, title: 'Next Turn'},
];

function LandscapeContentHeader({title, active = 1}) {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.inner}>
          <h1 className={styles.pageTitle}>{title}</h1>
          <Link to={'/player-management'}
                target={'_blank'}
                className={styles.linkBox}>Player management</Link>
        </div>
      </header>

      <div className={styles.stepWrap}>
        <div>
          <ul className={styles.stepper}>
            {data.map(item => (
              <li key={item.id}
                  className={`${item.id < active ? styles.__pass : ''} 
                  ${active === item.id ? styles.__current : ''}`}>
                <span dangerouslySetInnerHTML={{__html: item.title}}></span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default LandscapeContentHeader;
