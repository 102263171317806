import React from 'react';
import gameRule2 from '../../../assets/img/game-rule-player2.png';

Information2.propTypes = {};

function Information2(props) {
  return (
    <div className={'center'}>
      <img src={gameRule2}
           alt={'Game rule 2'}
           width={'350'}
           height={'289'}/>

      <h2>The biomass that grows in this<br/>
        landscape is the only source of<br/>
        food for your harvesters.</h2>

      <p>Biomass will increase or decrease based on<br/>
        its own natural dynamics and what happens in<br/>
        the square. The problem is that you don’t know<br/>
        how, since you are new to this landscape. </p>
      <p>Each of your harvesters needs one biomass<br/> per turn to be in good
        health.</p>
    </div>
  );
}

export default Information2;
