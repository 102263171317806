import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';

InputField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,

  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  helperText: PropTypes.string,
};

function InputField(props) {
  const {
    control, name, type, placeholder, autocomplete,
    errors, maxlength, helperText, readOnly
  } = props;

  const [helper, setHelper] = useState(false);
  const handleHelperText = () => {
    setHelper(true);
  };

  const inputRef = useRef();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (inputRef.current &&
          !inputRef.current.contains(event.target)) {
        setHelper(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <Controller control={control}
                name={name}
                render={({field: {onChange, onBlur, value}}) => (
                  <>
                    <input type={type}
                           placeholder={placeholder}
                           onChange={onChange}
                           onBlur={onBlur}
                           value={value ? value : ''}
                           autoComplete={autocomplete}
                           className={''}
                           maxLength={maxlength}
                           ref={inputRef}
                           onClick={handleHelperText}
                           readOnly={readOnly ? readOnly : ''}/>

                    {helper &&
                     <div className={'helper-text'}>{helperText}</div>}

                    <ErrorMessage
                      errors={errors}
                      name={name}
                      render={({message}) =>
                        <p className={'error'}>{message}</p>}/>
                  </>
                )}/>
  );
}

export default InputField;
