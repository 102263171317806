import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import SelectField2 from '../../../../../components/FormFields/SelectField2';
import IconEdit from '../../../../../assets/img/edit-icon.png';
import IconSave from '../../../../../assets/img/save-icon.png';
import IconRemove from '../../../../../assets/img/close-o-icon.png';
import styles from './PositionHarvesterForm.module.scss';

PositionHarvesterForm.propTypes = {
  onSubmit: PropTypes.func,
  player: PropTypes.object,
  editPositionPlayer: PropTypes.func,
};

const options = [
  {value: '', label: '-'},
  {value: 'A1', label: 'A1'},
  {value: 'A2', label: 'A2'},
  {value: 'A3', label: 'A3'},
  {value: 'A4', label: 'A4'},
  {value: 'B1', label: 'B1'},
  {value: 'B2', label: 'B2'},
  {value: 'B3', label: 'B3'},
  {value: 'B4', label: 'B4'},
  {value: 'C1', label: 'C1'},
  {value: 'C2', label: 'C2'},
  {value: 'C3', label: 'C3'},
  {value: 'C4', label: 'C4'},
  {value: 'D1', label: 'D1'},
  {value: 'D2', label: 'D2'},
  {value: 'D3', label: 'D3'},
  {value: 'D4', label: 'D4'},
  {value: 'E1', label: 'E1'},
  {value: 'E2', label: 'E2'},
  {value: 'E3', label: 'E3'},
  {value: 'E4', label: 'E4'},
];

function PositionHarvesterForm({onSubmit, player, edit, cancel, index, harvesterMax}) {
  const schema = yup.object().shape({
    playerId: yup.number(),
    position_harvest: yup.array(),
  });

  const {
    control, handleSubmit,
    formState: {errors}, reset,
  } = useForm(
    {
      defaultValues: {
        playerId: player.player_id,
        position_harvest: player.harvesters,
      },
      resolver: yupResolver(schema),
    });

  const [selectDisable, setSelectDisable] = useState(true);
  const [showActionsButton, setShowActionsButton] = useState(false);

  const handleEditButton = (index) => {
    if (edit) {
      edit(player.harvesters, index);
    }

    setShowActionsButton(true);
    setSelectDisable(false);
  };

  const handleActionCancel = () => {
    setSelectDisable(true);
    setShowActionsButton(!showActionsButton);

    if (cancel) {
      cancel(player.harvesters);
    }

    reset();
  };

  const refSelectPosition = useRef([]);
  const handleSelectPosition = (index) => {
    refSelectPosition.current[index].classList.add('changed');
  };

  const onHandleSubmit = async (values) => {
    const harvesterPosition = [];
    values.position_harvest.map(item => {
      return harvesterPosition.push(item.position);
    });

    const valuesCurrent = {
      'player_id': values.playerId,
      'harvester_position': harvesterPosition,
    };

    if (onSubmit) {
      await onSubmit(valuesCurrent);
      setSelectDisable(true);
      setShowActionsButton(!showActionsButton);
    }
  };

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}
          className={`d-flex ${styles.formPlayer}`}>
      <div className={styles.playerName}>
        <div>{player.player_name}</div>
      </div>

      <div className={styles.playerHarvest}>
        <div className={`${styles.playerPosition} 
      ${harvesterMax === 4 ? styles.has4Cols :
          harvesterMax === 3 ? styles.has3Cols :
            harvesterMax === 2 ? styles.has2Cols : ''}`}>
          {player.harvesters.map((harvester, index) => (
            <div key={index}
                 className={'form-item'}
                 ref={el => refSelectPosition.current[index] = el}
                 onClick={() => handleSelectPosition(index)}>
              <div className={`${styles.positionInput} ${showActionsButton ?
                styles.focus : ''}`}>
                <SelectField2 control={control}
                              placeholder={harvester.position ?
                                harvester.position : '-'}
                              name={`position_harvest[${index}].position`}
                              options={options}
                              errors={errors}
                              defaultValue={harvester}
                              disabled={selectDisable}
                              protectedArea={player.protected_areas}/>
              </div>
            </div>
          ))}
        </div>

        <div className={`d-flex ${styles.playerActions}`}>
          {!showActionsButton && <button type={'button'}>
            <img src={IconEdit}
                 alt={'Icon Edit'}
                 onClick={() => handleEditButton(index)}/></button>}

          {showActionsButton && <>
            <button type={'submit'}>
              <img src={IconSave}
                   alt={'Icon Update'}/></button>

            <button type={'button'} onClick={handleActionCancel}>
              <img src={IconRemove}
                   alt={'Icon Cancel'}/></button>
          </>}
        </div>
      </div>
    </form>
  );
}

export default PositionHarvesterForm;
