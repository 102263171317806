import React, {useEffect, useState} from 'react';
import gameApi from '../../../api/gameApi';
import LoadingSpinner from '../../../components/LoadingSpinner';
import HeaderParkManager from "../../../components/HeaderParkManager";

ParkManagerWaitingFinalDecision.propTypes = {};

function ParkManagerWaitingFinalDecision(props) {
  const [landscapeView, setLandscapeView] = useState({});

  useEffect(() => {
    (async() => {
      try {
        const qrCode = localStorage.getItem('qrCode');
        const response = await gameApi.getLandscapePlayer(qrCode);
        setLandscapeView(response?.landscape);
      } catch (e) {}
    })();
  }, []);

  return (
    <div className={'min-vh'}>
      <div className={'container-sm'}>
        <HeaderParkManager title={'Waiting for the final decision'}/>

        <div className={'game-content'}>
          <div className={'game-board small'}>
            <div className={'game-title-row'}>
              <div>A</div>
              <div>B</div>
              <div>C</div>
              <div>D</div>
              <div>E</div>
            </div>
            <div className={'game-title-col'}>
              <div>1</div>
              <div>2</div>
              <div>3</div>
              <div>4</div>
            </div>
            <div className={'game-list'}>
              {Object.entries(landscapeView).map((value, index) => (
                <div key={index}
                     className={`game-item ${value[1]?.is_protected === 'true' ?
                       'active' :
                       ''}`}
                     style={{backgroundColor: value[1].color}}>
                  {0 < value[1].animal_qty &&
                   <p className={'animal-icon'}>{[
                     ...Array(value[1].animal_qty)].map(
                     (currentValue, i) => (
                       <span key={i}>&nbsp;</span>))}</p>}
                </div>
              ))}
            </div>
          </div>

          <LoadingSpinner/>
        </div>
      </div>
    </div>
  );
}

export default ParkManagerWaitingFinalDecision;
