import styles from "./ComfirmDeleteAccount.module.scss";
import React from "react";
import IconDelete from "../../../../../assets/img/delete-icon.png";

export default function ConfirmDeleteAccount({onDelete, handleCancel, current}) {
  const onHandleCancel = () => {
    handleCancel();
  }

  const onHandleDelete = () => {
    onDelete(current.id)
  }

  return (
    <div>
      <div className={'center'}>
        <img src={IconDelete}
             alt={'Icon Delete'}
             width={96}
             height={96}/></div>
      <h2 className={'center'}>Are you sure?</h2>
      <p className={'center'}>Do you really want to delete this game master
        account <strong>{current.username}</strong> ? <br/>This process cannot be undone.</p>
      <div className={styles.confirm_actions}>
        <button type={'button'}
                className={'btn cancel'}
                onClick={onHandleCancel}>Cancel
        </button>
        <button type={'button'}
                onClick={onHandleDelete}>Delete
        </button>
      </div>
    </div>
  )
}