import React from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

TextareaField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxlength: PropTypes.number,
};

function TextareaField(props) {
  const {control, name, placeholder, maxlength} = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, onBlur, value}}) => (
        <>
          <textarea
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            placeholder={placeholder}
            rows="4"
            cols="50"
            maxLength={maxlength}>{value}</textarea>
        </>
      )}
    />
  );
}

export default TextareaField;
