import React, { useEffect, useState } from 'react';
import LandscapeContentHeader
  from '../../../components/LandscapeLayout/LandscapeContentHeader';
import {Link, useNavigate} from 'react-router-dom';
import styles from '../Landscape/Landscape.module.scss';
import LogoGray from '../../../assets/img/logo-gray.png';
import LineIcon from '../../../assets/img/line-icon.png';
import gameApi from '../../../api/gameApi';
import { useStoreGame } from '../../../store';
import WaitingForLandscape from '../../../components/WaitingForLandscape';
import masterApi from "../../../api/masterApi";

ProtectedArea.propTypes = {};

function ProtectedArea(props) {
  const [stateGame] = useStoreGame();
  const { roundGame } = stateGame;

  const [landscapeView, setLandscapeView] = useState({});
  const [round, setRound] = useState(roundGame);

  const [showView, setShowView] = useState(false);
  const [waiting, setWaiting] = useState(true);

  const qrCode = localStorage.getItem('qrCode');
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const response = await gameApi.getLandscape(qrCode);
        setLandscapeView(response?.landscape);
        setRound(response?.round);
        setWaiting(!waiting);
      } catch (e) { }
    })();
  }, []);

  useEffect(() => {
    if (stateGame?.landscapeMaster) {
      setLandscapeView(stateGame?.landscapeMaster);
    }
  }, [stateGame])

  const handleShowView = () => {
    setShowView(!showView);
  };

  const redirectPage = async () => {
    try {
      await masterApi.redirectPage({
        'qr_code': qrCode,
        'next_page': 'set-timer-for-harvester'
      });
      navigate('/timer-harvester');
    } catch (e) {
    }
  };

  return (
    <>
      <main className={'container'}>
        <LandscapeContentHeader title={`Round ${round} - Protected area`} active={3} />

        <div className='game-content-master'>
          {waiting && <WaitingForLandscape />}

          {!waiting && <>
            <div className={'game-board'}>
              <div className={'game-title-row'}>
                <div>A</div>
                <div>B</div>
                <div>C</div>
                <div>D</div>
                <div>E</div>
              </div>
              <div className={'game-title-col'}>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
              </div>
              <div className={'game-view'}>
                {!showView &&
                  <div className={'game-list'}>
                    {Object.entries(landscapeView)
                      .map(([positionKey, positionData], index) => (
                        <div key={index}
                          className={`game-item ${positionData?.is_protected ===
                            'true' ?
                            'active' :
                            ''}`}
                          style={{ backgroundColor: positionData.color }}>
                        </div>
                      ))}
                  </div>}

                {showView &&
                  <div className={'game-list'}>
                    {Object.entries(landscapeView)
                      .map(([positionKey, positionData], index) => (
                        <div key={index}
                          className={`game-item ${positionData?.is_protected ===
                            'true' ?
                            'active' :
                            ''}`}
                          style={{ backgroundColor: positionData.color }}>
                          {0 < positionData.animal_qty &&
                            <p className={'animal-icon'}>{[
                              ...Array(positionData.animal_qty)].map(
                                (currentValue, i) => (
                                  <span key={i}>&nbsp;</span>))}</p>}
                        </div>
                      ))}
                  </div>}
              </div>
            </div>
          </>}
        </div>

        <div className={'center'}>
          <button className={'btn btn-arrow'}
                  onClick={redirectPage}><span>Next</span></button>
        </div>


      </main>

      <footer className={styles.footer}>
        <div className={'container'}>
          <div className={styles.wrapper}>
            <div className={'d-flex'}>
              <img src={LogoGray}
                alt={'Planet C Logo Gray'}
                className={styles.logoFt} />
              Park manager

              <div onClick={handleShowView} className={'switch-toggle'}>
                <input type={'checkbox'} name={'switch'} value={'1'} />
                <label htmlFor={'switch'}>Switch</label>
              </div>
            </div>

            <p>
              <Link to={'/landscape-view'}
                target={'_blank'}
                className={styles.linkView}>
                <span>Game master view</span>
                <img src={LineIcon} alt={'Line Icon'} />
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default ProtectedArea;
