import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import TextareaField from '../../../../../components/FormFields/TextareaField';
import SelectField from '../../../../../components/FormFields/SelectField';
import RadioField from '../../../../../components/FormFields/RadioField';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import InputCompleteField
  from '../../../../../components/FormFields/InputCompleteField';
import masterApi from '../../../../../api/masterApi';
import Message from '../../../../../components/Message';

OrganizationForm.propTypes = {
  onSubmit: PropTypes.func,
};

const gameTypeList = [
  {id: 1, value: 'public'},
  {id: 2, value: 'professional'},
];

const animalList = [
  {id: 1, value: 'bird'},
  {id: 2, value: 'deer'},
  {id: 3, value: 'elephant'},
];

const options = [
  {value: '0', label: 'Type of business'},
  {value: '1', label: 'Education'},
  {value: '2', label: 'Private company'},
  {value: '3', label: 'General public'},
  {value: '4', label: 'Officials'},
  {value: '5', label: 'Others'},
];

function OrganizationForm({onSubmit, message}) {
  const schema = yup.object({
    organization_name: yup.string()
                          .required('Please enter the organization name'),
    type_of_business: yup.string().required('Please choose the business type'),
    game_type: yup.number().required(),
    description: yup.string().max(200, 'Maximum 200 characters'),
    animal: yup.number().required(),
  });

  const {control, handleSubmit,
    formState: {errors, isDirty},
    reset, setValue, getValues, watch} = useForm(
    {
      defaultValues: {
        organization_name: '',
        type_of_business: '',
        game_type: 1,
        description: '',
        animal: 1,
      },
      resolver: yupResolver(schema),
    });

  const [organizations, setOrganizations] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [display, setDisplay] = useState(false);

  const [selected, setSelected] = useState();
  const [selectDisable, setSelectDisable] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await masterApi.getOrganization();
      setOrganizations(response);
    })()
  },[])

  const autocompleteRef = useRef();
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (autocompleteRef.current &&
          !autocompleteRef.current.contains(event.target)) {
        setDisplay(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const watchOrganization = watch('organization_name');
  useEffect(() => {
    let matches = [];
    if (0 < watchOrganization?.length) {
      matches = organizations.filter(item => {
        const regex = new RegExp(`${watchOrganization}`, 'gi');
        return item.organization_name.match(regex);
      });
    }
    setSuggestions(matches);
    const found = organizations.find(el => el.organization_name === watchOrganization);
    if (found === undefined) {
      setSelectDisable(false);
    }
  }, [watchOrganization, organizations]);

  const watchTypeOfBusiness = watch('type_of_business');
  useEffect(() => {
    const currentType = getValues('type_of_business');
    setSelected(currentType);
  },[watchTypeOfBusiness, getValues])

  const handleOnClick = () => {
    setDisplay(true);
  };

  const handleSuggestion = (value) => {
    const businessTypeId = value.business_type.id;
    setValue('organization_name', value.organization_name);
    setSelected(businessTypeId);

    if (businessTypeId) {
      setSelectDisable(true);
      setValue('type_of_business', businessTypeId);
    } else {
      setSelectDisable(false);
    }

    setSuggestions([]);
    setDisplay(false);
  };

  const [gameType, setGameType] = useState(1);
  const [animal, setAnimal] = useState(1);

  const handleGameType = (id) => {
    setGameType(id);
    setValue('game_type', id);
  };

  const handleAnimal = (id) => {
    setAnimal(id);
    setValue('animal', id);
  };

  const onHandleSubmit = async(values) => {
    if (onSubmit) {
      await onSubmit(values);
    }
    setSelectDisable(false);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}>

      <div className={'form-item'}>
        <InputCompleteField
          type={'text'}
          placeholder={'Organization'}
          name={'organization_name'}
          label={'Organization'}
          control={control}
          errors={errors}
          suggestions={suggestions ? suggestions : []}
          handleSuggestion={handleSuggestion}
          display={display}
          setDisplay={setDisplay}
          handleOnClick={handleOnClick}
          autocompleteRef={autocompleteRef}/>
      </div>

      <div className={'form-item form-select'}>
        <SelectField control={control}
                     placeholder={'Type of business'}
                     name={'type_of_business'}
                     errors={errors}
                     options={options}
                     selected={selected}
                     disabled={selectDisable}/>
      </div>

      <div className={'form-item d-flex'}>
        <RadioField
          control={control}
          name={'game_type'}
          radioList={gameTypeList}
          handleRadioField={handleGameType}
          checked={gameType}/>
      </div>

      <div className={'form-item'}>
        <TextareaField
          name={'description'}
          placeholder={'Description (Maximum 200 characters)'}
          control={control}
          maxlength={200}/>
      </div>

      <div className={'form-item'}>
        <div className={'d-flex'}>
          <RadioField control={control}
                      name={'animal'}
                      radioList={animalList}
                      checked={animal}
                      handleRadioField={handleAnimal}
                      disabled={'disabled'}/>
        </div>

        {message && <Message notification={message}/>}
      </div>

      <div>
        <button type={'submit'} disabled={!isDirty}>Get QR code</button>
      </div>
    </form>
  );
}

export default OrganizationForm;
