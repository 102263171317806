import React, {useEffect, useRef, useState} from 'react';
import CopyIcon from '../../../assets/img/copy-orange-icon.png';
import styles from './PlayerManagement.module.scss';
import masterApi from '../../../api/masterApi';

PlayerManagement.propTypes = {};

function PlayerManagement(props) {
  const [usersInGame, setUserInGame] = useState([]);
  const inputsRef = useRef([]);

  useEffect(() => {
    (async() => {
      try {
        const qrCode = localStorage.getItem('qrCode');
        const response = await masterApi.getPlayersInfo(qrCode);
        setUserInGame(response.players);
      } catch (e) {}
    })();
  }, []);

  const getLinkReJoin = (rejoinKey, idx) => {
    const currentUser = inputsRef.current[idx];
    const copyText = currentUser.querySelector('span');

    const currentInput = currentUser.querySelector('input');
    const currentInputValue = currentInput.value;
    currentInput.select();
    currentInput.setSelectionRange(0, 99999);

    if (navigator.clipboard) {
      navigator.clipboard.writeText(currentInputValue);
    } else {
      document.execCommand('copy');
    }

    copyText.style.opacity = 1;
    copyText.style.visibility = 'visible';

    setTimeout(() => {
      copyText.style.opacity = 0;
      copyText.style.visibility = 'hidden';
    }, 2000);
  };

  return (
    <div className={'min-vh'}>
      <div className={'container center'}>
        <h1>Player management</h1>

        <div className={styles.managementList}>
          {0 === usersInGame.length &&
           <p className={styles.notFound}> No user in the game</p>}

          {usersInGame && usersInGame.map((item, idx) => (
            <div key={idx}
                 ref={(element) => {
                   inputsRef.current[idx] = element;
                 }}>
              <div className={styles.username}>{item.name}</div>
              <input className={styles.linkLogin}
                     type={'text'}
                     value={`${window.location.origin}/re-join/${item.rejoin_key}`}
                     readOnly/>
              <div className={styles.copyIcon}
                   onClick={() => getLinkReJoin(item.rejoin_key, idx)}>
                <img src={CopyIcon} alt={'Copy Icon'}/>

                <span className={styles.copiedText}>Copied</span>
              </div>
            </div>
          ))}
        </div>

        <p className={'fw-medium'}>Close tab and come back to the main
          screen</p>
      </div>
    </div>
  );
}

export default PlayerManagement;
