import React from 'react';
import styles from './PlayerAddHarvest.module.scss';
import PropTypes from 'prop-types';

PlayerAddHarvest.propTypes = {
  harvesters: PropTypes.array,
  areaCurrent: PropTypes.string,
  handleHarvesters: PropTypes.func,
};

function PlayerAddHarvest({harvesters, areaCurrent, handleHarvestersClick}) {

  return (
    <div className={`${styles.box}`}>
      <div>
        <h4 className={styles.title}>Who should come to {areaCurrent}?</h4>

        <ul className={styles.harvestList}>
          {harvesters.map((item, index) => (
            <li key={index}
                className={`${item.position === areaCurrent ?
                  styles.active : item.position === '' ?
                    styles.white : styles.gray}`}
                onClick={() => handleHarvestersClick(item.harvester_id, index)}>
              H{index + 1}
              <span className={styles.position}>{item.position}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PlayerAddHarvest;
