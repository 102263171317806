import React, { useState } from 'react';
import LoginForm from './components/LoginForm';
import Text from '../../../components/Text';
import background from '../../../assets/img/login-bg.jpg';
import Logo from '../../../components/Logo';
import styles from './Login.module.scss';
import { actions, useStore } from '../../../store';
import { Navigate } from 'react-router-dom';
import masterApi from '../../../api/masterApi';
import { Helmet } from 'react-helmet';

Login.propTypes = {};

function Login({ admin }) {
  const [, dispatch] = useStore();
  const [message, setMessage] = useState('');
  const handleSubmit = async (values) => {
    try {
      const response = await masterApi.loginMaster(values);
      dispatch(actions.masterLogin(response));
      setMessage('');

    } catch (e) {
      setMessage(e.response?.data);
    }
  };

  if (admin) {
    return <Navigate to={'/create-game'} replace />;
  }

  return (
    <div className={'has-bg'} style={{ backgroundImage: `url(${background})` }}>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="A game about the ecological transition." name="description" />
      </Helmet>
      <div className={'container'}>
        <div className={'box'}>
          <Text />

          <div>
            <div className={styles.loginMaster}>
              <div className={styles.logoSmall}><Logo /></div>

              <LoginForm onSubmit={handleSubmit} message={message} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
