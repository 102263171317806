import React, {useState} from 'react';
import Logo from '../../../components/Logo';
import background from '../../../assets/img/login-bg.jpg';
import OrganizationForm from './components/OrganizationForm';
import styles from './Organization.module.scss';
import {actions, useStore} from '../../../store';
import {useNavigate} from 'react-router-dom';
import masterApi from '../../../api/masterApi';

Organization.propTypes = {};

function Organization(props) {
  const [, dispatch] = useStore();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');

  const handleSubmit = async(values) => {
    try {
      const response = await masterApi.createGame(values);
      dispatch(actions.generateQrCode(response));
      navigate('/start-game');

    } catch (e) {
      setMessage(e.response?.data.message);
    }
  };
  return (
    <div className={'min-vh has-bg'}
         style={{backgroundImage: `url(${background})`}}>
      <div className={'container'}>
        <div className={`${styles.organizationBox} bg-white border-radius`}>
          <div className={`${styles.logoSmall} center`}><Logo/></div>

          <h2 className={'center'}>Describe the organization</h2>

          <OrganizationForm onSubmit={handleSubmit} message={message}/>
        </div>
      </div>
    </div>
  );
}

export default Organization;
