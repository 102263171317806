import React, { useEffect } from 'react';
import background from '../../../assets/img/login-bg.jpg';
import Logo from '../../../components/Logo';
import { actions, useStoreUser } from '../../../store';
import styles from './Welcome.module.scss';

Welcome.propTypes = {};

function Welcome(props) {
  const [, dispatchUser] = useStoreUser();
  const dateNow = Math.floor(Date.now() / 1000);

  useEffect(() => {
    const expired = localStorage.getItem('expired');
    if (0 > parseInt(expired) - dateNow) {
      dispatchUser(actions.userLogout(null));
    }
  }, []);

  return (
    <div className={'has-bg'} style={{ backgroundImage: `url(${background})` }}>
      <div className={'container'}>
        <div className={styles.welcomeContent}>
          <Logo />

          <p><strong>Welcome to Planet C.<br />
            Please contact the organizer to join this game.</strong></p>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
