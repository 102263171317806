import styles from './WaitingForLandscape.module.scss'

WaitingForLandscape.propTypes = {};

function WaitingForLandscape(props) {
    return (
        <div className={styles.waitingLandscape}>
            <div className={styles.ldsEllipsis}>
                <div></div><div></div><div></div><div></div>
            </div>
            <p>Waiting for Landscape</p>
        </div>
    )
}

export default WaitingForLandscape;