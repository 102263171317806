import React, {useEffect, useState} from 'react';
import {useStore, useStoreGame} from '../../../store';
import gameApi from '../../../api/gameApi';
import WaitingForLandscape from '../../../components/WaitingForLandscape';

LandscapeView.propTypes = {};

function LandscapeView(props) {
  const [state] = useStore();
  const {qrCode} = state;

  const [stateGame] = useStoreGame();
  const {landscapeMaster} = stateGame;

  const [landscapeView, setLandscapeView] = useState({});
  const [round, setRound] = useState(1);

  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    (async() => {
      try {
        const response = await gameApi.getLandscape(qrCode);
        setLandscapeView(response?.landscape);
        setRound(response?.round);
        setWaiting(!waiting);
      } catch (e) {

      }
    })();
  }, []);

  useEffect(() => {
    setLandscapeView(landscapeMaster);
  }, [landscapeMaster])

  return (
    <div className={'min-vh'}>
      <div className={'container center'}>
        <h1>Round {round}</h1>
        <div className='game-content-master'>
          {waiting && <WaitingForLandscape/>}

          {!waiting &&
           <div className={'game-board'}>
             <div className={'game-title-row'}>
               <div>A</div>
               <div>B</div>
               <div>C</div>
               <div>D</div>
               <div>E</div>
             </div>
             <div className={'game-title-col'}>
               <div>1</div>
               <div>2</div>
               <div>3</div>
               <div>4</div>
             </div>
             <div className={'game-list'}>
               {Object.entries(landscapeView)
                      .map(([positionKey, positionData], index) => (
                        <div key={index}
                             className={`game-item 
                          ${positionData?.is_protected === 'true' ?
                               'active' : ''}`}
                             style={{backgroundColor: positionData.color}}>
                          {0 < positionData.animal_qty &&
                           <p className={'animal-icon'}>{[
                             ...Array(positionData.animal_qty)].map(
                             (currentValue, i) => (
                               <span key={i}>&nbsp;</span>))}</p>}

                          {0 < positionData?.animal_new_born &&
                           <p className={'animal-icon new-born'}>{[
                             ...Array(positionData?.animal_new_born)].map(
                             (currentValue, i) => (
                               <span key={i}>&nbsp;</span>))}</p>}

                          {0 < positionData.harvester_qty &&
                           <ul className={'harvesters'}>{[
                             ...Array(positionData.harvester_qty)].map(
                             (currentValue, i) => (
                               <li key={i}><span>&nbsp;</span></li>))}</ul>}
                        </div>
                      ))}
             </div>
           </div>}
        </div>
        <p className={'fw-500'}>Close tab and come back to the main screen </p>
      </div>
    </div>
  );
}

export default LandscapeView;
