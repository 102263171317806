import React from 'react';
import gameRule1 from '../../../../../assets/img/game-rule1.png';
import biomass from '../../../../../assets/img/game-rule12.png';

GameRule1.propTypes = {};

function GameRule1(props) {
  return (
    <div className={'center'}>
      <img src={gameRule1}
           alt={'Game Rule 1'}
           width={'350'}
           height={'289'}/>

      <img src={biomass}
           alt={'Biomass'}
           width={'237'}
           height={'40'}/>

      <h2>You have been hired by the<br/>
        Office for Environment as a Park Manager.</h2>

      <p>The landscape you will manage is made of 20<br/>
        squares identified by a letter and a number. <br/>Each square contains
        biomass, the colour<br/> tells you how much.</p>
    </div>
  );
}

export default GameRule1;
