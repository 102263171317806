import {Context, ContextUser, ContextGame} from './Context';
import {useReducer} from 'react';
import {initialState, initialUserState, initialGame,
  reducer, reducerUser, reducerGame} from './reducer';
import logger from './logger';

function Provider({children}) {
  const [state, dispatch] = useReducer(logger(reducer), initialState);
  const [stateUser, dispatchUser] = useReducer(logger(reducerUser),
    initialUserState);
  const [stateGame, dispatchGame] = useReducer(logger(reducerGame), initialGame);

  return (
    <ContextGame.Provider value={[stateGame, dispatchGame]}>
      <Context.Provider value={[state, dispatch]}>
        <ContextUser.Provider value={[stateUser, dispatchUser]}>
          {children}
        </ContextUser.Provider>
      </Context.Provider>
    </ContextGame.Provider>
  );
}

export default Provider;
