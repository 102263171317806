import React, {useState} from 'react';
import styles from './FormBiomassEarning.module.scss';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {useFieldArray, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import InputFieldNumber
  from '../../../../../components/FormFields/InputFieldNumber';

FormBiomassEarning.propTypes = {
  onSubmit: PropTypes.func,
  harvesterMax: PropTypes.number,
  resultOfPM: PropTypes.object,
  message: PropTypes.string,
};

function FormBiomassEarning({biomassEarning, onSubmit, harvesterMax, resultOfPM, message}) {
  const player = yup.object({
    player_name: yup.string(),
    harvesters: yup.array()
                   .of(
                     yup.object().shape({
                       harvester_id: yup.number(),
                       qty_harvest: yup.number(),
                     }),
                   ),
    total: yup.number(),
  });

  const schema = yup.object({
    players: yup
      .array()
      .of(player),
  });

  const {control, handleSubmit, formState: {errors}, reset}
    = useForm({
    defaultValues: {players: biomassEarning},
    resolver: yupResolver(schema),
  });

  const {fields, update} = useFieldArray({
    control,
    name: 'players',
  });

  const onHandleSubmit = async(values) => {
    const currentValues = values.players.map(player => {
      return {player_name: player.player_name, harvesters: player.harvesters};
    });

    if (onSubmit) {
      await onSubmit(currentValues);
      setIsShow(!isShow);
    }
  };

  const [isShow, setIsShow] = useState(false);

  const handleActions = () => {
    setIsShow(!isShow);
  };

  return (
    <form onSubmit={handleSubmit(onHandleSubmit)}
          className={`${styles.form} 
            ${harvesterMax === 4 ? styles.has4Cols :
            harvesterMax === 3 ? styles.has3Cols :
              harvesterMax === 2 ? styles.has2Cols : ''}`}>
      <div className={styles.formWrapper} name={'players'}>
        <div className={styles.formHeading}>
          <div className={styles.headingName}>&nbsp;</div>
          <div className={`${styles.headingHarvester}`}>
            {harvesterMax > 0 && [...Array(harvesterMax)].map((item, index) => (
              <div key={index}>H{index + 1}</div>
            ))}
          </div>
          <div className={styles.headingTotal}>Total</div>
        </div>

        {fields.map((player, index) => (
          <div className={styles.player} key={player.id}>
            <div className={styles.name}>{player.player_name}</div>
            <div className={styles.harvesters}>
              {player.harvesters.map((harvester, idx) => (
                <div key={harvester.harvester_id} className={styles.harvester}>
                  <InputFieldNumber
                    type={'number'}
                    placeholder={'0'}
                    control={control}
                    name={`players.${index}.harvesters[${idx}].qty_harvest`}
                    errors={errors ? errors : ''}
                    readOnly/>
                </div>
              ))}
            </div>
            <div className={styles.total}>
              {isShow && <button type='button' onClick={() => {
                let newArr = [...player.harvesters];
                let max = newArr.reduce(
                  (prev, current) => (prev.qty_harvest > current.qty_harvest) ?
                    prev :
                    current);

                update(index, {
                  ...player,
                  total: player.total > 0 ? player.total - 1 : 0,
                  harvesters: player.harvesters.map(
                    harvester => harvester.harvester_id === max.harvester_id ?
                      {
                        ...harvester,
                        qty_harvest: harvester.qty_harvest > 0 ?
                          harvester.qty_harvest - 1 :
                          0,
                      } : harvester),
                });
              }}>-</button>}

              <InputFieldNumber
                type={'number'}
                placeholder={'0'}
                control={control}
                name={`players.${index}.total`}
                errors={errors}
                readOnly/>

              {isShow && <button type='button' onClick={() => {
                let newArr = [...player.harvesters];
                let min = newArr.reduce(
                  (prev, current) => (prev.qty_harvest > current.qty_harvest) ?
                    current :
                    prev);

                update(index, {
                  ...player,
                  total: player.total + 1,
                  harvesters: player.harvesters.map(
                    harvester => harvester.harvester_id === min.harvester_id ?
                      {...harvester, qty_harvest: harvester.qty_harvest + 1} :
                      harvester),
                });
              }}>+</button>}
            </div>
          </div>
        ))}
      </div>

      <div className={styles.resultOfPM}>
        <h4>Park manager Result</h4>
        <div className={styles.inner}>
          <p>{resultOfPM.pm_name}</p>
          <p><span>Adults : </span> {resultOfPM.total_adult}</p>
          <p><span>Newborns : </span> {resultOfPM.total_babies}</p>
        </div>
      </div>

      {message && <p className='error center'>{message}</p>}

      <div className={styles.actions}>
        {!isShow &&
         <button type={'button'} onClick={handleActions}>Edit</button>}

        {isShow && <button type={'submit'}>Save</button>}

        {isShow &&
         <button type={'button'}
                 className='secondary'
                 onClick={() => reset({
                   players: biomassEarning,
                 })}>Cancel</button>}
      </div>
    </form>
  );
}

export default FormBiomassEarning;
