import {useContext} from 'react';
import {Context, ContextUser, ContextGame} from './Context';

export const useStore = () => {
  const [state, dispatch] = useContext(Context);

  return [state, dispatch];
};

export const useStoreUser = () => {
  const [stateUser, dispatchUser] = useContext(ContextUser);

  return  [stateUser, dispatchUser];
}

export const useStoreGame = () => {
  const [stateGame, dispatchGame] = useContext(ContextGame);

  return  [stateGame, dispatchGame];
}
