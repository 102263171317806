import React, { useEffect, useState } from 'react';
import { useStoreGame } from '../../../store';
import gameApi from '../../../api/gameApi';
import HeaderPlayer from '../../../components/HeaderPlayer';
import WaitingForLandscape from '../../../components/WaitingForLandscape';
import userApi from '../../../api/userApi';

PlayerHarvestResult.propTypes = {};

function PlayerHarvestResult(props) {
  const [stateGame] = useStoreGame();
  const qrCode = localStorage.getItem('qrCode');

  const [landscapeView, setLandscapeView] = useState({});
  const [round, setRound] = useState();

  const [total, setTotal] = useState();
  const [stock, setStock] = useState();

  const [harvesters, setHarvesters] = useState({});
  const [waiting, setWaiting] = useState(true);

  const [numberHarvester, setNumberHarvester] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const response = await gameApi.getLandscapePlayer(qrCode);
        setLandscapeView(response?.landscape);
        setWaiting(!waiting);
      } catch (e) {
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await gameApi.getBiomassEarningOfHarvester(qrCode);
        setRound(response?.round);
        setTotal(response?.biomass_earning.total_harvest);

        setStock(response?.biomass_earning.stock);
        setHarvesters(response?.biomass_earning.harvesters);
      } catch (e) {
      }
    })();
  }, [stateGame.landscapePlayer]);

  useEffect(() => {
    (async () => {
      try {
        const response = await userApi.getHarvestorOfPlayer();
        setNumberHarvester(response?.number_harvesters);
      } catch (e) {
      }
    })();
  }, []);

  return (
    <div className={'min-vh'}>
      <div className={'container-sm'}>
        <HeaderPlayer title={'End of the Harvest Season'}
          numberHarvester={numberHarvester} />

        <div className={'game-content'}>
          {waiting && <WaitingForLandscape />}

          {!waiting &&
            <div className={'game-board small'}>
              <div className={'game-title-row'}>
                <div>A</div>
                <div>B</div>
                <div>C</div>
                <div>D</div>
                <div>E</div>
              </div>
              <div className={'game-title-col'}>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
              </div>
              <div className={'game-list'}>
                {Object.entries(landscapeView)
                  .map(([positionKey, positionData], index) => (
                    <div key={index}
                      className={`game-item 
                     ${positionData?.is_protected === 'true' ? 'active' : ''}`}
                      style={{ backgroundColor: positionData.color }}>
                      {0 < positionData.harvester_qty &&
                        <ul className={'harvesters'}>
                          <>
                            {positionData.harvester_of_player > 0 &&
                              [...Array(positionData.harvester_of_player)]
                                .map((currentValue, i) => (
                                  <li key={i} className='selected'>
                                    <span>&nbsp;</span></li>))}
                            {[
                              ...Array(positionData.harvester_qty -
                                positionData.harvester_of_player)]
                              .map((currentValue, i) => (
                                <li key={i}><span>&nbsp;</span></li>))}
                          </>
                        </ul>}
                    </div>
                  ))}
              </div>
            </div>}

          <div className={'harvest-result'}>
            <h2>Round {round}</h2>

            <ul>
              <li>Total Harvest : {total}</li>
              <li>Your stock : {stock}</li>
            </ul>

            {Object.entries(harvesters)
              .map(([harvesterKey, harvesterData], index) => (
                <p key={index}>{harvesterKey} : {harvesterData.qty_harvest}</p>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerHarvestResult;
