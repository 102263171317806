import React from 'react';
import icon1 from '../../assets/img/icon1.svg';
import icon2 from '../../assets/img/icon2.svg';
import icon3 from '../../assets/img/icon3.svg';
import icon4 from '../../assets/img/icon4.svg';
import styles from './Text.module.scss'

Text.propTypes = {};

function Text(props) {
  return (
    <div>
      <ul className={styles.textList}>
        <li>
          <img src={icon1} alt={'Icon 1'}/>
          Landscapes don’t happen, we shape them.
        </li>

        <li>
          <img src={icon2} alt={'Icon 2'}/>
          Your assumptions tie your hands down.
          Question yourself. What do you know and
          what do you assume ?
        </li>

        <li>
          <img src={icon3} alt={'Icon 3'}/>
          Managing nature is managing people
          about nature. How well do you understand
          the others ?
        </li>

        <li>
          <img src={icon4} alt={'Icon 4'}/>
          Communication is necessary, but not
          sufficient. Do you know how to listen ?
        </li>
      </ul>
    </div>
  );
}

export default Text;
