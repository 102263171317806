import gameApi from '../api/gameApi';

export const RedirectPage = async (qrCode, navigate, userId) => {
  try {
    const response = await gameApi.getGameInfo(qrCode);
    const pmId = response?.pm_id;
    const currentPage = response?.current_page;
    const playerId = parseInt(userId);
    const status = response?.status;

    if ('Stopped' === status) {
      localStorage.clear();
      navigate('/welcome');
    }

    if (playerId === pmId) {
      switch (currentPage) {
        case 'landscape-of-round':
          navigate('/park-manager-landscape');
          break;
        case 'set-timer-for-pm':
          navigate('/park-manager-protected-area');
          break;
        case 'protected-area':
          navigate('/park-manager-view-protected-area');
          break;
        case 'set-timer-for-harvester':
          navigate('/park-manager-waiting-harvesters');
          break;
        case 'position-of-harvester':
          navigate('/park-manager-waiting-decision');
          break;
        case 'map-of-harvester':
          navigate('/park-manager-view-map-of-harvester');
          break;
        case 'biomass-earning-of-harvester':
          navigate('/park-manager-view-decision');
          break;
        default:
          break;
      }
    } else {
      switch (currentPage) {
        case 'landscape-of-round':
          navigate('/player-landscape');
          break;
        case 'set-timer-for-pm':
          navigate('/player-view-protected-area');
          break;
        case 'protected-area':
          navigate('/player-preservation-zone');
          break;
        case 'set-timer-for-harvester':
          navigate(`/player-harvest-process/${userId}`);
          break;
        case 'position-of-harvester':
          navigate(`/player-waiting-decision/${userId}`);
          break;
        case 'map-of-harvester':
          navigate(`/player-view-map-of-harvester/${userId}`);
          break;
        case 'biomass-earning-of-harvester':
          navigate(`/player-harvest-result/${userId}`);
          break;
        default:
          break;
      }
    }
  } catch (e) { }
};

export const RedirectPageReload = async (qrCode, navigate, userId) => {
  try {
    const response = await gameApi.getGameInfo(qrCode);
    const pmId = response?.pm_id;
    const currentPage = response?.current_page;
    const playerId = parseInt(userId);

    const round = response?.round;
    const session = response?.session;
    const status = response?.status;

    if ('Stopped' === status) {
      localStorage.clear();
      navigate('/welcome');
    }

    if (playerId === pmId) {
      switch (currentPage) {
        case 'landscape-of-round':
          (1 === round && 1 === session) ?
            navigate('/game-rule-park-manager') : navigate('/park-manager-landscape');
          break;
        case 'set-timer-for-pm':
          navigate('/park-manager-protected-area');
          break;
        case 'protected-area':
          navigate('/park-manager-view-protected-area');
          break;
        case 'set-timer-for-harvester':
          navigate('/park-manager-waiting-harvesters');
          break;
        case 'position-of-harvester':
          navigate('/park-manager-waiting-decision');
          break;
        case 'map-of-harvester':
          navigate('/park-manager-view-map-of-harvester');
          break;
        case 'biomass-earning-of-harvester':
          navigate('/park-manager-view-decision');
          break;
        default:
          break;
      }
    } else {
      switch (currentPage) {
        case 'landscape-of-round':
          (1 === round && 1 === session) ?
            navigate('/game-rule-player') : navigate('/player-landscape');
          break;
        case 'set-timer-for-pm':
          navigate('/player-view-protected-area');
          break;
        case 'protected-area':
          navigate('/player-preservation-zone');
          break;
        case 'set-timer-for-harvester':
          navigate(`/player-harvest-process/${userId}`);
          break;
        case 'position-of-harvester':
          navigate(`/player-waiting-decision/${userId}`);
          break;
        case 'map-of-harvester':
          navigate(`/player-view-map-of-harvester/${userId}`);
          break;
        case 'biomass-earning-of-harvester':
          navigate(`/player-harvest-result/${userId}`);
          break;
        default:
          break;
      }
    }
  } catch (e) { }
};

