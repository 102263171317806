import React from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';

RadioField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string,
  radioList: PropTypes.array,
};

function RadioField(props) {
  const {control, name, radioList, handleRadioField, checked, disabled} = props;
  return (
    <Controller control={control}
                name={name}
                render={({field: {onBlur}}) => (
                  <>{radioList.map((radio, idx) => (
                    <div key={idx}>
                      <input type={'radio'}
                             value={radio.id}
                             name={radio.value}
                             id={radio.value}
                             onChange={() => handleRadioField(radio.id)}
                             onBlur={onBlur}
                             checked={checked === radio.id}
                             disabled={disabled?.length}/>
                      <label htmlFor={radio.value}><span className={radio.value}>{radio.value}</span></label>
                    </div>
                  ))}</>
                )}
    />
  );
}

export default RadioField;
