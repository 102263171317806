export const MASTER_LOGIN = 'master_login';
export const MASTER_LOGOUT = 'master_logout';
export const MASTER_REFRESH_TOKEN = 'master_refresh_token';

export const GENERATE_QRCODE = 'generate_qrCode';
export const USER_LOGIN = 'user_login';
export const USER_LOGOUT = 'user_logout';
export const USER_REFRESH_TOKEN = 'user_refresh_token';

export const GAME_MASTER = 'game_master';
export const GAME_MASTER_WAITING = 'game_master_waiting';
export const GAME_PLAYER = 'game_player';


