import React from 'react';
import gameRule3 from '../../../../../assets/img/game-rule-player3.png';

GameRule3.propTypes = {};

function GameRule3(props) {
  return (
    <div className={'center'}>
      <img src={gameRule3} alt={'Game Rule 3'} width={'350'} height={'289'}/>

      <h2>Each of your harvesters needs<br/>
        one biomass per turn to be in<br/>
        good health.</h2>

      <p>Each round, you have to indicate squares for<br/>
        your harvesters to go and collect biomass.<br/>
        Other clan leaders will give instructions to<br/>
        their own harvesters.</p>

      <p>One harvester can only go to one square but<br/>
        several harvesters can go to the same square.</p>

      <p>One harvester will harvest up to 2 biomass<br/>
        from its square if it is available,<br/>
        before returning home.</p>
    </div>
  );
}

export default GameRule3;
