import React from 'react';
import logo from '../../logo.svg';

Logo.propTypes = {};

function Logo(props) {
  return (
    <div className={'logo'}>
      <img src={logo} alt="logo" width={86} height={103}/>
    </div>
  );
}

export default Logo;
