import React from 'react';
import gameRule4 from '../../../../../assets/img/waiting-pic.jpg';
import {Link} from 'react-router-dom';

GameRule4.propTypes = {};

function GameRule4(props) {
  return (
    <div className={'center'}>
      <img src={gameRule4} alt={'Game rule 4'} width={'350'} height={'289'}/>

      <h2>Your success depends on<br/>
        how well fed and secure your<br/> harvesters are.<br/>
        Good luck ! The well being of your clan depends on you.</h2>

      <br/>

      <Link to={'/player-landscape'} className={'btn primary'}>Let's go</Link>
    </div>
  );
}

export default GameRule4;
