import React from 'react';
import gameRule4 from '../../../../../assets/img/game-rule4.png';

GameRule4.propTypes = {};

function GameRule4(props) {
  return (
    <div className={'center'}>
      <img src={gameRule4} alt={'Game rule 4'} width={'350'} height={'289'}/>

      <h2>Protect reproduction <br/> and reduce human pressure</h2>
      <p>From round 2 onwards, you can designate<br/>
        Protected Areas in up to 3 squares, <br/>
        restricting the access for harvesters.</p>
    </div>
  );
}

export default GameRule4;
