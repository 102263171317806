import axiosClient from "./axiosClient";

const masterApi = {
  loginMaster(data) {
    const url = "/game-master-login";
    return axiosClient.post(url, data);
  },

  logoutMaster(qrCode) {
    const url = "/game-master-logout";
    return axiosClient.post(url, qrCode);
  },

  createAccountMaster(data) {
    const url = "/create-gm-account";
    return axiosClient.post(url, data);
  },

  deleteAccountMaster(id) {
    const url = `/disable-gm-account/${id}`;
    return axiosClient.delete(url);
  },

  updateAccountMaster(id, data) {
    const url = `/edit-gm-account/${id}`;
    return axiosClient.put(url, data);
  },

  getListGameMasterAccount() {
    const url = "/list-gm-account";
    return axiosClient.get(url);
  },

  gmRefreshToken() {
    const url = "/gm-refresh-token";
    return axiosClient.post(url);
  },

  getOrganization() {
    const url = "/organization";
    return axiosClient.get(url);
  },

  createGame(data) {
    const url = "/create-game";
    return axiosClient.post(url, data);
  },

  startGame(data) {
    const url = "/start-game";
    return axiosClient.post(url, data);
  },

  playersOnline(qrCode) {
    const url = `/players-online/${qrCode}`;
    return axiosClient.get(url);
  },

  getPlayersInfo(qrCode) {
    const url = `/players-info/${qrCode}`;
    return axiosClient.get(url);
  },

  setTimeForParkManager(data) {
    const url = "/set-time-for-park-manager";
    return axiosClient.post(url, data);
  },

  setTimeForHarvestor(data) {
    const url = "/set-time-for-harvester";
    return axiosClient.post(url, data);
  },

  getPositionPlayers(qrCode) {
    const url = `/get-position-of-player-harvester/${qrCode}`;
    return axiosClient.get(url);
  },

  updatePositionOfPlayerHarvester(data) {
    const url = "/update-position-of-player-harvester";
    return axiosClient.put(url, data);
  },

  transferBiomass(data) {
    const url = "/transfer-biomass";
    return axiosClient.put(url, data);
  },

  redirectPage(data) {
    const url = "/redirect-page";
    return axiosClient.post(url, data);
  },

  getListPlayers(qrCode) {
    const url = `/list-players/${qrCode}`;
    return axiosClient.get(url);
  },
};

export default masterApi;
