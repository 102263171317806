import React, { useState } from 'react';
import Logo from '../../../components/Logo';
import styles from './JoinGame.module.scss';
import JoinGameForm from './components/JoinGameForm';
import { Navigate, useParams } from 'react-router-dom';
import userApi from '../../../api/userApi';
import { actions, useStoreUser } from '../../../store';

JoinGame.propTypes = {};

function JoinGame({ user }) {
  const [, dispatchUser] = useStoreUser();
  const { qrCode } = useParams();
  const [message, setMessage] = useState('');

  const handleSubmit = async (values) => {
    try {
      const response = await userApi.playerLogin(values);
      dispatchUser(actions.userLogin(response));
    } catch (e) {
      setMessage(e.response?.data.message || e.response?.data);
    }
  };

  if (user) {
    return <Navigate to={'/player-waiting'} replace />;
  }

  return (
    <div className={styles.main}>
      <div className={'container'}>
        <div className={styles.logo}>
          <Logo />
        </div>

        <div className={styles.description}>
          <h2>Welcome. Shall we play a game?</h2>

          <p>Planet C is a game of discovery. You just arrived in a new
            environment.<br />
            You'll have to learn fast, you are not alone and time is ticking.
            Have fun!</p>
        </div>

        <JoinGameForm qrCode={qrCode} onSubmit={handleSubmit} message={message} />
      </div>
    </div>
  );
}

export default JoinGame;
