import React, {useEffect, useRef, useState} from 'react';
import masterApi from "../../../api/masterApi";
import styles from './MasterAccountList.module.scss';
import Logo from "../../../components/Logo";
import {Link} from "react-router-dom";
import avt from "../../../assets/img/avt.png";
import {useStore} from "../../../store";
import IconEdit from '../../../assets/img/edit-icon.png';
import IconDelete from '../../../assets/img/delete-icon.png';
import UpdateAccountForm from "./components/UpdateAccountForm";
import ConfirmDeleteAccount from "./components/ConfirmDeleteAccount";

function MasterAccountList(props) {
  const [state] = useStore();
  const [accountList, setAccountList] = useState([]);

  const [showDropDown, setShowDropDown] = useState(false);
  const dropDownRef = useRef(null);

  const [isPopup, setIsPopup] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const [notification, setNotification] = useState({});
  const [defaultForm, setDefaultForm] = useState({
    id: '',
    username: ''
  });

  const refModal = useRef(null);

  const handleShowDropDown = () => {
    setShowDropDown((showDropDown) => !showDropDown);
  };

  const logoutMaster = async () => {
    try {
      const qrCode = localStorage?.getItem('qrCode');
      await masterApi.logoutMaster({
        "qr_code": qrCode ? qrCode : "0"
      });
      localStorage.clear();
      window.location.reload();
    } catch (e) {
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const results = await masterApi.getListGameMasterAccount();
        setAccountList(results);
      } catch (e) {

      }
    })()
  }, []);

  const handleSubmitUpdate = async (values) => {
    try {
      const currentId = defaultForm.id;
      const response = await masterApi.updateAccountMaster(currentId, values);
      setAccountList(response);
      setNotification({
        type: 'success',
        message: 'Account information has been changed successfully.'
      });

      setTimeout(() => {
        setNotification({});
        setIsPopup(false);
      }, 1000)
    } catch (e) {
      console.log(e);
    }
  }

  const onHandleClosePopup = () => {
    setIsPopup(false);
  }

  const handleUpdate = (account) => {
    setDefaultForm({
      id: account?.id,
      username: account.username
    });
    setIsPopup(true);
  }

  const handleCancelConfirm = () => {
    setIsConfirm(false);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (refModal.current && !refModal.current.contains(event.target)) {
        setIsPopup(false);
        setIsConfirm(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refModal])

  const handleDeleteAccount = async (id) => {
    try {
      const newAccountList = accountList.filter(item => item.id !== id);
      setAccountList(newAccountList);
      await masterApi.deleteAccountMaster(id);
      setIsConfirm(!isConfirm)
    } catch (e) {
      console.log(e);
    }
  }

  const handleDelete = (account) => {
    setDefaultForm({
      id: account.id,
      username: account.username
    })
    setIsConfirm(!isConfirm);
  }

  return (
    <>
      <div className={styles.headerDashboard}>
        <div className={styles.headerDashboardInr}>
          <div className={styles.hdrLeft}>
            <div className="logo">
              <Logo/>
            </div>
          </div>
          <div className={styles.hdrRight}>
            <div className={styles.hdrItem}>
              <Link to={"/"} className={styles.backHome}>
                Homepage
              </Link>
            </div>
            <div className={styles.hdrItem}>
              <Link to={"/dashboard-global"} className={styles.btnGlobal}>
                Global
              </Link>
            </div>
            <div className={styles.hdrItem}>
              <div
                className={styles.user}
                ref={dropDownRef}
                onClick={handleShowDropDown}
              >
                <div className={styles.avt}>
                  <img src={avt} alt="avt-user"/>
                </div>
                <div className={styles.extraUser}>
                  <div className={styles.txtUser}>
                    {localStorage.getItem("username")}
                  </div>
                  <div className={`${styles.txtUser} ${styles.colorGray}`}>
                    Game master
                  </div>
                </div>
              </div>

              {showDropDown && (
                <div className={styles.userPopup}>
                  <ul>
                    {'Superadmin' === state.level && (
                      <>
                        <li><Link to={"/master-account"}>Account List</Link></li>
                        <li><Link to={"/create-account"}>Create Account</Link></li>
                      </>
                    )}
                    <li onClick={logoutMaster}>Sign Out</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={'container'}>
        <div className={styles.masterAccountList}>
          <h1 className={'center'}>Master Account List</h1>
          <div className={styles.accountList}>
            <div>
              <div>Username</div>
              <div>Role</div>
              <div>&nbsp;</div>
            </div>
            {accountList.map(account => (
              <div key={account?.id}>
                <div>{account?.username}</div>
                <div>{'Superadmin' === account?.gm_role ? 'Super admin' : 'Admin'}</div>
                <div className={styles.actions}>
                  {'Admin' === account?.gm_role ? (
                    <>
                      <button type={'submit'}
                              className={'btn action'}
                              onClick={() => handleUpdate(account)}>
                        <img src={IconEdit}
                             alt={'Icon Edit'}
                             width={24}
                             height={24}/>
                      </button>

                      <button type={'submit'}
                              className={'btn action'}
                              onClick={() => handleDelete(account)}>
                        <img src={IconDelete}
                             alt={'Icon Delete'}
                             width={24}
                             height={24}/>
                      </button>
                    </>
                  ) : (
                    <>&nbsp;</>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {isPopup && <div className={styles.modal}>
        <div ref={refModal}>
          <UpdateAccountForm
            onSubmit={handleSubmitUpdate}
            defaultForm={defaultForm}
            handleClosePopup={onHandleClosePopup}
            notification={notification}
          />
        </div>
      </div>}

      {isConfirm && <div className={styles.modal}>
        <div ref={refModal}>
          <ConfirmDeleteAccount
            onDelete={handleDeleteAccount}
            handleCancel={handleCancelConfirm}
            current={defaultForm}/>
        </div>
      </div>}
    </>
  );
}

export default MasterAccountList;