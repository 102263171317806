import React, { useState, useEffect, useRef, useMemo } from "react";
import styles from "./DashboardOfGame.module.scss";
import Logo from "../../../components/Logo";
import { Link } from "react-router-dom";
import avt from "../../../assets/img/avt.png";
import iconPlayer from "../../../assets/img/icon-db-player.svg";
import iconHarvest from "../../../assets/img/icon-db-harvest.svg";
import iconSessions from "../../../assets/img/icon-db-sessions.svg";
import iconBiomass from "../../../assets/img/icon-db-biomass.svg";
import iconTimer from "../../../assets/img/icon-db-timer.svg";
import iconNewBorn from "../../../assets/img/icon-db-newborn.svg";
import dashboardApi from "../../../api/dashboardApi";
import masterApi from "../../../api/masterApi";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {useStore} from "../../../store";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      onClick: null,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        boxWidth: 6,
        boxHeight: 4,
        padding: 32,
        font: {
          size: 14,
          family: "'Poppins', sans-serif",
          weight: "500",
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        stepSize: 10,
        font: {
          family: "'Poppins', sans-serif ",
          weight: "400",
        },
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: true,
      },
      ticks: {
        stepSize: 10,
        font: {
          family: "'Poppins', sans-serif",
          weight: "400",
        },
      },
    },
  },
};

function DashboardOfGame() {
  const dataFilter = [
    { id: 1, name: "Session 1", checked: true },
    { id: 2, name: "Session 2", checked: false },
    { id: 3, name: "Global Overview", checked: false },
  ];

  const dataRadioBox = [
    { id: 1, name: "Cumulative", value: 1, checked: true },
    { id: 2, name: "Instant", value: 2, checked: false },
  ];

  const [state] = useStore();

  const [showDropDown, setShowDropDown] = useState(false);
  const dropDownRef = useRef(null);
  const dropDownFilterRef = useRef(null);
  const dropDownFilterPlayerRef = useRef(null);
  const boxRef = useRef(null);
  const boxFilterPlayRef = useRef(null);
  const [dashboardTotal, setDashboardTotal] = useState(null);
  const [showStaticsOneTable, setShowStaticsOneTable] = useState(true);
  const [showStaticsTwoTable, setShowStaticsTwoTable] = useState(false);
  const [filterUser, setFilterUser] = useState(true);

  const [showFilter, setShowFilter] = useState(false);
  const [showFilterUser, setShowFilterUser] = useState(false);
  const [checkBoxes, setCheckedBoxes] = useState(dataFilter);

  const [playerList, setPlayerList] = useState([]);
  const [currentSession, setCurrentSession] = useState("1");
  const [arrayIdUser, setArrayIdUser] = useState([]);

  const [radioBox, setRadioBox] = useState(dataRadioBox);
  const [radioValue, setRadioValue] = useState(1);
  const [showRadioBox, setShowRadioBox] = useState(true);

  const handleShowDropDown = () => {
    setShowDropDown((showDropDown) => !showDropDown);
  };

  const handleShowFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleShowFilterUser = () => {
    setShowFilterUser((showFilterUser) => !showFilterUser);
  };

  const logoutMaster = async () => {
    try {
      const qrCode = localStorage?.getItem('qrCode');
      await masterApi.logoutMaster({
        "qr_code": qrCode ? qrCode : "0"
      });
      localStorage.clear();
      window.location.reload();
    } catch (e) { }
  }

  useEffect(() => {
    if (dashboardTotal?.current_session === 1) {
      const arrayAfterRemove = [...dataFilter].filter((item) => {
        return item.id !== 2;
      });
      setCheckedBoxes(arrayAfterRemove);
    } else if (dashboardTotal?.current_session === 2) {
      setCheckedBoxes([...dataFilter]);
    }
  }, [dashboardTotal?.current_session]);

  const handleOnChange = (event) => {
    let checkBoxIndex = checkBoxes.findIndex(
      (box) => box.name === event.target.name
    );

    let copyOfCheckBoxes = [...checkBoxes];
    let isChecked = copyOfCheckBoxes[checkBoxIndex].checked;
    let amountOfCheckedBoxes = checkBoxes.filter((box) => box.checked);

    if (!isChecked && amountOfCheckedBoxes.length >= 2) {
      return checkBoxes;
    }

    if (isChecked) {
      copyOfCheckBoxes[checkBoxIndex] = {
        ...copyOfCheckBoxes[checkBoxIndex],
        checked: false,
      };
    } else {
      copyOfCheckBoxes[checkBoxIndex] = {
        ...copyOfCheckBoxes[checkBoxIndex],
        checked: true,
      };
    }
    setCheckedBoxes(copyOfCheckBoxes);
    checkboxUser.filter((item) => {
      if (item.checked === true) {
        item.checked = false;
      }
    });
  };

  useEffect(() => {
    function handleClickOutDropDown(e) {
      if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
        setShowDropDown(false);
      }
    }
    document.addEventListener("click", handleClickOutDropDown);
    return () => {
      document.removeEventListener("click", handleClickOutDropDown);
    };
  }, []);

  useEffect(() => {
    function handleClickOutDropDownFilterPlayer(e) {
      if (
        dropDownFilterPlayerRef.current &&
        !dropDownFilterPlayerRef.current.contains(e.target) &&
        boxFilterPlayRef.current &&
        !boxFilterPlayRef.current.contains(e.target)
      ) {
        setShowFilterUser(false);
      }
    }
    document.addEventListener("click", handleClickOutDropDownFilterPlayer);
    return () => {
      document.removeEventListener("click", handleClickOutDropDownFilterPlayer);
    };
  }, []);

  useEffect(() => {
    function handleClickOutDropDownFilter(e) {
      if (
        dropDownFilterRef.current &&
        !dropDownFilterRef.current.contains(e.target) &&
        boxRef.current &&
        !boxRef.current.contains(e.target)
      ) {
        setShowFilter(false);
      }
    }
    document.addEventListener("click", handleClickOutDropDownFilter);
    return () => {
      document.removeEventListener("click", handleClickOutDropDownFilter);
    };
  }, []);

  useEffect(() => {
    const checkFilter = checkBoxes.filter((item) => item.checked === true);
    const arrayId = checkFilter.map((item) => item.id);

    if (checkFilter.length === 1) {
      setShowStaticsOneTable(true);
      setShowStaticsTwoTable(false);
    } else if (checkFilter.length > 1) {
      setShowStaticsOneTable(false);
      setShowStaticsTwoTable(true);
    }

    if (arrayId.includes(1) && arrayId.includes(2)) {
      setFilterUser(true);
      handleApiGetDashboard("1,2");
      setCurrentSession("1,2");
      setArrayIdUser([]);
      setShowRadioBox(true);
    } else if (arrayId.includes(1) && arrayId.includes(3)) {
      setFilterUser(false);
      handleApiGetDashboard("1, 3");
      setCurrentSession("1,3");
      setArrayIdUser([]);
      setShowRadioBox(true);
    } else if (arrayId.includes(2) && arrayId.includes(3)) {
      setFilterUser(false);
      handleApiGetDashboard("2, 3");
      setCurrentSession("2,3");
      setArrayIdUser([]);
      setShowRadioBox(true);
    } else if (arrayId.includes(1)) {
      setFilterUser(true);
      handleApiGetDashboard("1");
      setArrayIdUser([]);
      setCurrentSession("1");
      setShowRadioBox(true);
    } else if (arrayId.includes(2)) {
      setFilterUser(true);
      handleApiGetDashboard("2");
      setArrayIdUser([]);
      setCurrentSession("2");
      setShowRadioBox(true);
    } else if (arrayId.includes(3)) {
      setFilterUser(false);
      handleApiGetDashboard("3");
      setArrayIdUser([]);
      setShowRadioBox(false);
    } else {
      setFilterUser(false);
    }
  }, [checkBoxes]);

  useEffect(() => {
    (async () => {
      try {
        const response = await masterApi.getListPlayers(
          localStorage.getItem("qrCode")
          // "LEAF-H48Y6e9Te31gX3iJpPI4-20220913090758"
          // "planetB-ejs5bvY2OdTQugrGUcbR-20220911180558"
        );
        setPlayerList(response?.players);
        setCheckBoxUser(response?.players);
      } catch (e) {
      }
    })();
  }, []);

  const handleApiGetDashboard = (idFilter, idPlayer) => {
    (async () => {
      try {
        const response = await dashboardApi.getDashboardOfGame(
          localStorage.getItem("qrCode"),
          // "LEAF-H48Y6e9Te31gX3iJpPI4-20220913090758",
          // "planetB-ejs5bvY2OdTQugrGUcbR-20220911180558",
          idFilter,
          idPlayer
        );
        setDashboardTotal(response);
      } catch (e) {
      }
    })();
  };

  const [checkboxUser, setCheckBoxUser] = useState(playerList);
  const handleOnChangeFilterUser = (event) => {
    let checkBoxIndex = checkboxUser.findIndex(
      (box) => box.name === event.target.name
    );

    let copyOfCheckBoxesUser = [...checkboxUser];
    let isChecked = copyOfCheckBoxesUser[checkBoxIndex].checked;
    let amountOfCheckedBoxes = checkboxUser.filter((box) => box.checked);

    if (!isChecked && amountOfCheckedBoxes.length >= 22) {
      return checkboxUser;
    }
    if (isChecked) {
      copyOfCheckBoxesUser[checkBoxIndex] = {
        ...copyOfCheckBoxesUser[checkBoxIndex],
        checked: false,
      };
    } else {
      copyOfCheckBoxesUser[checkBoxIndex] = {
        ...copyOfCheckBoxesUser[checkBoxIndex],
        checked: true,
      };
    }
    setCheckBoxUser(copyOfCheckBoxesUser);
  };

  useEffect(() => {
    const checkFilterUser = checkboxUser.filter(
      (item) => item.checked === true
    );
    const arrayNewIdUser = checkFilterUser.map((item) => item.player_id);
    setArrayIdUser(arrayNewIdUser);

    if (arrayNewIdUser.length === 0) {
      handleApiGetDashboard(currentSession, -1);
    } else {
      handleApiGetDashboard(currentSession, arrayNewIdUser.toString());
    }
  }, [checkboxUser]);

  const handleRadioClick = (e) => {
    const valueRadio = e.target.value;
    setRadioValue(parseInt(valueRadio));
  };

  const chartData = useMemo(() => {
    const arrayPlayerSs1 = dashboardTotal?.dashboard[0]?.harvesters?.players;
    const arrayPlayerSs2 = dashboardTotal?.dashboard[1]?.harvesters?.players;
    const result = [].concat(arrayPlayerSs1, arrayPlayerSs2);

    return {
      labels:
        dashboardTotal?.dashboard[0]?.harvesters.labels.length >
          dashboardTotal?.dashboard[1]?.harvesters.labels.length
          ? dashboardTotal?.dashboard[0]?.harvesters.labels
          : dashboardTotal?.dashboard[1]?.harvesters.labels,
      datasets: result.map((item) => {
        let obj = {};
        obj = {
          label: item?.name,
          data:
            radioValue === 1
              ? item?.number
              : item?.number_instant
                ? item?.number_instant
                : item?.number,
          borderColor: item?.color,
          backgroundColor: item?.color,
        };
        return obj;
      }),
    };
  }, [dashboardTotal?.dashboard, radioValue]);

  return (
    <>
      <div className={styles.headerDashboard}>
        <div className={styles.headerDashboardInr}>
          <div className={styles.hdrLeft}>
            <div className="logo">
              <Logo />
            </div>
          </div>
          <div className={styles.hdrRight}>
            <div className={styles.hdrItem}>
              <Link to={"/play-again"} className={"btn primary"}>
                Move on
              </Link>
            </div>
            <div className={styles.hdrItem}>
              <div
                className={styles.user}
                ref={dropDownRef}
                onClick={handleShowDropDown}
              >
                <div className={styles.avt}>
                  <img src={avt} alt="avt-user" />
                </div>
                <div className={styles.extraUser}>
                  <div className={styles.txtUser}>
                    {localStorage.getItem("username")}
                  </div>
                  <div className={`${styles.txtUser} ${styles.colorGray}`}>
                    Game master
                  </div>
                </div>
              </div>

              {showDropDown && (
                <div className={styles.userPopup}>
                  <ul>
                    {'Superadmin' === state.level && (
                      <>
                        <li><Link to={"/master-account"}>Account List</Link></li>
                        <li><Link to={"/create-account"}>Create Account</Link></li>
                      </>
                    )}
                    <li onClick={logoutMaster}>Sign Out</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.sectionInr}>
          <div className={styles.title}>
            <div className={`${styles.selectBox} ${styles.headerTitle}`}>
              <div
                onClick={handleShowFilter}
                ref={dropDownFilterRef}
                className={styles.selectHeader}
              >
                <span>Session</span>
              </div>
              {showFilter && (
                <div ref={boxRef} className={styles.selectCnt}>
                  <div className={styles.selectItems}>
                    {checkBoxes.map((box, index) => (
                      <div key={index} className={styles.itemSl}>
                        <div className="checkbox">
                          <input
                            id={`chkFilter-${box.id}`}
                            type="checkbox"
                            name={box.name}
                            onChange={handleOnChange}
                            checked={box.checked}
                          />
                          <label htmlFor={`chkFilter-${box.id}`}>
                            {box.name}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={styles.selectHint}>Up to 2 values</div>
                </div>
              )}
            </div>
          </div>

          <div className={`${styles.flexBox} ${styles.col7}`}>
            <div className={styles.flexItem}>
              <div className={styles.subTitle}>Statistic</div>
              <div className={`${styles.cntDashboard} ${styles.flexColumn}`}>
                {showStaticsOneTable && (
                  <div className={styles.statisticItems}>
                    <div className={styles.item}>
                      <div className={styles.itemIcon}>
                        <img src={iconPlayer} alt="Player" />
                      </div>
                      <div className={styles.itemTxt}>
                        {dashboardTotal && (
                          <>
                            {dashboardTotal.dashboard[0] && (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].total_players}
                              </p>
                            )}
                          </>
                        )}
                        <p className={styles.brief}>players</p>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.itemIcon}>
                        <img src={iconHarvest} alt="harvest" />
                      </div>
                      <div className={styles.itemTxt}>
                        {dashboardTotal && (
                          <>
                            {dashboardTotal.dashboard[0].id === 3 ? (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].avg_harvest}
                              </p>
                            ) : (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].total_harvesters}
                              </p>
                            )}
                          </>
                        )}
                        {dashboardTotal && (
                          <>
                            {dashboardTotal.dashboard[0].id === 3 ? (
                              <p className={styles.brief}>avg harvest</p>
                            ) : (
                              <p className={styles.brief}>harvest</p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.itemIcon}>
                        <img src={iconSessions} alt="sessions" />
                      </div>
                      <div className={styles.itemTxt}>
                        {dashboardTotal && (
                          <>
                            {dashboardTotal.dashboard[0].id === 3 ? (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].total_sessions}
                              </p>
                            ) : (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].total_rounds}
                              </p>
                            )}
                          </>
                        )}
                        {dashboardTotal && (
                          <>
                            {dashboardTotal.dashboard[0].id === 3 ? (
                              <p className={styles.brief}>sessions</p>
                            ) : (
                              <p className={styles.brief}>rounds</p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.itemIcon}>
                        <img src={iconBiomass} alt="biomass" />
                      </div>
                      <div className={styles.itemTxt}>
                        {dashboardTotal && (
                          <>
                            {dashboardTotal.dashboard[0].id === 3 ? (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].avg_biomass}
                              </p>
                            ) : (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].total_biomass}
                              </p>
                            )}
                          </>
                        )}
                        {dashboardTotal && (
                          <>
                            {dashboardTotal.dashboard[0].id === 3 ? (
                              <p className={styles.brief}>avg final biomass</p>
                            ) : (
                              <p className={styles.brief}>final biomass</p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.itemIcon}>
                        <img src={iconTimer} alt="hours" />
                      </div>
                      <div className={styles.itemTxt}>
                        {dashboardTotal && (
                          <p className={styles.number}>
                            {dashboardTotal.dashboard[0].hours}
                          </p>
                        )}
                        <p className={styles.brief}>hours</p>
                      </div>
                    </div>
                    <div className={styles.item}>
                      <div className={styles.itemIcon}>
                        <img src={iconNewBorn} alt="newborns" />
                      </div>
                      <div className={styles.itemTxt}>
                        {dashboardTotal && (
                          <>
                            {dashboardTotal.dashboard[0].id === 3 ? (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].avg_newborns}
                              </p>
                            ) : (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].total_newborn}
                              </p>
                            )}
                          </>
                        )}
                        {dashboardTotal && (
                          <>
                            {dashboardTotal.dashboard[0].id === 3 ? (
                              <p className={styles.brief}>avg newborns</p>
                            ) : (
                              <p className={styles.brief}>newborns</p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {showStaticsTwoTable && (
                  <div className={`${styles.statisticItems} ${styles.hasLine}`}>
                    <div className={styles.statisticItemsLeft}>
                      {dashboardTotal && (
                        <div className={styles.smallTitle}>
                          {dashboardTotal.dashboard[0].name}
                        </div>
                      )}
                      <div className={styles.listItems}>
                        <div className={styles.item}>
                          <div className={styles.itemIcon}>
                            <img src={iconHarvest} alt="harvest" />
                          </div>
                          <div className={styles.itemTxt}>
                            {dashboardTotal && (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].total_harvesters}
                              </p>
                            )}
                            <p className={styles.brief}>harvest</p>
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.itemIcon}>
                            <img src={iconBiomass} alt="biomass" />
                          </div>
                          <div className={styles.itemTxt}>
                            {dashboardTotal && (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].total_biomass}
                              </p>
                            )}
                            <p className={styles.brief}>final biomass</p>
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.itemIcon}>
                            <img src={iconNewBorn} alt="newborns" />
                          </div>
                          <div className={styles.itemTxt}>
                            {dashboardTotal && (
                              <p className={styles.number}>
                                {dashboardTotal.dashboard[0].total_newborn}
                              </p>
                            )}
                            <p className={styles.brief}>newborns</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.statisticItemsRight}>
                      {dashboardTotal && (
                        <>
                          {dashboardTotal.dashboard[1] && (
                            <div className={styles.smallTitle}>
                              {dashboardTotal.dashboard[1].name}
                            </div>
                          )}
                        </>
                      )}
                      <div className={styles.listItems}>
                        <div className={styles.item}>
                          <div className={styles.itemIcon}>
                            <img src={iconHarvest} alt="harvest" />
                          </div>
                          <div className={styles.itemTxt}>
                            {dashboardTotal && (
                              <>
                                {dashboardTotal.dashboard[1] && (
                                  <>
                                    {dashboardTotal.dashboard[1].id === 3 ? (
                                      <p className={styles.number}>
                                        {
                                          dashboardTotal.dashboard[1]
                                            .avg_harvest
                                        }
                                      </p>
                                    ) : (
                                      <p className={styles.number}>
                                        {
                                          dashboardTotal.dashboard[1]
                                            .total_harvesters
                                        }
                                      </p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {dashboardTotal && (
                              <>
                                {dashboardTotal.dashboard[1] && (
                                  <>
                                    {dashboardTotal.dashboard[1].id === 3 ? (
                                      <p className={styles.brief}>
                                        avg harvest
                                      </p>
                                    ) : (
                                      <p className={styles.brief}>harvest</p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.itemIcon}>
                            <img src={iconBiomass} alt="biomass" />
                          </div>
                          <div className={styles.itemTxt}>
                            {dashboardTotal && (
                              <>
                                {dashboardTotal.dashboard[1] && (
                                  <>
                                    {dashboardTotal.dashboard[1].id === 3 ? (
                                      <p className={styles.number}>
                                        {
                                          dashboardTotal.dashboard[1]
                                            .avg_biomass
                                        }
                                      </p>
                                    ) : (
                                      <p className={styles.number}>
                                        {
                                          dashboardTotal.dashboard[1]
                                            .total_biomass
                                        }
                                      </p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {dashboardTotal && (
                              <>
                                {dashboardTotal.dashboard[1] && (
                                  <>
                                    {dashboardTotal.dashboard[1].id === 3 ? (
                                      <p className={styles.brief}>
                                        avg final biomass
                                      </p>
                                    ) : (
                                      <p className={styles.brief}>
                                        final biomass
                                      </p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.itemIcon}>
                            <img src={iconNewBorn} alt="newborns" />
                          </div>
                          <div className={styles.itemTxt}>
                            {dashboardTotal && (
                              <>
                                {dashboardTotal.dashboard[1] && (
                                  <>
                                    {dashboardTotal.dashboard[1].id === 3 ? (
                                      <p className={styles.number}>
                                        {
                                          dashboardTotal.dashboard[1]
                                            .avg_newborns
                                        }
                                      </p>
                                    ) : (
                                      <p className={styles.number}>
                                        {
                                          dashboardTotal.dashboard[1]
                                            .total_newborn
                                        }
                                      </p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            {dashboardTotal && (
                              <>
                                {dashboardTotal.dashboard[1] && (
                                  <>
                                    {dashboardTotal.dashboard[1].id === 3 ? (
                                      <p className={styles.brief}>
                                        avg newborns
                                      </p>
                                    ) : (
                                      <p className={styles.brief}>newborns</p>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.flexItem}>
              <div className={styles.subTitle}>Harvest</div>
              <div className={`${styles.cntDashboard} ${styles.chart}`}>
                {showRadioBox && (
                  <div className={styles.radioBox}>
                    <div className={styles.flexRadio}>
                      {radioBox.map((item, index) => (
                        <div key={index} className={styles.itemSl}>
                          <div className="checkbox has_radio">
                            <input
                              type="radio"
                              id={`chkFilter-${item.id}`}
                              name="check-radio"
                              defaultChecked={item.checked}
                              value={item.id}
                              onChange={handleRadioClick}
                            />
                            <label htmlFor={`chkFilter-${item.id}`}>
                              {item.name}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {filterUser && playerList.length > 0 && (
                  <div className={styles.filterUser}>
                    <div className={styles.selectBox}>
                      <div
                        onClick={handleShowFilterUser}
                        ref={dropDownFilterPlayerRef}
                        className={styles.selectHeader}
                      >
                        <span>User</span>
                      </div>
                      {showFilterUser && (
                        <div
                          ref={boxFilterPlayRef}
                          className={styles.selectCnt}
                        >
                          <div
                            className={`${styles.selectItems} ${arrayIdUser.includes(0)
                              ? styles.hideUserCheckbox
                              : arrayIdUser.length === 0
                                ? ""
                                : styles.hideAll
                              }`}
                          >
                            {checkboxUser &&
                              checkboxUser.map((item, index) => (
                                <div key={index} className={styles.itemSl}>
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id={`chkFilter-${item.player_id}`}
                                      name={item.name}
                                      checked={item.checked}
                                      onChange={handleOnChangeFilterUser}
                                    />
                                    <label
                                      htmlFor={`chkFilter-${item.player_id}`}
                                    >
                                      {item.name === "" ? "All" : item.name}
                                    </label>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className={styles.ctnChart}>
                  {dashboardTotal && (
                    <>
                      {dashboardTotal.dashboard[0] &&
                        dashboardTotal.dashboard[1] ? (
                        <>
                          {arrayIdUser.length === 0 ? (
                            <>
                              {dashboardTotal.dashboard[0].harvesters.number
                                .length > 0 ||
                                dashboardTotal.dashboard[1].harvesters.number
                                  .length > 0 ? (
                                <Line
                                  options={options}
                                  data={{
                                    labels:
                                      dashboardTotal.dashboard[0].harvesters
                                        .labels.length >
                                        dashboardTotal.dashboard[1].harvesters
                                          .labels.length
                                        ? dashboardTotal.dashboard[0].harvesters
                                          .labels
                                        : dashboardTotal.dashboard[1].harvesters
                                          .labels,
                                    datasets: [
                                      {
                                        label: dashboardTotal.dashboard[0].name,
                                        data:
                                          radioValue === 1
                                            ? dashboardTotal.dashboard[0]
                                              .harvesters.number
                                            : dashboardTotal.dashboard[0]
                                              .harvesters.number_instant
                                              ? dashboardTotal.dashboard[0]
                                                .harvesters.number_instant
                                              : dashboardTotal.dashboard[0]
                                                .harvesters.number,
                                        borderColor: ["#3D69B0"],
                                        backgroundColor: ["#3D69B0"],
                                      },
                                      {
                                        label: dashboardTotal.dashboard[1].name,
                                        data:
                                          radioValue === 1
                                            ? dashboardTotal.dashboard[1]
                                              .harvesters.number
                                            : dashboardTotal.dashboard[1]
                                              .harvesters.number_instant
                                              ? dashboardTotal.dashboard[1]
                                                .harvesters.number_instant
                                              : dashboardTotal.dashboard[1]
                                                .harvesters.number,
                                        borderColor: ["#09CF70"],
                                        backgroundColor: ["#09CF70"],
                                      },
                                    ],
                                  }}
                                />
                              ) : (
                                <div className={styles.emptyData}>No data</div>
                              )}
                            </>
                          ) : (
                            <>
                              <Line options={options} data={chartData} />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {arrayIdUser.length === 0 ? (
                            <>
                              {dashboardTotal.dashboard[0].harvesters.number
                                .length > 0 ||
                                dashboardTotal.dashboard[0].harvesters.number ||
                                !dashboardTotal.dashboard[0].harvesters
                                  .players ? (
                                <Line
                                  options={options}
                                  data={{
                                    labels:
                                      dashboardTotal.dashboard[0].harvesters
                                        .labels,
                                    datasets: [
                                      {
                                        label: dashboardTotal.dashboard[0].name,
                                        data:
                                          radioValue === 1
                                            ? dashboardTotal.dashboard[0]
                                              .harvesters.number
                                            : dashboardTotal.dashboard[0]
                                              .harvesters.number_instant
                                              ? dashboardTotal.dashboard[0]
                                                .harvesters.number_instant
                                              : dashboardTotal.dashboard[0]
                                                .harvesters.number,
                                        borderColor: ["#3D69B0"],
                                        backgroundColor: ["#3D69B0"],
                                      },
                                    ],
                                  }}
                                />
                              ) : (
                                <div className={styles.emptyData}>No data</div>
                              )}
                            </>
                          ) : (
                            <>
                              {dashboardTotal.dashboard[0].harvesters
                                .players ? (
                                <Line
                                  options={options}
                                  data={{
                                    labels:
                                      dashboardTotal.dashboard[0].harvesters
                                        .labels,
                                    datasets:
                                      dashboardTotal.dashboard[0].harvesters.players.map(
                                        (item) => {
                                          let obj = {};
                                          obj = {
                                            label: item.name,
                                            data:
                                              radioValue === 1
                                                ? item.number
                                                : item.number_instant
                                                  ? item.number_instant
                                                  : item.number,
                                            borderColor: item.color,
                                            backgroundColor: item.color,
                                          };
                                          return obj;
                                        }
                                      ),
                                  }}
                                />
                              ) : (
                                <div className={styles.emptyData}>No data</div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.flexBox} ${styles.col5}`}>
            <div className={styles.flexItem}>
              <div className={styles.subTitle}>Biomass</div>
              <div className={styles.cntDashboard}>
                {dashboardTotal && (
                  <>
                    {dashboardTotal.dashboard[0] &&
                      dashboardTotal.dashboard[1] ? (
                      <>
                        {(dashboardTotal.dashboard[0].biomass.number &&
                          dashboardTotal.dashboard[0].biomass.number.length >
                          0) ||
                          (dashboardTotal.dashboard[1].biomass.number &&
                            dashboardTotal.dashboard[1].biomass.number.length >
                            0) ? (
                          <Line
                            options={options}
                            data={{
                              labels:
                                dashboardTotal.dashboard[0].biomass.labels
                                  .length >
                                  dashboardTotal.dashboard[1].biomass.labels
                                    .length
                                  ? dashboardTotal.dashboard[0].biomass.labels
                                  : dashboardTotal.dashboard[1].biomass.labels,
                              datasets: [
                                {
                                  label: dashboardTotal.dashboard[0].name,
                                  data: dashboardTotal.dashboard[0].biomass
                                    .number,
                                  borderColor: ["#3D69B0"],
                                  backgroundColor: ["#3D69B0"],
                                },
                                {
                                  label: dashboardTotal.dashboard[1].name,
                                  data: dashboardTotal.dashboard[1].biomass
                                    .number,
                                  borderColor: ["#09CF70"],
                                  backgroundColor: ["#09CF70"],
                                },
                              ],
                            }}
                          />
                        ) : (
                          <div className={styles.emptyData}>No data</div>
                        )}
                      </>
                    ) : (
                      <>
                        {dashboardTotal.dashboard[0].biomass.number &&
                          dashboardTotal.dashboard[0].biomass.number.length >
                          0 ? (
                          <Line
                            options={options}
                            data={{
                              labels:
                                dashboardTotal.dashboard[0].biomass.labels,
                              datasets: [
                                {
                                  label: dashboardTotal.dashboard[0].name,
                                  data: dashboardTotal.dashboard[0].biomass
                                    .number,
                                  borderColor: ["#3D69B0"],
                                  backgroundColor: ["#3D69B0"],
                                },
                              ],
                            }}
                          />
                        ) : (
                          <div className={styles.emptyData}>No data</div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={styles.flexItem}>
              <div className={styles.subTitle}>Newborns</div>
              <div className={styles.cntDashboard}>
                {dashboardTotal && (
                  <>
                    {dashboardTotal.dashboard[0] &&
                      dashboardTotal.dashboard[1] ? (
                      <>
                        {(dashboardTotal.dashboard[0].newborns.number &&
                          dashboardTotal.dashboard[0].newborns.number.length >
                          0) ||
                          (dashboardTotal.dashboard[1].newborns.number &&
                            dashboardTotal.dashboard[1].newborns.number.length >
                            0) ? (
                          <Line
                            options={options}
                            data={{
                              labels:
                                dashboardTotal.dashboard[0].newborns.labels
                                  .length >
                                  dashboardTotal.dashboard[1].newborns.labels
                                    .length
                                  ? dashboardTotal.dashboard[0].newborns.labels
                                  : dashboardTotal.dashboard[1].newborns.labels,
                              datasets: [
                                {
                                  label: dashboardTotal.dashboard[0].name,
                                  data: dashboardTotal.dashboard[0].newborns
                                    .number,
                                  borderColor: ["#3D69B0"],
                                  backgroundColor: ["#3D69B0"],
                                },
                                {
                                  label: dashboardTotal.dashboard[1].name,
                                  data: dashboardTotal.dashboard[1].newborns
                                    .number,
                                  borderColor: ["#09CF70"],
                                  backgroundColor: ["#09CF70"],
                                },
                              ],
                            }}
                          />
                        ) : (
                          <div className={styles.emptyData}>No data</div>
                        )}
                      </>
                    ) : (
                      <>
                        {dashboardTotal.dashboard[0].newborns.number &&
                          dashboardTotal.dashboard[0].newborns.number.length >
                          0 ? (
                          <Line
                            options={options}
                            data={{
                              labels:
                                dashboardTotal.dashboard[0].newborns.labels,
                              datasets: [
                                {
                                  label: dashboardTotal.dashboard[0].name,
                                  data: dashboardTotal.dashboard[0].newborns
                                    .number,
                                  borderColor: ["#3D69B0"],
                                  backgroundColor: ["#3D69B0"],
                                },
                              ],
                            }}
                          />
                        ) : (
                          <div className={styles.emptyData}>No data</div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardOfGame;
