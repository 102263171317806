import styles from "./WaitingForDashboard.module.scss";

WaitingForDashboard.propTypes = {};

function WaitingForDashboard(props) {
  return (
    <div className={styles.waitingLandscape}>
      <div className={styles.ldsEllipsis}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p>Please choose enough 2 axis to visualize this chart</p>
    </div>
  );
}

export default WaitingForDashboard;
