import React, {useState} from 'react';
import CloseIcon from '../../assets/img/close-icon.png';
import Slider from 'react-slick';
import Information1 from './Information1';
import Information2 from './Information2';
import Information3 from './Information3';
import Information4 from './Information4';
import InfoIcon from '../../assets/img/info-icon.png';
import PropTypes from 'prop-types';

HeaderParkManager.propTypes = {
  title: PropTypes.string,
};

function HeaderParkManager({title}) {
  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  var settings = {
    dots: true,
    arrow: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return (
    <div>
      <div className={'header-top'}>
        <h2 className={'center'} dangerouslySetInnerHTML={{__html: title}}></h2>
        <div className={'info-icon'}
             onClick={openModal}>
          <img src={InfoIcon}
               alt={'Information Icon'}/></div>
      </div>
      {modal &&
       <div className={'modal-information'}>
         <div className={'modal-content container-sm'}>
           <div className={'modal-close'} onClick={closeModal}>
             <img src={CloseIcon} alt={'Close Icon'}/>
           </div>
           <Slider {...settings}>
             <Information1/>
             <Information2/>
             <Information3/>
             <Information4/>
           </Slider>
         </div>
       </div>}</div>
  );
}

export default HeaderParkManager;
