import React, { useEffect, useRef } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './TimerParkManagerForm.module.scss';
import PropTypes from 'prop-types';
import InputFieldNumber from '../../../../../components/FormFields/InputFieldNumber';

TimerParkManagerForm.propTypes = {
  onSubmit: PropTypes.func,
};

function TimerParkManagerForm({ onSubmit }) {
  const schema = yup.object({
    minutes: yup.number()
      .typeError('Please set minutes number')
      .required('Please set minutes')
      .min(0, 'Min value 0')
      .max(2, 'Max value 2'),
    seconds: yup.number()
      .typeError('Please set seconds number')
      .max(59, 'Max value 59'),
  });

  const { control, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm(
    {
      defaultValues: {
        minutes: 1,
        seconds: 0,
      },
      resolver: yupResolver(schema),
    });

  const onHandleSubmit = async (values) => {
    if (onSubmit) {
      await onSubmit(values);
    }
    reset();
  };

  const refInputMinutes = useRef();
  const refInputSeconds = useRef();
  let watchMinutes = watch('minutes');
  useEffect(() => {
    if (watchMinutes.toString().length > 2) {
      setValue('minutes', watchMinutes.slice(0, 2));
    }
    if (2 <= watchMinutes) {
      refInputSeconds.current.classList.add('disabled');
    } else {
      refInputSeconds.current.classList.remove('disabled');
    }

    if ('' === watchMinutes) {
      setValue('minutes', 0);
    }
  }, [watchMinutes]);

  let watchSeconds = watch('seconds');
  useEffect(() => {
    if ('' === watchSeconds) {
      setValue('seconds', 0);
    }
  }, [watchSeconds]);

  return (
    <form className={styles.timerForm}
      onSubmit={handleSubmit(onHandleSubmit)}>
      <div className={styles.inner}>
        <div className={'form-item'} ref={refInputMinutes}>
          <InputFieldNumber
            type={'number'}
            placeholder={'00'}
            control={control}
            name={'minutes'}
            errors={errors}
            maxLength={2} />
        </div>

        <div className={styles.spacing}>:</div>

        <div className={'form-item'} ref={refInputSeconds}>
          <InputFieldNumber
            type={'number'}
            placeholder={'00'}
            control={control}
            name={'seconds'}
            errors={errors}
            maxLength={2} />
        </div>
      </div>

      <div className={'form-actions center'}>
        <button type="submit">Set Timer</button>
      </div>
    </form>
  );
}

export default TimerParkManagerForm;
