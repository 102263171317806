import React, {useEffect} from 'react';
import waiting from '../../../assets/img/waiting-pic.jpg';
import styles from './UserWaiting.module.scss';
import {actions, useStoreUser} from '../../../store';
import {RedirectPage} from '../../../utils/Redirect';
import { useNavigate } from 'react-router-dom';

UserWaiting.propTypes = {};

function UserWaiting(props) {
  const [, dispatchUser] = useStoreUser();
  const dateNow = Math.floor(Date.now() / 1000);

  const userName = localStorage.getItem('userName');
  const navigate = useNavigate();

  useEffect(() => {
    const expired = localStorage.getItem('expired');
    if (0 > parseInt(expired) - dateNow) {
      dispatchUser(actions.userLogout(null));
    }
  }, []);

  useEffect(() => {
    const qrCode = localStorage.getItem('qrCode');
    const userId = localStorage.getItem('userId');

    RedirectPage(qrCode, navigate, userId);
  }, [])

  return (
    <div className={styles.waitingScreen}>
      <div className={'container'}>
        <div className={styles.waitingImg}>
          <img src={waiting} alt={'Waiting'}/>
        </div>

        <h2>Welcome <span className='text-orange'>{userName}</span><br/>
          Waiting for other players</h2>
      </div>
    </div>
  );
}

export default UserWaiting;
