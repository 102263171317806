import userApi from "../api/userApi";

export const HandleCountDownTimerGM = async (qrCode) => {
  try {
    const response = await userApi.getCountDownTime(qrCode);
    let timeEnd = response?.set_time_for_gm_end_at;
    let countdownForPm = response?.countdown_for_gm;
    let timeFormat = countdownForPm.split(':');
    let timeConvert = (+timeFormat[0]) * 60 +
      (+timeFormat[1]);
    return {
      'time_current': timeConvert,
      'time_end': timeEnd
    };
  } catch (e) {
  }
}

export const HandleCountDownTimerPM = async (qrCode) => {
  try {
    const response = await userApi.getCountDownTime(qrCode);
    let timeEnd = response?.set_time_for_pm_end_at;
    let countdownForPm = response?.countdown_for_pm;
    let timeFormat = countdownForPm.split(':');
    let timeConvert = (+timeFormat[0]) * 60 +
      (+timeFormat[1]);
    return {
      'time_current': timeConvert,
      'time_end': timeEnd
    };
  } catch (e) {
  }
}

export const HandleCountDownTimerPlayer = async (qrCode) => {
  try {
    const response = await userApi.getCountDownTime(qrCode);
    let timeEnd = response?.set_time_for_player_end_at;
    let countdownForPm = response?.countdown_for_player;
    let timeFormat = countdownForPm.split(':');
    let timeConvert = (+timeFormat[0]) * 60 +
      (+timeFormat[1]);
    return {
      'time_current': timeConvert,
      'time_end': timeEnd
    };
  } catch (e) {
  }
}