import React from 'react';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';

InputFieldNumber.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,

  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  maxLength: PropTypes.number,
};

function InputFieldNumber(props) {
  const {
    control, name, type, placeholder,
    errors, maxLength, readOnly,
  } = props;

  return (
    <Controller control={control}
                name={name}
                render={({field: {onChange, onBlur, value}}) => (
                  <>
                    <input type={type}
                           placeholder={placeholder}
                           onChange={onChange}
                           onBlur={onBlur}
                           value={value ? value : ''}
                           className={''}
                           maxLength={maxLength}
                           readOnly={readOnly ? readOnly : ''}/>

                    <ErrorMessage
                      errors={errors}
                      name={name}
                      render={({message}) =>
                        <p className={'error'}>{message}</p>}/>
                  </>
                )}/>
  );
}

export default InputFieldNumber;
