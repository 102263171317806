export const ChannelPlayer = (
  userId, pmId, currentPage, navigate, round, session, playerChosen,
  status) => {
  if ('Stopped' === status) {
    localStorage.clear();
    window.location.reload();
    navigate('/welcome', {replace: true});
  }

  if (parseInt(userId) === parseInt(pmId)) {
    switch (currentPage) {
      case 'landscape-of-round':
        (1 === round && 1 === session) ?
          navigate('/game-rule-park-manager') :
          navigate('/park-manager-landscape');
        break;
      case 'set-timer-for-pm':
        navigate('/park-manager-protected-area');
        break;
      case 'set-timer-for-harvester':
        navigate('/park-manager-waiting-harvesters');
        break;
      case 'position-of-harvester':
        navigate('/park-manager-waiting-decision');
        break;
      case 'map-of-harvester':
        navigate('/park-manager-view-map-of-harvester');
        break;
      case 'biomass-earning-of-harvester':
        navigate('/park-manager-view-decision');
        break;
      case 'end-of-round':
        navigate('/park-manager-view-decision');
        break;
      default:
        break;
    }

    if (true === playerChosen) {
      navigate('/park-manager-waiting-decision');
    }
  } else {
     switch (currentPage) {
      case 'landscape-of-round':
        (1 === round && 1 === session) ?
          navigate('/game-rule-player') :
          navigate('/player-landscape');
        break;
      case 'set-timer-for-pm':
        navigate('/player-view-protected-area');
        break;
      case 'protected-area':
        navigate('/player-preservation-zone');
        break;
      case 'set-timer-for-harvester':
        navigate(`/player-harvest-process/${userId}`);
        break;
      case 'position-of-harvester':
        navigate(`/player-waiting-decision/${userId}`);
        break;
      case 'map-of-harvester':
        navigate(`/player-view-map-of-harvester/${userId}`,
          {replace: true});
        break;
      case 'biomass-earning-of-harvester':
        navigate(`/player-harvest-result/${userId}`);
        break;
      default:
        break;
    }
  }
};

export const ChannelMaster = (currentPage, playerChosen, navigate) => {
  if ('protected-area' === currentPage) {
    navigate('/protected-area');
  }

  if (true === playerChosen) {
    navigate('/position-of-harvester');
  }
};
