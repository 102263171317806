import axiosClient from './axiosClient';

const userApi = {
  playerLogin(data) {
    const url = '/player-login';
    return axiosClient.post(url, data);
  },

  playerRejoin(data) {
    const url = '/player-rejoin';
    return axiosClient.post(url, data);
  },

  playerRefreshToken() {
    const url ='/pl-refresh-token';
    return axiosClient.post(url);
  },

  getHarvestorOfPlayer() {
    const url = '/harvester-of-player';
    return axiosClient.get(url);
  },

  postChooseLandToHarvest(data) {
    const url = '/choose-land-to-harvest';
    return axiosClient.post(url, data);
  },

  getCountDownTime(qrCode) {
    const url = `/get-countdown-time/${qrCode}`;
    return axiosClient.get(url);
  }
}

export default userApi;
