import React from 'react';
import gameRule5 from '../../../../../assets/img/waiting-pic.jpg';
import {Link} from 'react-router-dom';

GameRule5.propTypes = {};

function GameRule5(props) {
  return (
    <div className={'center'}>
      <img src={gameRule5} alt={'Waiting'} width={'273'} height={'273'}/>

      <h2 className={'center'}>Your success depends on the<br/> number of newborns in
        the <br/>landscape over the entire game.<br/>Good luck! The survival of the<br/>
        species depends on you.</h2>

      <br/>

      <Link to={'/park-manager-landscape'} className={'btn primary'}>Let's
        go</Link>
    </div>
  );
}

export default GameRule5;
