import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import userApi from '../../../api/userApi';
import {actions, useStoreUser} from '../../../store';
import {useNavigate} from 'react-router-dom';

ReJoin.propTypes = {};

function ReJoin(props) {
  let {key} = useParams();
  const navigate = useNavigate();
  const [, dispatchUser] = useStoreUser();

  useEffect(() => {
    (async() => {
      try {
        const response = await userApi.playerRejoin({
          'rejoin_key': key,
        });
        dispatchUser(actions.userLogin(response));
        navigate('/player-waiting', {replace: true});
      } catch (e) {
        navigate('/welcome', {replace: true});
      }
    })();
  }, []);

  return (
    <div className={'min-vh'}>
      <div className={'container center'}>
        Please Waiting
      </div>
    </div>
  );
}

export default ReJoin;
