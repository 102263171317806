import React, {useEffect, useState} from 'react';
import HeaderParkManager from '../../../components/HeaderParkManager';
import gameApi from '../../../api/gameApi';
import WaitingForLandscape from '../../../components/WaitingForLandscape';

ParkManagerLandscape.propTypes = {};

function ParkManagerLandscape(props) {
  const [landscapeView, setLandscapeView] = useState({});
  const [round, setRound] = useState();

  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    (async() => {
      const qrCode = localStorage.getItem('qrCode');
      const response = await gameApi.getLandscapePlayer(qrCode);
      setLandscapeView(response?.landscape);
      setRound(response?.round);
      setWaiting(!waiting);
    })();
  }, []);

  return (
    <div className={'min-vh'}>
      <div className={'container-sm'}>
        <HeaderParkManager title={'Hey, take a look at <br/>our ecosystem'}/>

        <div className={'game-content'}>
          {waiting && <WaitingForLandscape/>}

          {!waiting &&
           <div className={'game-board small'}>
             <div className={'game-title-row'}>
               <div>A</div>
               <div>B</div>
               <div>C</div>
               <div>D</div>
               <div>E</div>
             </div>
             <div className={'game-title-col'}>
               <div>1</div>
               <div>2</div>
               <div>3</div>
               <div>4</div>
             </div>
             <div className={'game-list'}>
               {Object.entries(landscapeView).map((value, index) => (
                 <div key={index}
                      className={'game-item'}
                      style={{backgroundColor: value[1].color}}>
                   {0 < value[1].animal_qty &&
                    <p className={'animal-icon'}>{[
                      ...Array(value[1].animal_qty)].map(
                      (currentValue, i) => (
                        <span key={i}>&nbsp;</span>))}</p>}
                 </div>
               ))}
             </div>
           </div>}

          {1 === round &&
           <h2 className={'center'}>During this round, you just observe.</h2>}
        </div>
      </div>
    </div>
  );
}

export default ParkManagerLandscape;
