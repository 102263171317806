import React, { useState } from 'react';
import background from '../../../assets/img/login-bg.jpg';
import LogoXl from '../../../assets/img/logo-xl.svg';
import styles from './PlayAgain.module.scss';
import gameApi from '../../../api/gameApi';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

PlayAgain.propTypes = {};

function PlayAgain(props) {
  const navigate = useNavigate();
  const [session, setSession] = useState(1);

  useEffect(() => {
    (async () => {
      try {
        const qrCode = localStorage.getItem('qrCode');
        const response = await gameApi.getGameInfoMaster(qrCode);
        setSession(response?.session);
      } catch (e) {
      }
    })()
  }, []);

  const handlePlayAgain = async () => {
    try {
      await gameApi.playAgain({
        "qr_code": localStorage.getItem('qrCode')
      });
      navigate('/landscape', { replace: true });
    } catch (e) {
    }
  }

  const handleStopGame = async () => {
    try {
      await gameApi.stopGame({
        "qr_code": localStorage.getItem('qrCode')
      });
      navigate('/landscape-of-game', { replace: true });
    } catch (e) {
    }
  }

  return (
    <div className={'has-bg'} style={{ backgroundImage: `url(${background})` }}>
      <div className={'container'}>
        <div className={styles.playAgain}>
          <div className={styles.logoXl}>
            <img src={LogoXl} alt={'Logo Planet C'} width={339} height={408} />
          </div>

          <div className={`${styles.actions} group-buttons`}>
            {session < 2 &&
              <button type='button'
                className='btn primary'
                onClick={handlePlayAgain}><span>Play Again</span></button>}

            <button type='button'
              className='btn secondary'
              onClick={handleStopGame}><span>Game Over</span></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayAgain;
