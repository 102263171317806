import React from 'react';
import IconError from '../../assets/img/info-icon.svg';
import IconSuccess from '../../assets/img/success-icon.svg';
import styles from './Notification.module.scss';
import PropTypes from 'prop-types';

Notification.propTypes = {
    notification: PropTypes.object,
};

function Notification({notification}) {
    const {type, message} = notification;
    return (
        <div className={styles.messageNotification}>
            {'success' === type && (
                <div className={styles.success}>
                    <img src={IconSuccess} alt={'Icon Success'}/>
                    <span>{message}</span>
                </div>
            )}

            {'error' === type && (
                <div className={styles.error}>
                    <img src={IconError} alt={'Icon Error'}/>
                    <span>{message}</span>
                </div>
            )}
        </div>
    );
}

export default Notification;
