import React, { useEffect, useState } from 'react';
import LandscapeContentHeader
  from '../../../components/LandscapeLayout/LandscapeContentHeader';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../Landscape/Landscape.module.scss';
import LogoGray from '../../../assets/img/logo-gray.png';
import LineIcon from '../../../assets/img/line-icon.png';
import gameApi from '../../../api/gameApi';
import masterApi from '../../../api/masterApi';
import WaitingForLandscape from '../../../components/WaitingForLandscape';
import { useStoreGame } from '../../../store';

BiomassEarning.propTypes = {};

function BiomassEarning(props) {
  const [stateGame] = useStoreGame();
  const { roundCurrent } = stateGame;

  const [landscapeView, setLandscapeView] = useState({});
  const [round, setRound] = useState(roundCurrent ? roundCurrent : ' ');

  const [session, setSession] = useState('');
  const [showView, setShowView] = useState(false);

  const [waiting, setWaiting] = useState(true);
  const navigate = useNavigate();

  const qrCode = localStorage.getItem('qrCode');

  useEffect(() => {
    (async () => {
      try {
        const response = await gameApi.getLandscape(qrCode);

        setLandscapeView(response?.landscape);
        setRound(response?.round);

        setSession(response.session);
        setWaiting(!waiting);
      } catch (e) {
      }
    })();
  }, []);

  const handleNewRound = async () => {
    try {
      if (round < 10) {
        await gameApi.postNewRound({
          'qr_code': qrCode,
        });
        navigate('/landscape');
      }

      if (round === 10) {
        await gameApi.playAgain({
          'qr_code': qrCode,
        });
        navigate('/landscape');
      }
    } catch (e) {
    }
  };

  const handleEndGame = async () => {
    try {
      await masterApi.redirectPage({
        'qr_code': localStorage.getItem('qrCode'),
        'next_page': 'landscape-of-game'
      });
    } catch (e) {
    }
  };

  const handleShowView = () => {
    setShowView(!showView);
  };

  return (
    <>
      <main className={'container'}>
        <LandscapeContentHeader title={`Round ${round} - Biomass earning of harvesters`}
          active={6} />

        <div className={'center'}>
          <h2>End of round {round}</h2>
          <p>You can see the harvest result on your phone. <br />
            Time to discuss</p>

          <div className='game-content-master'>
            {waiting && <WaitingForLandscape />}

            {!waiting &&
              <div className={'game-board'}>
                <div className={'game-title-row'}>
                  <div>A</div>
                  <div>B</div>
                  <div>C</div>
                  <div>D</div>
                  <div>E</div>
                </div>
                <div className={'game-title-col'}>
                  <div>1</div>
                  <div>2</div>
                  <div>3</div>
                  <div>4</div>
                </div>
                <div className={'game-view'}>
                  {!showView &&
                    <div className={'game-list'}>
                      {Object.entries(landscapeView)
                        .map(([positionKey, positionData], index) => (
                          <div key={index}
                            className={`game-item 
                          ${'true' === positionData?.is_protected ?
                                'active' :
                                ''}`}
                            style={{ backgroundColor: positionData.color }}>
                            {0 < positionData.harvester_qty &&
                              <ul className={'harvesters'}>{[
                                ...Array(positionData.harvester_qty)].map(
                                  (currentValue, i) => (
                                    <li key={i}><span>&nbsp;</span></li>))}</ul>}
                          </div>
                        ))}
                    </div>}

                  {showView &&
                    <div className={'game-list'}>
                      {Object.entries(landscapeView)
                        .map(([positionKey, positionData], index) => (
                          <div key={index}
                            className={`game-item 
                          ${'true' === positionData?.is_protected ?
                                'active' :
                                ''}`}
                            style={{ backgroundColor: positionData.color }}>
                            {0 < positionData.animal_qty &&
                              <p className={'animal-icon'}>{[
                                ...Array(positionData.animal_qty)].map(
                                  (currentValue, i) => (
                                    <span key={i}>&nbsp;</span>))}</p>}

                            {0 < positionData.animal_new_born &&
                              <p className={'animal-icon new-born'}>{[
                                ...Array(positionData.animal_new_born)].map(
                                  (currentValue, i) => (
                                    <span key={i}>&nbsp;</span>))}</p>}

                            {0 < positionData.harvester_qty &&
                              <ul className={'harvesters'}>{[
                                ...Array(positionData.harvester_qty)].map(
                                  (currentValue, i) => (
                                    <li key={i}><span>&nbsp;</span></li>))}</ul>}
                          </div>
                        ))}
                    </div>}
                </div>
              </div>}
          </div>

          <p className={'group-buttons'}>
            {(10 > round) &&
              <button
                className={'btn primary auto'}
                onClick={handleNewRound}><span>New Round</span></button>

            }

            {4 < round &&
              <Link to={'/dashboard-of-game'}
                className={'btn secondary'}
                onClick={handleEndGame}><span>End game</span></Link>}
          </p>
        </div>

      </main>

      <footer className={styles.footer}>
        <div className={'container'}>
          <div className={styles.wrapper}>
            <div className={'d-flex'}>
              <img src={LogoGray}
                alt={'Planet C Logo Gray'}
                className={styles.logoFt} />
              Park manager

              <div onClick={handleShowView} className={'switch-toggle'}>
                <input type={'checkbox'} name={'switch'} value={'1'} />
                <label htmlFor={'switch'}>Switch</label>
              </div>
            </div>

            <p>
              <Link to={'/update-biomass-earning'}
                target={'_blank'}
                className={styles.linkView}>
                <span>Game master view</span>
                <img src={LineIcon} alt={'Line Icon'} />
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default BiomassEarning;
