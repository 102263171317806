import React from 'react';
import InputField from '../../../../../components/FormFields/InputField';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Message from '../../../../../components/Message';

JoinGameForm.propTypes = {
  onSubmit: PropTypes.func,
};

function JoinGameForm({onSubmit, qrCode, message}) {
  const schema = yup.object({
    username: yup.string().required('Please enter your name')
                 .max(8, 'Maximum 8 characters'),
  });

  const {control, handleSubmit, formState: {errors, isDirty}, reset} = useForm(
    {
      defaultValues: {
        username: '',
        qr_code: qrCode,
      },
      resolver: yupResolver(schema),
    });

  const onHandleSubmitJoinGame = async(values) => {
    if (onSubmit) {
      await onSubmit(values);
    }
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onHandleSubmitJoinGame)}>
      <div className={'form-item'}>
        <InputField
          type={'text'}
          placeholder={'What should we call you?'}
          control={control}
          name={'username'}
          autocomplete={'username'}
          errors={errors}
          maxlength={8}
          helperText={'Maximum 8 characters'}/>

        {message && <Message notification={message}/>}
      </div>

      <div className={'form-actions'}>
        <button type={'submit'} disabled={!isDirty}>Join</button>
      </div>
    </form>
  );
}

export default JoinGameForm;
