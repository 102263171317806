import './App.scss';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './pages/admin/Login';
import React, {useEffect, useState} from 'react';
import CreateGame from './pages/admin/CreateGame';
import JoinGame from './pages/user/JoinGame';
import Organization from './pages/admin/Organization';
import StartGame from './pages/admin/StartGame';
import MasterWaiting from './pages/admin/MasterWaiting';
import UserWaiting from './pages/user/UserWaiting';
import GameRule from './pages/user/GameRule';
import { actions, useStore, useStoreGame, useStoreUser } from './store';
import Landscape from './pages/admin/Landscape';
import LandscapeView from './pages/admin/LandscapeView';
import PlayerLandscape from './pages/user/PlayerLandscape';
import ParkManagerLandscape from './pages/user/ParkManagerLandscape';
import GameRulePlayer from './pages/user/GameRulePlayer';
import TimerParkManager from './pages/admin/TimerParkManager';
import ProtectedArea from './pages/admin/ProtectedArea';
import ParkManagerProtectedArea from './pages/user/ParkManagerProtectedArea';
import ParkManagerViewProtectedArea
  from './pages/user/ParkManagerViewProtectedArea';
import PlayerViewProtectedArea from './pages/user/PlayerViewProtectedArea';
import PlayerPreservationZone from './pages/user/PlayerPreservationZone';
import TimerHarvestor from './pages/admin/TimerHarvestor';
import ReJoin from './pages/user/ReJoin';
import PlayerHarvestProcess from './pages/user/PlayerHarvestProcess';
import ParkManagerWaitingHarvestors
  from './pages/user/ParkManagerWaitingHarvestors';
import PositionOfHarvestor from './pages/admin/PositionOfHarvestor';
import ParkManagerWaitingFinalDecision
  from './pages/user/ParkManagerWaitingFinalDecision';
import ParkManagerViewDecision from './pages/user/ParkManagerViewDecision';
import BiomassEarning from './pages/admin/BiomassEarning';
import ChangePositionOfHarvestor from './pages/admin/ChangePositionOfHarvestor';
import PlayerHarvestResult from './pages/user/PlayerHarvestResult';
import PlayerWaitingFinalDecision
  from './pages/user/PlayerWaitingFinalDecision';
import UpdateBiomassEarning from './pages/admin/UpdateBiomassEarning';
import PlayAgain from './pages/admin/PlayAgain';
import DashboardOfGame from './pages/admin/DashboardOfGame';
import LandscapeOfGame from './pages/admin/LandscapeOfGame';
import Dashboard from './pages/admin/Dashboard';
import DashboardGlobal from './pages/admin/DashBoardGlobal';
import Welcome from './pages/user/Welcome';
import { RedirectPage, RedirectPageReload } from './utils/Redirect';
import { ChannelMaster, ChannelPlayer } from './utils/ChannelGame';
import { PrivateRoutes, PrivateUserRoutes } from './utils/PrivateRoutes';
import Echo from 'laravel-echo';
import io from 'socket.io-client';
import masterApi from "./api/masterApi";
import userApi from "./api/userApi";
import CreateAccount from "./pages/admin/CreateAccount";
import MasterAccountList from "./pages/admin/MasterAccountList";
import gameApi from "./api/gameApi";
import PlayerManagement from "./pages/admin/PlayerManagement";

let navigate;
const handleFocus = () => {
  const qrCode = localStorage.getItem('qrCode');
  const role = localStorage.getItem('role');

  const userId = localStorage.getItem('userId');

  if ('admin' !== role && qrCode) {
    RedirectPage(qrCode, navigate, userId);
  }
};

function App() {
  const [state, dispatch] = useStore();
  const [stateUser, dispatchUser] = useStoreUser();
  const [, dispatchGame] = useStoreGame();

  navigate = useNavigate();
  const dateNow = Math.floor(Date.now() / 1000);

  const expired = localStorage.getItem('expired');
  const role = localStorage.getItem('role');

  const [isRefreshToken, setIsRefreshToken] = useState(false);
  const [isRefreshTokenPlayer, setIsRefreshTokenPlayer] = useState(false);

  useEffect(() => {
    window.addEventListener('focus', handleFocus);

    if ('admin' === role && expired) {
      if (0 > parseInt(expired) - dateNow) {
       setIsRefreshToken(true);
      }
    }

    window.addEventListener('focus', () => {
      if ('admin' === role && expired) {
        if (0 >= parseInt(expired) - dateNow) {
          setIsRefreshToken(true);
        }
      }
    });

    if ('player' === role && expired) {
      if (0 > parseInt(expired) - dateNow) {
        setIsRefreshTokenPlayer(true);
      }
    }

    window.addEventListener('focus', () => {
      if ('player' === role && expired) {
        if (0 > parseInt(expired) - dateNow) {
          setIsRefreshTokenPlayer(true);
        }
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (isRefreshToken === true) {
          const response = await masterApi.gmRefreshToken();
          dispatch(actions.masterRefreshToken(response));
        }
      } catch (e) {

      }
    })()
  }, [isRefreshToken, state.expired]);

  useEffect(() => {
    (async () => {
      try {
        if (isRefreshTokenPlayer === true) {
          const response = await userApi.playerRefreshToken();
          dispatchUser(actions.userRefreshToken(response));
        }
      } catch (e) {

      }
    })()
  }, [isRefreshTokenPlayer, stateUser.expired]);

  useEffect(() => {
    if ('admin' !== state.role && state.qrCode) {
      RedirectPageReload(state.qrCode, navigate,
        localStorage.getItem('userId'));
    }
  }, [state.role]);

  useEffect(() => {
    initListenerPlayer();
  }, [stateUser.token, stateUser.qrCode, stateUser.userId, stateUser.role]);

  useEffect(() => {
    initListenerMaster();
  }, [state.accessToken, state.qrCode, state.role]);

  function initListenerMaster() {
    let echo = new Echo({
      host: `${process.env.REACT_APP_REAL_TIME_URL}`,
      broadcaster: 'socket.io',
      client: io,
      disableStats: true
    });

    echo.connector.options.auth.headers['Authorization'] = 'Bearer ' +
      state.accessToken;
    echo.options.auth = {
      headers: {
        Authorization: 'Bearer ' + state.accessToken,
      },
    };

    echo.private(`channel-game-${state.qrCode}`)
      .listen(`.game-${state.qrCode}`, function (e) {
        const currentPage = e?.game.current_page;
        const playerChosen = e?.game?.all_player_chosen;
        const playerList = e?.player;

        if ('admin' === localStorage.getItem('role') && playerList) {
          dispatchGame(actions.gameMaterWaiting(e?.player));
        }

        if ('admin' === localStorage.getItem('role')) {
          ChannelMaster(currentPage, playerChosen, navigate);
          dispatchGame(actions.gameMater(e?.game));
        }
      });
  }

  function initListenerPlayer() {
    let echo = new Echo({
      host: `${process.env.REACT_APP_REAL_TIME_URL}`,
      broadcaster: 'socket.io',
      client: io,
      disableStats: true
    });

    echo.connector.options.auth.headers['Authorization'] = 'Bearer ' +
      stateUser.accessToken;
    echo.options.auth = {
      headers: {
        Authorization: 'Bearer ' + stateUser.accessToken,
      },
    };

    echo
      .private(`channel-player-game-${stateUser.qrCode}`)
      .listen(`.player-game-${stateUser.qrCode}`, async (e) => {
        const userId = localStorage.getItem('userId');
        const pmId = e?.game.pm_id;

        const currentPage = e?.game.current_page;
        const round = e?.game.round;

        const session = e?.game.session;
        const playerChosen = e?.game.all_player_chosen;

        const status = e?.game.status;
        const endTimePl = e?.game?.countdown_time_for_pl;

        const endTimePm = e?.game?.countdown_time_for_pm;

        if ('player' === localStorage.getItem('role')) {
          ChannelPlayer(userId, pmId, currentPage,
            navigate, round, session,
            playerChosen, status);

          dispatchGame(actions.gamePlayer(e?.game));
        }

        const harvest = JSON.parse(localStorage.getItem('harvest'));
        const qrCode = localStorage.getItem('qrCode');
        if ('end' === endTimePl && null !== harvest) {
          await userApi.postChooseLandToHarvest(
            {
              'qr_code': qrCode,
              'position_harvest': harvest,
            },
          );
        }

        const protectedArea = JSON.parse(localStorage.getItem('area'));
        if ('end' === endTimePm && null !== protectedArea) {
          await gameApi.pmChooseProtectedAreas(protectedArea);
          dispatchGame(actions.gamePlayer(e?.game));
        }
      });
  }

  return (
    <>
      <Routes>
        <Route path={'/'} element={<Login admin={state.role} />} />

        <Route element={<PrivateRoutes admin={state.role} />}>
          <Route path={'/create-game'} element={<CreateGame />} exact />
          <Route path={'/organization'} element={<Organization />} exact />
          <Route path={'/start-game'} element={<StartGame />} exact />
          <Route path={'/master-waiting'} element={<MasterWaiting />} exact />
          <Route path={'/landscape'} element={<Landscape />} exact />
          <Route path={'/landscape-view'} element={<LandscapeView />} exact />
          <Route path={'/player-management'} element={<PlayerManagement />} exact />
          <Route path={'/timer-park-manager'}
            element={<TimerParkManager />}
            exact />
          <Route path={'/protected-area'} element={<ProtectedArea />} exact />
          <Route path={'/timer-harvester'} element={<TimerHarvestor />} exact />
          <Route path={'/position-of-harvester'}
                 element={<PositionOfHarvestor />}
                 exact />
          <Route path={'/biomass-earning'} element={<BiomassEarning />} exact />
          <Route path={'/change-position-harvesters'}
                 element={<ChangePositionOfHarvestor />} exact />
          <Route path={'/update-biomass-earning'}
            element={<UpdateBiomassEarning />} />
          <Route path={'/dashboard-of-game'}
            element={<DashboardOfGame />} />
          <Route path={'/landscape-of-game'}
            element={<LandscapeOfGame />} />
          <Route path={'/play-again'}
            element={<PlayAgain />} />
          <Route path={'/dashboard'} element={<Dashboard />} exact />
          <Route
            path={'/dashboard-global'}
            element={<DashboardGlobal />}
            exact
          />
          <Route path={'/create-account'} element={<CreateAccount/>} exact/>
          <Route path={'/master-account'} element={<MasterAccountList/>} exact/>
        </Route>

        <Route path={'/join-game/:qrCode'}
          element={<JoinGame user={stateUser.role} />} />

        <Route path={'/re-join/:key'}
          element={<ReJoin />} />

        <Route element={<PrivateUserRoutes user={stateUser.role} />}>
          <Route path={'/player-waiting'} element={<UserWaiting />} exact />
          <Route path={'/game-rule-park-manager'}
            element={<GameRule />}
            exact />

          <Route path={'/park-manager-landscape'}
            element={<ParkManagerLandscape />} exact />
          <Route path={'/park-manager-protected-area'}
            element={<ParkManagerProtectedArea />} exact />
          <Route path={'/park-manager-view-protected-area'}
            element={<ParkManagerViewProtectedArea />} exact />
          <Route path={'/park-manager-waiting-harvesters'}
                 element={<ParkManagerWaitingHarvestors />} exact />
          <Route path={'/park-manager-waiting-decision'}
            element={<ParkManagerWaitingFinalDecision />} exact />
          <Route path={'/park-manager-view-decision'}
            element={<ParkManagerViewDecision />} exact />

          <Route path={'/game-rule-player'}
            element={<GameRulePlayer />}
            exact />
          <Route path={'/player-landscape'}
            element={<PlayerLandscape />} exact />
          <Route path={'/player-view-protected-area'}
            element={<PlayerViewProtectedArea />} exact />
          <Route path={'/player-preservation-zone'}
            element={<PlayerPreservationZone />} exact />
          <Route path={'/player-harvest-process/:userId'}
            element={<PlayerHarvestProcess />} />
          <Route path={'/player-waiting-decision/:userId'}
            element={<PlayerWaitingFinalDecision />} />
          <Route path={'/player-harvest-result/:userId'}
            element={<PlayerHarvestResult />} />
        </Route>

        <Route path={'*'} element={<Welcome />} />
      </Routes>
    </>
  );
}

export default App;
